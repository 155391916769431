// Generated by ts-to-zod
import { z } from 'zod';

import { EmploymentTypeEnumSchema } from './EmploymentTypeEnumSchema';
import { ExperienceEnumSchema } from './ExperienceEnumSchema';
import { OrderEnumSchema } from './OrderEnumSchema';
import { RegionEnumSchema } from './RegionEnumSchema';
import { ScheduleEnumSchema } from './ScheduleEnumSchema';
import { SearchPeriodEnumSchema } from './SearchPeriodEnumSchema';

export const CreateMailingRequestSchema = z.object({
  region: RegionEnumSchema.optional(),
  resume_hash: z.string(),
  letter: z.string().optional().nullable(),
  order: OrderEnumSchema.optional(),
  search_period: SearchPeriodEnumSchema.optional(),
  desired_vacancy_search_key: z
    .string()
    .trim()
    .min(1, { message: 'Поле обязательно' }),
  employment_type: z.array(EmploymentTypeEnumSchema).optional(),
  hh_user: z.number(),
  desired_number_of_applies: z
    .number({ message: 'Только цифры' })
    .min(1, 'Не менее 1')
    .max(2800, 'Не более 2800')
    .optional(),

  desired_applies_number_per_day: z
    .number({ message: 'Только цифры' })
    .min(1, 'Не менее 1')
    .max(200, 'Не более 200')
    .optional(),
  sleep_time: z.number().optional().nullable(),
  restricted_employers: z.array(z.number()).optional(),
  experience: ExperienceEnumSchema.optional(),
  schedule: z.array(ScheduleEnumSchema).optional(),
});
