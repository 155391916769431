export enum BrowserEnum {
  UCBrowser = 'UCBrowser',
  Edge = 'Edge',
  GoogleBot = 'GoogleBot',
  Chromium = 'Chromium',
  Firefox = 'Firefox',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Opera = 'Opera',
  IE = 'IE',
  unknown = 'unknown',
}
const browserVersion = (userAgent: string, regex: RegExp) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
};

type Created = {
  browser: BrowserEnum;
  version: string | null;
};
export const getBrowserAndVersion = (): Created => {
  const userAgent = navigator.userAgent;
  let browser = 'unknown';
  // Detect browser name
  browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser;
  browser = /edg/i.test(userAgent) ? 'Edge' : browser;
  browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser;
  browser = /chromium/i.test(userAgent) ? 'Chromium' : browser;
  browser =
    /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
      ? 'Firefox'
      : browser;
  browser =
    /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
      ? 'IE'
      : browser;
  browser =
    /chrome|crios/i.test(userAgent) &&
    !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
      ? 'Chrome'
      : browser;
  browser =
    /safari/i.test(userAgent) &&
    !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
      userAgent,
    )
      ? 'Safari'
      : browser;
  browser = /opr|opera/i.test(userAgent) ? 'Opera' : browser;

  switch (browser) {
    case BrowserEnum.UCBrowser:
      return {
        browser,
        version: browserVersion(userAgent, /(ucbrowser)\/([\d.]+)/i),
      };
    case BrowserEnum.Edge:
      return {
        browser,
        version: browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d.]+)/i),
      };
    case BrowserEnum.GoogleBot:
      return {
        browser,
        version: browserVersion(userAgent, /(googlebot)\/([\d.]+)/i),
      };
    case BrowserEnum.Chromium:
      return {
        browser,
        version: browserVersion(userAgent, /(chromium)\/([\d.]+)/i),
      };
    case BrowserEnum.Firefox:
      return {
        browser,
        version: browserVersion(userAgent, /(firefox|fxios)\/([\d.]+)/i),
      };
    case BrowserEnum.Chrome:
      return {
        browser,
        version: browserVersion(userAgent, /(chrome|crios)\/([\d.]+)/i),
      };
    case BrowserEnum.Safari:
      return {
        browser,
        version: browserVersion(userAgent, /(safari)\/([\d.]+)/i),
      };
    case BrowserEnum.Opera:
      return {
        browser,
        version: browserVersion(userAgent, /(opera|opr)\/([\d.]+)/i),
      };
    case BrowserEnum.IE:
      // eslint-disable-next-line no-case-declarations
      const version = browserVersion(userAgent, /(trident)\/([\d.]+)/i);
      // IE version is mapped using trident version
      // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
      if (version) {
        return {
          browser,
          version: String(parseFloat(version) + 4.0),
        };
      } else {
        return {
          browser,
          version: '7.0',
        };
      }
    default:
      return {
        browser: BrowserEnum.unknown,
        version: null,
      };
  }
};
