// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DesiredAppliesInputs_container__DpnLb {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
}

.DesiredAppliesInputs_inputs__pjrQY {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}
@media (max-width: 929px) {
  .DesiredAppliesInputs_inputs__pjrQY {
    flex-wrap: wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/DesiredAppliesInputs/DesiredAppliesInputs.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAAF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EAJF;IAKI,eAAA;EAGF;AACF","sourcesContent":["@import 'src/assets/styles/utils';\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: rem(36);\n}\n.inputs {\n  display: flex;\n  flex-direction: row;\n  gap: rem(12);\n  @media (width < 930px) {\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DesiredAppliesInputs_container__DpnLb`,
	"inputs": `DesiredAppliesInputs_inputs__pjrQY`
};
export default ___CSS_LOADER_EXPORT___;
