import styles from './EditMailingStep3.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { NavigationPath } from '../../../../config/navigation.data';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { PatchedCreateMailingRequest } from '../../../../Entities';
import { useUpdateMailingMutation } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { DesiredAppliesInputs } from '../DesiredAppliesInputs/DesiredAppliesInputs';
import { useGetIdFromSearchParams } from '../hooks/useGetIdFromSearchParams';

export const EditMailingStep3 = () => {
  const mailingId = useGetIdFromSearchParams();

  const { handleSubmit } = useFormContext<PatchedCreateMailingRequest>();
  const [update] = useUpdateMailingMutation();
  const navigate = useNavigate();

  const onHandleBack = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep1,
      search: `id=${mailingId}`,
    });
  };

  const onHandleSave = handleSubmit(async ({ plan, daily_plan }) => {
    if (!mailingId) {
      navigate({ pathname: NavigationPath.mailingCreateStep1 });
      return;
    }
    const response = await update({
      id: mailingId,
      patchedMailing: {
        daily_plan,
        plan,
      },
      customOptions: { showSuccessToast: true, successMessage: 'Успешно!' },
    });
    if ('data' in response) {
      navigate({ pathname: NavigationPath.mailing });
    }
    if ('error' in response) {
      console.log(response);
    }
  });
  return (
    <div className={styles.container}>
      <DesiredAppliesInputs />
      <div className={styles.buttons}>
        <Button onClick={onHandleSave} interfaceType={ButtonType.Primary}>
          Сохранить
        </Button>

        <Button onClick={onHandleBack} interfaceType={ButtonType.Secondary}>
          Назад
        </Button>
      </div>
    </div>
  );
};
