import { HHCheckButton } from '../../../../Components/UI-kit/HHCheckButton/HHCheckButton';
import styles from './EditMailingStep2.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { useGetSearchLinkQuery } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../../config/navigation.data';
import { useGetIdFromSearchParams } from '../hooks/useGetIdFromSearchParams';

export const EditMailingStep2 = () => {
  const mailingId = useGetIdFromSearchParams();
  const navigate = useNavigate();
  const { data } = useGetSearchLinkQuery(
    { id: mailingId as number },
    {
      skip: !mailingId,
    },
  );

  const onHandleBack = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep1,
      search: `id=${mailingId}`,
    });
  };

  const onHandleNext = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep3,
      search: `id=${mailingId}`,
    });
  };

  return (
    <div className={styles.container}>
      <HHCheckButton link={data} disabled={!!data} />
      <div className={styles.buttons}>
        <Button onClick={onHandleNext} interfaceType={ButtonType.Primary}>
          Продолжить
        </Button>

        <Button onClick={onHandleBack} interfaceType={ButtonType.Secondary}>
          Нет, исправить
        </Button>
      </div>
    </div>
  );
};
