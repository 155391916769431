import React, { memo } from 'react';
import { ControlledInput } from '../../../../../Components';
import { InputWrapper } from './InputWrapper';
import { Control, useWatch } from 'react-hook-form';
import { CreateMailingRequest } from '../../../../../Entities';

interface IDesiredNumberOfAppliesTotalInputProps {
  control: Control<CreateMailingRequest>;
}

export const DesiredNumberOfAppliesTotalInput: React.FC<IDesiredNumberOfAppliesTotalInputProps> =
  memo(({ control }) => {
    const daily_plan = useWatch({
      control,
      name: 'daily_plan',
    });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const resultValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        let resultValue: string | undefined = value;

        if (!value) {
          resultValue = daily_plan ? `${daily_plan}` : undefined;
        } else if (+value > 200 * 14) {
          resultValue = `${200 * 14}`;
        }

        return resultValue;
      };

      return resultValue(event);
    };

    return (
      <InputWrapper
        label={'Желаемое количество откликов всего (максимум 2800)'}
      >
        <ControlledInput<CreateMailingRequest>
          name={'plan'}
          placeholder={'Максимум 2800'}
          onChange={handleOnChange}
          control={control}
          type={'number'}
          showRequiredStar
          valueAsNumber
          showError
          rules={{
            required: 'Обязательное поле',
            max: {
              value: 200 * 14,
              message: 'Не более 2800',
            },
            min: daily_plan && {
              value: daily_plan,
              message:
                'Значение не может быть меньше желаемого количества откликов в день',
            },
            pattern: {
              value: /^\d+$/,
              message: 'Только цифры',
            },
          }}
        />
      </InputWrapper>
    );
  });
