export const setStorageItem = <T = any>(
  key: string,
  data: T,
): T | undefined => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
    return data;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getStorageItem = <T = any>(key: string): T | undefined => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key);
};
export const removeStorageItemsArray = (keys: string[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const addItemsFromArrayLocalStorage = <T = any>(
  key: string,
  item: T | T[],
): T[] | undefined => {
  try {
    const values = getStorageItem<T[]>(key);
    let newValues: T[];
    if (Array.isArray(item)) {
      newValues = [...new Set([...(values ?? []), ...item])];
    } else {
      newValues = [...new Set([...(values ?? []), item])];
    }
    return setStorageItem<T[]>(key, newValues);
  } catch (e) {
    console.log(e);
    return;
  }
};
export const filterItemFromArrayLocalStorage = <T = any>(
  key: string,
  callback: (item: T) => boolean,
): T[] | undefined => {
  try {
    const values = getStorageItem<T[]>(key);
    if (!values) return;
    return setStorageItem<T[]>(key, values.filter(callback));
  } catch (e) {
    console.log(e);
    return;
  }
};
