// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUpPage_container__LpEW- {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.SignUpPage_centredContent__gU-Ez {
  width: 25.75rem;
  gap: 2.25rem;
  display: flex;
  flex-direction: column;
}
.SignUpPage_centredContent__gU-Ez > :nth-child(1) {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/SignUpPage/SignUpPage.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAHF;;AAMA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAHF;AAKE;EACE,kBAAA;AAHJ","sourcesContent":["@use 'src/assets/styles/texts.scss' as text;\n@import 'src/assets/styles/utils';\n\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.centredContent {\n  width: clamp(17.5rem, 25.75rem, 25.75rem);\n  gap: rem(36);\n  display: flex;\n  flex-direction: column;\n\n  & > :nth-child(1) {\n    align-self: center;\n  }\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SignUpPage_container__LpEW-`,
	"centredContent": `SignUpPage_centredContent__gU-Ez`
};
export default ___CSS_LOADER_EXPORT___;
