import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../../Components/UI-kit/Logo/Logo';
import image from './main-image.png';
import styles from './MainPage.module.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuth } from '../../../redux/slices/authSlice';
import { AppRoutes } from '../../../config/navigation.data';

export const MainPage = () => {
  const isAuthed = useSelector(selectIsAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthed) navigate(AppRoutes.mailing);
  }, [isAuthed]);

  return (
    <div className={styles.centrBlock}>
      <div className={styles.rightContent}>
        <Logo />
        <h3>
          Наш сервис автоматически откликается на вакансии HeadHunter. Искать
          работу теперь проще простого.
        </h3>
        {!isAuthed && (
          <div className={styles.buttons}>
            <Button
              onClick={() => {
                navigate('/signUp');
              }}
              interfaceType={ButtonType.Primary}
            >
              Зарегистрироваться
            </Button>
            <Button
              onClick={() => {
                navigate('/signIn');
              }}
              interfaceType={ButtonType.Secondary}
            >
              Войти
            </Button>
          </div>
        )}
      </div>
      <div className={styles.image}>
        <img src={image} alt='image' />
      </div>
    </div>

    // <a
    //   href={'https://t.me/hhmailer_support'}
    //   target={'_blank'}
    //   rel='noreferrer'
    // >
    //   Поддержка hhmailer
    // </a>
    // <div className={styles.background} />
    // </div>
  );
};
