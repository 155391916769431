// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_relativeContainer__v1ykP {
  position: relative;
  min-width: var(--menu-width);
  min-height: var(--menu-height);
}

.Container_container__uMTgL {
  display: flex;
  flex-direction: column;
  border-radius: 1.125rem;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
}
@media (min-width: 1024px) {
  .Container_container__uMTgL {
    position: relative;
  }
}
@media (min-width: 640px) {
  .Container_container__uMTgL {
    max-width: 21.4375rem;
    width: 21.4375rem;
  }
}

.Container_horizonCollapse__NTzx2 {
  max-width: 3.75rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MenuBar/Container/Container.module.scss","webpack://./src/assets/styles/screens.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,4BAAA;EACA,8BAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,oBAAA;EAEA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AAHF;ACYE;EDpBF;IAcI,kBAAA;EAFF;AACF;ACHE;EDVF;IAiBI,qBAAA;IACA,iBAAA;EAAF;AACF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":["@use 'src/assets/styles/screens.scss' as screen;\n@import 'src/assets/styles/utils';\n\n.relativeContainer {\n  position: relative;\n  min-width: var(--menu-width);\n  min-height: var(--menu-height);\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  border-radius: rem(18);\n  transition: all 0.3s;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 50;\n  height: 100%;\n  width: 100%;\n\n  @include screen.lg {\n    position: relative;\n  }\n  @include screen.sm {\n    max-width: rem(343);\n    width: rem(343);\n  }\n}\n\n.horizonCollapse {\n  max-width: rem(60);\n}\n","$xxs: 320px;\n$xs: 560px;\n$sm: 640px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@mixin xxs {\n  @media (min-width: $xxs) {\n    @content;\n  }\n}\n@mixin xs {\n  @media (min-width: $xs) {\n    @content;\n  }\n}\n@mixin sm {\n  @media (min-width: $sm) {\n    @content;\n  }\n}\n@mixin md {\n  @media (min-width: $md) {\n    @content;\n  }\n}\n@mixin lg {\n  @media (min-width: $lg) {\n    @content;\n  }\n}\n@mixin xl {\n  @media (min-width: $xl) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media (min-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relativeContainer": `Container_relativeContainer__v1ykP`,
	"container": `Container_container__uMTgL`,
	"horizonCollapse": `Container_horizonCollapse__NTzx2`
};
export default ___CSS_LOADER_EXPORT___;
