import { FC, memo } from 'react';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import { CreateMailingRequest, ScheduleEnum } from '../../../../../Entities';

const ScheduleOptions: { value: ScheduleEnum; label: string }[] = [
  { value: ScheduleEnum.FULL_DAY, label: 'Полный день' },
  { value: ScheduleEnum.FLY_IN_FLY_OUT, label: 'Вахтовый метод' },
  { value: ScheduleEnum.SHIFT, label: 'Сменный график' },
  { value: ScheduleEnum.FLEXIBLE, label: 'Гибкий график' },
  { value: ScheduleEnum.REMOTE, label: 'Удаленная работа' },
];

interface Props {
  control: Control<CreateMailingRequest>;
}

export const ScheduleSelector: FC<Props> = memo(({ control }) => {
  return (
    <SelectorWrapper label={'Выберите предпочтительный график'}>
      <HookFormReactSelector<CreateMailingRequest>
        defaultValue={[ScheduleOptions[0].value]}
        control={control}
        name={'schedule'}
        isLoading={false}
        isMulti={true}
        options={ScheduleOptions}
        className={'w-full'}
        menuPlacement={'top'}
      />
    </SelectorWrapper>
  );
});
