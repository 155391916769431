/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `0` - Любой регион
 * * `1` - Москва
 * * `2` - Санкт-Петербург
 * * `3` - Екатеринбург
 * * `4` - Новосибирск
 * * `5` - Украина
 * * `6` - Австралия
 * * `7` - Австрия
 * * `8` - Майкоп
 * * `9` - Азербайджан
 * * `10` - Горно-Алтайск
 * * `11` - Барнаул
 * * `12` - Благовещенск (Амурская область)
 * * `13` - Армения
 * * `14` - Архангельск
 * * `15` - Астрахань
 * * `16` - Беларусь
 * * `17` - Белгород
 * * `18` - Бельгия
 * * `19` - Брянск
 * * `20` - Улан-Удэ
 * * `21` - Великобритания
 * * `22` - Владивосток
 * * `23` - Владимир
 * * `24` - Волгоград
 * * `25` - Вологда
 * * `26` - Воронеж
 * * `27` - Германия
 * * `28` - Грузия
 * * `29` - Махачкала
 * * `30` - Дания
 * * `31` - Биробиджан
 * * `32` - Иваново (Ивановская область)
 * * `33` - Израиль
 * * `34` - Магас
 * * `35` - Иркутск
 * * `36` - Ирландия
 * * `37` - Испания
 * * `38` - Италия
 * * `39` - Нальчик
 * * `40` - Казахстан
 * * `41` - Калининград
 * * `42` - Элиста
 * * `43` - Калуга
 * * `44` - Петропавловск-Камчатский
 * * `45` - Канада
 * * `46` - Черкесск
 * * `47` - Кемерово
 * * `48` - Кыргызстан
 * * `49` - Киров (Кировская область)
 * * `50` - Китай
 * * `51` - Сыктывкар
 * * `52` - Кострома
 * * `53` - Краснодар
 * * `54` - Красноярск
 * * `55` - Курган
 * * `56` - Курск
 * * `57` - Латвия
 * * `58` - Липецк
 * * `59` - Литва
 * * `60` - Магадан
 * * `61` - Йошкар-Ола
 * * `62` - Молдавия
 * * `63` - Саранск
 * * `64` - Мурманск
 * * `65` - Нидерланды
 * * `66` - Нижний Новгород
 * * `67` - Великий Новгород
 * * `68` - Омск
 * * `69` - Орел
 * * `70` - Оренбург
 * * `71` - Пенза
 * * `72` - Пермь
 * * `73` - Петрозаводск
 * * `74` - Польша
 * * `75` - Псков
 * * `76` - Ростов-на-Дону
 * * `77` - Рязань
 * * `78` - Самара
 * * `79` - Саратов
 * * `80` - Якутск
 * * `81` - Южно-Сахалинск
 * * `82` - Владикавказ
 * * `83` - Смоленск
 * * `84` - Ставрополь
 * * `85` - США
 * * `86` - Таджикистан
 * * `87` - Тамбов
 * * `88` - Казань
 * * `89` - Тверь
 * * `90` - Томск
 * * `91` - Кызыл
 * * `92` - Тула
 * * `93` - Туркменистан
 * * `94` - Турция
 * * `95` - Тюмень
 * * `96` - Ижевск
 * * `97` - Узбекистан
 * * `98` - Ульяновск
 * * `99` - Уфа
 * * `100` - Финляндия
 * * `101` - Франция
 * * `102` - Хабаровск
 * * `103` - Абакан
 * * `104` - Челябинск
 * * `105` - Грозный
 * * `106` - Чита
 * * `107` - Чебоксары
 * * `108` - Швейцария
 * * `109` - Эстония
 * * `110` - Южная Корея
 * * `111` - Япония
 * * `112` - Ярославль
 * * `113` - Россия
 * * `114` - Венгрия
 * * `115` - Киев
 * * `116` - Винница
 * * `117` - Днепр (Днепропетровск)
 * * `118` - Донецк
 * * `119` - Житомир
 * * `120` - Запорожье
 * * `121` - Ивано-Франковск
 * * `122` - Кировоград
 * * `123` - Луганск
 * * `124` - Луцк
 * * `125` - Львов
 * * `126` - Николаев
 * * `127` - Одесса
 * * `128` - Полтава
 * * `129` - Ровно
 * * `130` - Севастополь
 * * `131` - Симферополь
 * * `132` - Сумы
 * * `133` - Тернополь
 * * `134` - Ужгород
 * * `135` - Харьков
 * * `136` - Херсон
 * * `137` - Хмельницкий
 * * `138` - Черкассы
 * * `139` - Черновцы
 * * `140` - Чернигов
 * * `141` - Московская область - Север
 * * `142` - Московская область - Юг
 * * `143` - Московская область - Запад
 * * `144` - Московская область - Восток
 * * `145` - Ленинградская область
 * * `146` - Сербия
 * * `147` - Ханты-Мансийск
 * * `148` - Тайвань
 * * `149` - Швеция
 * * `150` - Аксай (Казахстан)
 * * `151` - Аксу (Павлодар.обл)
 * * `152` - Актау
 * * `153` - Атырау
 * * `154` - Актобе
 * * `155` - Арысь (ЮКО)
 * * `156` - Алга (Актюбинская обл)
 * * `157` - Аягоз (ВКО)
 * * `158` - Аральск
 * * `159` - Астана
 * * `160` - Алматы
 * * `161` - Аркалык
 * * `162` - Баутино
 * * `163` - Бейнеу (Мангистауская обл)
 * * `164` - Балхаш
 * * `165` - Ерейментау (Акмол.обл)
 * * `166` - Жезказган
 * * `167` - Жанатас
 * * `168` - Зайсан
 * * `169` - Алтай (Зыряновск)
 * * `170` - Иссык
 * * `171` - Капчагай
 * * `172` - Костанай
 * * `173` - Кентау (ЮКО)
 * * `174` - Кызылорда
 * * `175` - Казалы (Кызылорд. обл)
 * * `176` - Кокшетау
 * * `177` - Караганда
 * * `178` - Каратау
 * * `179` - Ленгер (ЮКО)
 * * `180` - Петропавловск
 * * `181` - Павлодар
 * * `182` - Рудный
 * * `183` - Риддер (ВКО)
 * * `184` - Сатпаев
 * * `185` - Семей
 * * `186` - Солнечный (ПГТ, Павлодарская обл)
 * * `187` - Тараз
 * * `188` - Талдыкорган
 * * `189` - Текели
 * * `190` - Темиртау
 * * `191` - Торгай
 * * `192` - Туркестан
 * * `193` - Уральск
 * * `194` - Усть-Каменогорск
 * * `195` - Хромтау
 * * `196` - Шемонаиха (ВКО)
 * * `197` - Щучинск
 * * `198` - Экибастуз
 * * `199` - Чехия
 * * `200` - Болгария
 * * `202` - Гвинея
 * * `203` - Вьетнам
 * * `204` - Новая Зеландия
 * * `205` - Шымкент
 * * `206` - Люксембург
 * * `207` - Норвегия
 * * `208` - ОАЭ
 * * `209` - Индия
 * * `210` - Катар
 * * `211` - ЮАР
 * * `212` - Тольятти
 * * `213` - Греция
 * * `214` - Дудинка (Красноярский край)
 * * `215` - Тура (Красноярский край)
 * * `216` - Агинское (Забайкальский АО)
 * * `217` - Усть-Ордынский
 * * `218` - Палана
 * * `219` - Анадырь
 * * `220` - Дальневосточный округ
 * * `221` - Восточно-Сибирский округ
 * * `222` - Западно-Сибирский округ
 * * `223` - Уральский округ
 * * `224` - Черноморско-Кавказский округ
 * * `225` - Южный округ
 * * `226` - Средне-Приволжский округ
 * * `227` - Верхне-Приволжский округ
 * * `228` - Восточно-Центральный округ
 * * `229` - Северо-Центральный округ
 * * `230` - Юго-Центральный округ
 * * `231` - Северо-Западный округ
 * * `232` - Центральный округ
 * * `233` - Сингапур
 * * `234` - Румыния
 * * `235` - Аргентина
 * * `236` - Кипр
 * * `237` - Сочи
 * * `238` - Малайзия
 * * `239` - Шотландия
 * * `240` - Нигерия
 * * `241` - Португалия
 * * `242` - Эквадор
 * * `243` - Бразилия
 * * `244` - Тунис
 * * `245` - Камбоджа
 * * `246` - Норильск
 * * `247` - Дзержинск (Нижегородская область)
 * * `248` - Арзамас
 * * `249` - Саров
 * * `300` - Таиланд
 * * `301` - Обнинск
 * * `302` - Исландия
 * * `303` - Бангладеш
 * * `304` - Салехард
 * * `305` - Египет
 * * `306` - Буркина Фасо
 * * `311` - Демократическая Республика Конго
 * * `312` - Республика Конго
 */
export enum AreasEnum {
    _0 = '0',
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47',
    _48 = '48',
    _49 = '49',
    _50 = '50',
    _51 = '51',
    _52 = '52',
    _53 = '53',
    _54 = '54',
    _55 = '55',
    _56 = '56',
    _57 = '57',
    _58 = '58',
    _59 = '59',
    _60 = '60',
    _61 = '61',
    _62 = '62',
    _63 = '63',
    _64 = '64',
    _65 = '65',
    _66 = '66',
    _67 = '67',
    _68 = '68',
    _69 = '69',
    _70 = '70',
    _71 = '71',
    _72 = '72',
    _73 = '73',
    _74 = '74',
    _75 = '75',
    _76 = '76',
    _77 = '77',
    _78 = '78',
    _79 = '79',
    _80 = '80',
    _81 = '81',
    _82 = '82',
    _83 = '83',
    _84 = '84',
    _85 = '85',
    _86 = '86',
    _87 = '87',
    _88 = '88',
    _89 = '89',
    _90 = '90',
    _91 = '91',
    _92 = '92',
    _93 = '93',
    _94 = '94',
    _95 = '95',
    _96 = '96',
    _97 = '97',
    _98 = '98',
    _99 = '99',
    _100 = '100',
    _101 = '101',
    _102 = '102',
    _103 = '103',
    _104 = '104',
    _105 = '105',
    _106 = '106',
    _107 = '107',
    _108 = '108',
    _109 = '109',
    _110 = '110',
    _111 = '111',
    _112 = '112',
    _113 = '113',
    _114 = '114',
    _115 = '115',
    _116 = '116',
    _117 = '117',
    _118 = '118',
    _119 = '119',
    _120 = '120',
    _121 = '121',
    _122 = '122',
    _123 = '123',
    _124 = '124',
    _125 = '125',
    _126 = '126',
    _127 = '127',
    _128 = '128',
    _129 = '129',
    _130 = '130',
    _131 = '131',
    _132 = '132',
    _133 = '133',
    _134 = '134',
    _135 = '135',
    _136 = '136',
    _137 = '137',
    _138 = '138',
    _139 = '139',
    _140 = '140',
    _141 = '141',
    _142 = '142',
    _143 = '143',
    _144 = '144',
    _145 = '145',
    _146 = '146',
    _147 = '147',
    _148 = '148',
    _149 = '149',
    _150 = '150',
    _151 = '151',
    _152 = '152',
    _153 = '153',
    _154 = '154',
    _155 = '155',
    _156 = '156',
    _157 = '157',
    _158 = '158',
    _159 = '159',
    _160 = '160',
    _161 = '161',
    _162 = '162',
    _163 = '163',
    _164 = '164',
    _165 = '165',
    _166 = '166',
    _167 = '167',
    _168 = '168',
    _169 = '169',
    _170 = '170',
    _171 = '171',
    _172 = '172',
    _173 = '173',
    _174 = '174',
    _175 = '175',
    _176 = '176',
    _177 = '177',
    _178 = '178',
    _179 = '179',
    _180 = '180',
    _181 = '181',
    _182 = '182',
    _183 = '183',
    _184 = '184',
    _185 = '185',
    _186 = '186',
    _187 = '187',
    _188 = '188',
    _189 = '189',
    _190 = '190',
    _191 = '191',
    _192 = '192',
    _193 = '193',
    _194 = '194',
    _195 = '195',
    _196 = '196',
    _197 = '197',
    _198 = '198',
    _199 = '199',
    _200 = '200',
    _202 = '202',
    _203 = '203',
    _204 = '204',
    _205 = '205',
    _206 = '206',
    _207 = '207',
    _208 = '208',
    _209 = '209',
    _210 = '210',
    _211 = '211',
    _212 = '212',
    _213 = '213',
    _214 = '214',
    _215 = '215',
    _216 = '216',
    _217 = '217',
    _218 = '218',
    _219 = '219',
    _220 = '220',
    _221 = '221',
    _222 = '222',
    _223 = '223',
    _224 = '224',
    _225 = '225',
    _226 = '226',
    _227 = '227',
    _228 = '228',
    _229 = '229',
    _230 = '230',
    _231 = '231',
    _232 = '232',
    _233 = '233',
    _234 = '234',
    _235 = '235',
    _236 = '236',
    _237 = '237',
    _238 = '238',
    _239 = '239',
    _240 = '240',
    _241 = '241',
    _242 = '242',
    _243 = '243',
    _244 = '244',
    _245 = '245',
    _246 = '246',
    _247 = '247',
    _248 = '248',
    _249 = '249',
    _300 = '300',
    _301 = '301',
    _302 = '302',
    _303 = '303',
    _304 = '304',
    _305 = '305',
    _306 = '306',
    _311 = '311',
    _312 = '312',
}
