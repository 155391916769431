import React, { memo } from 'react';
import { InputWrapper } from './InputWrapper';
import { Control } from 'react-hook-form';
import { ControlledInput } from '../../../../../Components';
import { CreateMailingRequest } from '../../../../../Entities';

interface ICoverLetterInputProps {
  control: Control<CreateMailingRequest>;
}

export const DesiredVacancySearchKeyInput: React.FC<ICoverLetterInputProps> =
  memo(({ control }) => {
    return (
      <InputWrapper label={'Желаемая должность'}>
        <ControlledInput<CreateMailingRequest>
          control={control}
          name={'search_key'}
          showError
          showRequiredStar
          placeholder={'Название должности'}
          rules={{
            required: 'Обязательное поле',
          }}
        />
      </InputWrapper>
    );
  });
