import React from 'react';
import styles from './TimeSorter.module.scss';
import { ReactComponent as AscendingIcon } from 'assets/svg/timeSorter/ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/svg/timeSorter/descending.svg';
import cn from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sort?: 'asc' | 'desc';
  onChange: () => void;
}

export const TimeSorter: React.FC<Props> = (props) => {
  const { sort = 'asc', onChange, className, ...otherProps } = props;

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    switch (key) {
      case ' ':
      case 'Enter':
        onChange();
    }
  };

  return (
    <div
      className={cn(styles.container, className)}
      role='switch'
      aria-checked={sort === 'asc' ? 'false' : 'true'}
      tabIndex={0}
      onClick={onChange}
      onKeyDown={onKeyDown}
      {...otherProps}
    >
      {
        {
          desc: <AscendingIcon />,
          asc: <DescendingIcon />,
        }[sort]
      }
      <div className={styles.divider} />
      {
        {
          desc: <p>Сначала новые</p>,
          asc: <p>Сначала старые</p>,
        }[sort]
      }
    </div>
  );
};
