import { BaseModal } from 'Components/UI-kit/BaseModal/BaseModal';
import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import styles from './ContinueModal.module.scss';
import { HookFormTextInput } from 'Components/UI-kit/TextInput/HookFormTextInput';
import { useForm } from 'react-hook-form';
import { PatchedCreateMailingRequest } from 'Entities';
import {
  useGetMailingListQuery,
  useUpdateMailingMutation,
} from 'services/RTKService/mailing/endpoints/mailingApi';

interface ContinueModalProps {
  openContinueModal: boolean;
  setOpenContinueModal: (value: boolean) => void;
  mailingId: number;
}
export const ContinueModal = ({
  openContinueModal,
  setOpenContinueModal,
  mailingId,
}: ContinueModalProps) => {
  const { data } = useGetMailingListQuery();
  const { plan } = data?.entities[mailingId] || {};
  const { handleSubmit, control } = useForm<PatchedCreateMailingRequest>({
    defaultValues: {
      plan,
    },
  });
  const [update, { isLoading }] = useUpdateMailingMutation();
  const onHandleSave = handleSubmit(async (data) => {
    const patchedDesired_number_of_applies =
      (data?.plan ? +data?.plan : 0) + (plan ? +plan : 0);

    await update({
      id: mailingId,
      patchedMailing: {
        plan: patchedDesired_number_of_applies,
      },
      customOptions: {
        successMessage: 'Отклики добавлены',
        showSuccessToast: true,
      },
    });
    setOpenContinueModal(false);
  });
  return (
    <BaseModal
      title='Продолжить рассылку?'
      isOpen={openContinueModal}
      onClose={() => setOpenContinueModal(false)}
    >
      <div className={styles.continueModal}>
        <HookFormTextInput
          name='plan'
          type='number'
          control={control}
          placeholder='Количество откликов'
          label='Сколько еще отзывов вы хотите отправить?'
        />
        <div className={styles.buttons}>
          <Button
            interfaceType={ButtonType.Primary}
            onClick={onHandleSave}
            disabled={isLoading}
          >
            Сохранить
          </Button>
          <Button
            interfaceType={ButtonType.Secondary}
            onClick={() => setOpenContinueModal(false)}
          >
            Отмена
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
