/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `full` - полная занятость
 * * `part` - частичная занятость
 * * `project` - проектная деятельность
 * * `volunteer` - волонтерство
 * * `probation` - стажировка
 */
export enum EmploymentTypeEnum {
    FULL = 'full',
    PART = 'part',
    PROJECT = 'project',
    VOLUNTEER = 'volunteer',
    PROBATION = 'probation',
}
