import { Option } from '../Option/Option';
import { OnHandleOptionType, SelectOption, SelectorValue } from '../types';
import { Flatten } from 'Shared/interfaces';

type RenderOptionsProps = {
  options: SelectOption[];
  value: SelectorValue;
  activeIndex: number;
  selectorId: string;
  searchValue: string;
  onHandleOption: OnHandleOptionType;
  onDeleteOption?: (value: Flatten<SelectorValue>) => void;
};

export const RenderOptions = ({
  options,
  value,
  activeIndex,
  selectorId,
  onHandleOption,
  onDeleteOption,
}: RenderOptionsProps) => {
  if (!options.length) return <Option>Нет опций</Option>;

  const prepareOptions = options.reduce((acc: JSX.Element[], option, index) => {
    if (value && Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (value.includes(option.value)) return acc;
    }

    const onClick = () => {
      onHandleOption({ selectValue: option.value, index });
    };

    const onHandleDelete = onDeleteOption
      ? () => {
          onDeleteOption(option.value);
        }
      : undefined;

    return [
      ...acc,
      <Option
        onHandleDelete={onHandleDelete}
        onClick={onClick}
        key={`${option.value} ${index === activeIndex}`}
        renderIcon={option.renderIcon}
        isAllOption={option.isAllOption}
        isSelect={option.value === value}
        isActive={index === activeIndex}
        id={selectorId + index}
      >
        {option.label}
      </Option>,
    ];
  }, []);

  if (!prepareOptions.length) {
    return <Option>Нет опций</Option>;
  }
  return <>{prepareOptions}</>;
};
