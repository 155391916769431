// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThemesSwitch_switch__OFL3T {
  display: flex;
  justify-content: center;
  column-gap: 0.25rem;
  padding: 0.375rem;
  border-radius: 1.75rem;
  box-shadow: var(--shadows-indented);
  background: var(--frame-fill-indented);
}
.ThemesSwitch_switch__OFL3T button {
  flex: 1 1;
  border-radius: 1.75rem;
}

.ThemesSwitch_activeButton__I\\+iTg {
  background: var(--frame-fill-convex) !important;
  box-shadow: var(--shadows-convex);
}

.ThemesSwitch_rounded__tL2kL {
  border-radius: 1.75rem;
}
.ThemesSwitch_rounded__tL2kL button {
  border-radius: 1.75rem;
}

.ThemesSwitch_squarer__y-1ov {
  border-radius: 1rem;
  background: var(--frame-fill-moreIndented);
}
.ThemesSwitch_squarer__y-1ov button {
  border-radius: 0.75rem;
}

.ThemesSwitch_vertical__wMP-l {
  flex-direction: column;
  padding: 0;
  gap: 0.25rem;
  border-radius: 0.5rem 0.5rem 0.75rem 0.75rem;
}
.ThemesSwitch_vertical__wMP-l button {
  padding: 0.875rem;
  border-radius: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
}
.ThemesSwitch_vertical__wMP-l button > span {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/ThemesSwitch/ThemesSwitch.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,mCAAA;EACA,sCAAA;AAFF;AAIE;EACE,SAAA;EACA,sBAAA;AAFJ;;AAMA;EACE,+CAAA;EACA,iCAAA;AAHF;;AAMA;EACE,sBAAA;AAHF;AAKE;EACE,sBAAA;AAHJ;;AAOA;EACE,mBAAA;EACA,0CAAA;AAJF;AAME;EACE,sBAAA;AAJJ;;AAOA;EACE,sBAAA;EACA,UAAA;EACA,YAAA;EACA,4CAAA;AAJF;AAKE;EACE,iBAAA;EACA,qBAAA;EACA,uBAAA;EAAA,kBAAA;AAHJ;AAII;EACE,aAAA;AAFN","sourcesContent":["@use 'src/assets/styles/texts.scss' as text;\n@import 'src/assets/styles/utils';\n\n.switch {\n  display: flex;\n  justify-content: center;\n  column-gap: rem(4);\n  padding: rem(6);\n  border-radius: rem(28);\n  box-shadow: var(--shadows-indented);\n  background: var(--frame-fill-indented);\n\n  button {\n    flex: 1 1 0;\n    border-radius: rem(28);\n  }\n}\n\n.activeButton {\n  background: var(--frame-fill-convex) !important;\n  box-shadow: var(--shadows-convex);\n}\n\n.rounded {\n  border-radius: rem(28);\n\n  button {\n    border-radius: rem(28);\n  }\n}\n\n.squarer {\n  border-radius: rem(16);\n  background: var(--frame-fill-moreIndented);\n\n  button {\n    border-radius: rem(12);\n  }\n}\n.vertical {\n  flex-direction: column;\n  padding: 0;\n  gap: rem(4);\n  border-radius: 0.5rem 0.5rem rem(12) rem(12);\n  & button {\n    padding: rem(14);\n    border-radius: 0.5rem;\n    width: fit-content;\n    & > span {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `ThemesSwitch_switch__OFL3T`,
	"activeButton": `ThemesSwitch_activeButton__I+iTg`,
	"rounded": `ThemesSwitch_rounded__tL2kL`,
	"squarer": `ThemesSwitch_squarer__y-1ov`,
	"vertical": `ThemesSwitch_vertical__wMP-l`
};
export default ___CSS_LOADER_EXPORT___;
