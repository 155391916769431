import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { TextArea, TextAreaProps } from './TextArea';

interface Props<T extends FieldValues>
  extends Omit<TextAreaProps, 'changeValue' | 'value' | 'onBlur'> {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: RegisterOptions<T, FieldPath<T>>;
}

export const WithHookFormControlTextArea = <T extends FieldValues>({
  control,
  name,
  rules,
  ...otherProps
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <TextArea
            onBlur={onBlur}
            onChange={onChange}
            value={value ? value : ''}
            error={error?.message}
            {...otherProps}
          />
        );
      }}
    />
  );
};
