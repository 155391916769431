import React, { memo } from 'react';

import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import {
  CreateMailingRequest,
  EmploymentTypeEnum,
} from '../../../../../Entities';

const employmentType: { value: EmploymentTypeEnum; label: string }[] = [
  { value: EmploymentTypeEnum.FULL, label: 'Полная занятость' },
  { value: EmploymentTypeEnum.PART, label: 'Частичная занятость' },
  { value: EmploymentTypeEnum.PROJECT, label: 'Проектная деятельность' },
  { value: EmploymentTypeEnum.VOLUNTEER, label: 'Волонтерство' },
  { value: EmploymentTypeEnum.PROBATION, label: 'Стажировка' },
];

interface IHookFormEmploymentTypeSelectorProps {
  control: Control<CreateMailingRequest>;
}

export const HookFormEmploymentTypeSelector: React.FC<IHookFormEmploymentTypeSelectorProps> =
  memo(({ control }) => {
    return (
      <SelectorWrapper label={'Тип работы'} options={employmentType}>
        <HookFormReactSelector<CreateMailingRequest>
          placeholder={'Тип работы'}
          defaultValue={[employmentType[0].value, employmentType[1].value]}
          control={control}
          name={'employment_type'}
          isLoading={false}
          isMulti={true}
          options={employmentType}
          className={'w-full'}
        />
      </SelectorWrapper>
    );
  });
