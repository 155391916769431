import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import { ContinueModal } from './ContinueModal/ContinueModal';
import { useState } from 'react';

interface ContinueButtonProps {
  id: number;
  className?: string;
}

export const ContinueButton = ({ id, className }: ContinueButtonProps) => {
  const [openContinueModal, setOpenContinueModal] = useState(false);

  const onHandleContinue = () => {
    setOpenContinueModal(true);
  };
  return (
    <>
      <Button
        className={className}
        onClick={onHandleContinue}
        interfaceType={ButtonType.Primary}
      >
        Продолжить
      </Button>
      <ContinueModal
        mailingId={id}
        openContinueModal={openContinueModal}
        setOpenContinueModal={setOpenContinueModal}
      />
    </>
  );
};
