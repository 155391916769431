import styles from './MailingSteps.module.scss';
import { useLocation } from 'react-router-dom';
import { MailingStepsNavigationItem } from '../../../config/navigation.data';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface Props
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  navigationItems?: MailingStepsNavigationItem[];
  onHandleStep?: (path: MailingStepsNavigationItem['path']) => void;
}
export const MailingSteps = ({
  navigationItems = [],
  className,
  onHandleStep,
  ...rest
}: Props) => {
  const location = useLocation();
  return (
    <ul className={[styles.mailingSteps, className].join(' ')} {...rest}>
      {navigationItems.map(({ path }, index) => {
        const onClick = () => {
          onHandleStep && onHandleStep(path);
        };
        return (
          <li
            onClick={onClick}
            className={location.pathname === path ? styles.active : ''}
            key={path}
          >
            {index + 1} шаг
          </li>
        );
      })}
    </ul>
  );
};
