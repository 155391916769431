import React, { memo } from 'react';
import { ButtonWithLoader } from '../../../../Components';
import { useSelector } from 'react-redux';
import { _selectHhUser } from '../../../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { useGetStartStopMailingTask } from '../../mailingHooks/useGetStartStopMailingTask';
import { StatusEnum } from '../../../../Entities';

const getDisabledStatus = (status?: StatusEnum): boolean | undefined => {
  switch (status) {
    case StatusEnum.PLAN_COMPLETED:
    case StatusEnum.OUT_OF_VACANCIES:
    case StatusEnum.HH_LIMIT_REACHED:
      return true;
  }
};

interface IControlButtonsGroupProps {
  id?: number;
}

export const ControlButtonsGroup: React.FC<IControlButtonsGroupProps> = memo(
  ({ id }) => {
    const hhUserData = useSelector(_selectHhUser);
    const { taskData, onStartTask, onStopTask, isLoading } =
      useGetStartStopMailingTask(id);

    const onClickStop = async () => {
      await onStopTask();
    };

    const onClickStart = async () => {
      await onStartTask();
    };

    if (hhUserData && taskData) {
      return (
        <div>
          {taskData.status === StatusEnum.RUNNING ||
          taskData.status === StatusEnum.PENDING ? (
            <ButtonWithLoader
              className={'bg-red-500 hover:bg-red-400 focus:ring-red-400'}
              isLoading={isLoading}
              onClick={onClickStop}
            >
              Остановить рассылку
            </ButtonWithLoader>
          ) : (
            <ButtonWithLoader
              isLoading={isLoading}
              onClick={onClickStart}
              disabled={getDisabledStatus(taskData.status)}
            >
              Начать рассылку
            </ButtonWithLoader>
          )}
        </div>
      );
    } else return null;
  },
);
