import { DivProps } from 'Shared/interfaces';
import { ProgressBar, ProgressBarColor } from '../ProgressBar/ProgressBar';
import { ResponseCounter } from '../ResponseCounter/ResponseCounter';
import styles from './Statistics.module.scss';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  dailyFeedbackRate: number;
  totalFeedbackRate: number;
  progressBarColor?: ProgressBarColor;
}

export const Statistics = ({
  dailyFeedbackRate,
  totalFeedbackRate,
  progressBarColor,
  className,
  ...otherProps
}: Props) => {
  {
    /* TODO: когда будет готов бек, то удалить TempStatistics */
  }
  if (totalFeedbackRate) {
    return (
      <TempStatistics
        className={className}
        totalFeedbackRate={dailyFeedbackRate}
      />
    );
  }
  return (
    <div className={[styles.statistics, className].join(' ')} {...otherProps}>
      <div className={styles.limit}>
        <h4>Суточный лимит</h4>
        <ProgressBar
          count={dailyFeedbackRate}
          totalCount={totalFeedbackRate}
          color={progressBarColor}
        />
      </div>
      <div className={styles.responseCounter}>
        <ResponseCounter
          count={dailyFeedbackRate}
          totalCount={totalFeedbackRate}
        />
        <p>откликов</p>
      </div>
    </div>
  );
};
const TempStatistics = ({
  totalFeedbackRate,
  className,
  ...otherProps
}: DivProps & {
  totalFeedbackRate: number;
}) => {
  return (
    <div
      className={[styles.TempStatistics, className].join(' ')}
      {...otherProps}
    >
      <h4>Количество откликов за все время:</h4>
      <p>{totalFeedbackRate}</p>
    </div>
  );
};
