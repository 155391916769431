import { ButtonType } from 'Components/UI-kit/Buttons/Button';
import { useEffect, useState } from 'react';
import { CreateMailingRequest } from 'Entities';
import { useFormContext } from 'react-hook-form';
import {
  getStorageItem,
  removeStorageItem,
} from 'services/localStorageService/localStorageService';
import { entries } from 'Shared/Utils/typedEntries';
import { BaseModal } from 'Components/UI-kit/BaseModal/BaseModal';
import { Button } from 'Components/UI-kit/Buttons/Button';
import { FORM_VALUES } from '../../constants';

export const LoadFormModal = () => {
  const [show, setShow] = useState(false);
  const { setValue, reset } = useFormContext<CreateMailingRequest>();

  useEffect(() => {
    const formValuesFromLocalStorage =
      getStorageItem<CreateMailingRequest>(FORM_VALUES);
    if (formValuesFromLocalStorage) {
      setShow(true);
    }
  }, []);

  const onHandleLoadFormInLocalStorage = () => {
    const formValues = getStorageItem<CreateMailingRequest>(FORM_VALUES);
    entries(formValues).forEach((value) => {
      if (!value) return;
      setValue(value[0], value[1]);
    });
    removeStorageItem(FORM_VALUES);
    setShow(false);
  };

  const onHandleStartAgain = () => {
    removeStorageItem(FORM_VALUES);
    reset();
    setShow(false);
  };

  return (
    <BaseModal isOpen={show} onClose={() => setShow(false)}>
      <p>Хотите ли вы продолжить создание предыдущей рассылки?</p>
      <div className={'flex flex-row mt-6 flex-1 gap-3 justify-end'}>
        <Button
          onClick={onHandleLoadFormInLocalStorage}
          interfaceType={ButtonType.Primary}
        >
          Продолжить
        </Button>
        <Button
          onClick={onHandleStartAgain}
          interfaceType={ButtonType.Secondary}
        >
          Начать заново
        </Button>
      </div>
    </BaseModal>
  );
};
