import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from 'react-hook-form';

import { ISelectOption, ReactSelector } from './ReactSelector';
import { Options } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import cn from 'classnames';

interface IHookFormReactSelectorProps<T extends FieldValues>
  extends StateManagerProps {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: Omit<
    RegisterOptions<T, FieldPath<T>>,
    'valueAsNumber' | 'valueAsDate' | 'disabled'
  >;
  defaultValue?: PathValue<T, Path<T>>;
  options?: Options<ISelectOption>;
  isLoading?: boolean;
  isMulti?: boolean;
  showError?: boolean;
}

export const HookFormReactSelector = <T extends Record<string, any>>(
  props: IHookFormReactSelectorProps<T>,
) => {
  const {
    showError = true,
    control,
    rules,
    options,
    isMulti,
    isLoading,
    name,
    value,
    defaultValue,
    ...rest
  } = props;
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={'relative flex flex-col'}>
          <div className={cn('border rounded', error && 'border-red-500')}>
            {rules?.required && (
              <div
                className={
                  'absolute -top-2 -right-3 text-red-500 text-xl font-bold'
                }
              >
                *
              </div>
            )}
            <ReactSelector
              onChange={onChange}
              value={value}
              options={options}
              isLoading={isLoading}
              isMulti={isMulti}
              {...rest}
            />
          </div>
          {error && showError ? (
            <div className={'absolute top-11 left-2 text-sm text-red-500'}>
              {error.message}
            </div>
          ) : null}
        </div>
      )}
    />
  );
};
