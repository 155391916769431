import { memo } from 'react';
import Screenshot_1 from 'assets/images/Screenshot 1.png';
import Screenshot_2 from 'assets/images/Screenshot 2.png';

export const Description = memo(() => {
  return (
    <ol className={'mt-6 space-y-6'}>
      <li className='font-semibold text-gray-500'>
        - Установите расширение HHmailer authorizaion из
        <a
          className={'text-indigo-600 ml-1 hover:underline'}
          target={'_blank'}
          href={
            'https://chrome.google.com/webstore/detail/hhmailer-authorizaion/nomccogeegidhfhjljnkdbdfmgeegfji?hl=ru'
          }
          rel='noreferrer'
        >
          Интернет-магазин chrome
        </a>
        <p className={'ml-2 font-semibold text-sm text-gray-400'}>
          Это расширение копирует только куки с сайта hh.ru и не собирает другие
          данные.
        </p>
        <p className={'ml-2 font-semibold text-sm text-gray-400'}>
          В данный момент, работать с нашим сервисом возможно исключительно из
          Google Chrome и Яндекс Браузер.
        </p>
      </li>
      <li className='font-semibold text-gray-500'>
        - Откройте сайт
        <a
          target={'_blank'}
          className={'text-indigo-600 ml-1 hover:underline'}
          href={'https://hh.ru'}
          rel='noreferrer'
        >
          hh.ru
        </a>{' '}
        и войдите в свой профиль.
      </li>
      <li>
        <p className='font-semibold text-gray-500'>
          - Нажмите на иконку расширения и нажмите «Скопировать куки».
          Расширение скопирует ваши куки для hh.ru в буфер обмена.
        </p>
        <div className={'mt-3 flex gap-3'}>
          <img
            className={'rounded-[8px]'}
            src={Screenshot_1}
            alt='Screenshot_1'
          />
          <img
            className={'rounded-[8px]'}
            src={Screenshot_2}
            alt='Screenshot_2'
          />
        </div>
      </li>
      <li className='font-semibold text-gray-500'>
        - Вставьте скопированные куки в поле ниже и нажмите кнопку:
      </li>
    </ol>
  );
});
