import styles from './ResponseCounter.module.scss';

interface Props {
  totalCount?: number;
  count?: number;
}

export const ResponseCounter = ({ totalCount = 0, count = 0 }: Props) => {
  return (
    <div className={styles.responseCounter}>
      {count}/{totalCount}
    </div>
  );
};
