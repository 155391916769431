// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateMailing_container__ladzU {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
}

.UpdateMailing_buttons__7V81q {
  margin-top: 1rem;
  display: flex;
  gap: 0.75rem;
}
.UpdateMailing_buttons__7V81q > button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/UpdateMailing/UpdateMailing.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;;AAGA;EACE,gBAAA;EACA,aAAA;EACA,YAAA;AAAF;AACE;EACE,WAAA;AACJ","sourcesContent":["@import 'src/assets/styles/utils';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: rem(36);\n}\n.buttons {\n  margin-top: rem(52-36);\n  display: flex;\n  gap: rem(12);\n  & > button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UpdateMailing_container__ladzU`,
	"buttons": `UpdateMailing_buttons__7V81q`
};
export default ___CSS_LOADER_EXPORT___;
