import { useActivationQuery } from '../../../services/RTKService/auth/endpoints/authApi';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Loader } from '../../../Components/UI-kit/Loader/Loader';
import styles from './EmailConfirmationPage.module.scss';
import { memo } from 'react';
export const EmailConfirmationPage = memo(() => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { data, isLoading, isError } = useActivationQuery(
    { token, customOptions: { notToken: true, showErrorToast: false } },
    { skip: !token },
  );

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Loader />;
      </div>
    );
  }

  if (!token || isError) {
    return (
      <div className={styles.container}>
        <h1>Что то пошло не так, обратитесь в службу поддержки.</h1>
      </div>
    );
  }
  if (data === 'User was activated') {
    return <Navigate to={'/signIn'} replace />;
  }
  return (
    <div className={styles.container}>
      <h1>
        Фронтендер опять накосячил, пожалуйтесь на него в службу поддержки.
      </h1>
    </div>
  );
});
