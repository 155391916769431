import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { HhUser } from '../../../../Entities';

interface IHhUserDescriptionProps {
  hhUser: HhUser | null;
}

const valueStyle = 'text-gray-500';
const propertyStyle = 'text-indigo-600 font-semibold mt-3';

export const HhUserDescription: React.FC<IHhUserDescriptionProps> = memo(
  ({ hhUser }) => {
    const mappedResumes = useMemo(
      () =>
        hhUser?.resumes?.map((resume) => (
          <div key={resume.hash} className={'text-gray-500 font-semibold mb-3'}>
            - {resume.name}
          </div>
        )),
      [hhUser],
    );

    if (!hhUser) {
      return null;
    }

    return (
      <div>
        <p className={propertyStyle}>
          Статус синхронизации аккаунта:{' '}
          {hhUser.cookies_alive ? (
            <span className={valueStyle}>Синхронизирован</span>
          ) : (
            <span className={cn(valueStyle, 'text-red-500')}>
              {' '}
              Не синхронизирован
            </span>
          )}
        </p>
        {hhUser.phone ? (
          <div className={propertyStyle}>
            Мобильный номер:
            <span className={valueStyle}> {hhUser.phone}</span>
          </div>
        ) : null}
        {hhUser.email ? (
          <div className={propertyStyle}>
            Почта:
            <span className={valueStyle}> {hhUser.email}</span>
          </div>
        ) : null}
        {hhUser.applies_count ? (
          <div className={propertyStyle}>
            Количество откликов:
            <span className={valueStyle}> {hhUser.applies_count}</span>
          </div>
        ) : null}
        {mappedResumes && mappedResumes.length !== 0 ? (
          <div
            className={cn('flex flex-row items-start space-x-3', propertyStyle)}
          >
            <div>Резюме:</div>
            <div>{mappedResumes}</div>
          </div>
        ) : null}
      </div>
    );
  },
);
