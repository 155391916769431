// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteModal_content__FATh4 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 32rem;
}

.DeleteModal_buttons__0zq15 {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MaillingCard/DeleteButton/DeleteModal/DeleteModal.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AADF;;AAGA;EACE,aAAA;EACA,SAAA;EACA,yBAAA;AAAF","sourcesContent":["@import 'assets/styles/utils.scss';\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  max-width: rem(512);\n}\n.buttons {\n  display: flex;\n  gap: 1rem;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `DeleteModal_content__FATh4`,
	"buttons": `DeleteModal_buttons__0zq15`
};
export default ___CSS_LOADER_EXPORT___;
