import { useEffect } from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { setStorageItem } from 'services/localStorageService/localStorageService';

export const usePersistOnUnmountAndUnload = <T extends FieldValues>({
  key,
  getValues,
}: {
  key: string;
  getValues: UseFormGetValues<T>;
}) => {
  useEffect(() => {
    const callback = () => {
      setStorageItem(key, getValues());
    };
    window.addEventListener('beforeunload', callback);
    return () => {
      setStorageItem(key, getValues());
      window.removeEventListener('beforeunload', callback);
    };
  }, []);
};
