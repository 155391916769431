import { useSelector } from 'react-redux';
import { _selectHhUser } from '../../../../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { Control } from 'react-hook-form';
import { SelectorWrapper } from './SelectorWrapper';
import { FC, memo } from 'react';
import { CreateMailingRequest } from '../../../../../Entities';

interface IHookFormResumeSelectorProps {
  control: Control<CreateMailingRequest>;
}

export const HookFormResumeSelector: FC<IHookFormResumeSelectorProps> = memo(
  ({ control }) => {
    const hhUser = useSelector(_selectHhUser);
    const options = hhUser
      ? hhUser.resumes?.map((resume) => ({
          label: resume.name,
          value: resume.hash,
        }))
      : [];
    return (
      <SelectorWrapper options={options} label={'Резюме для откликов'}>
        <HookFormReactSelector<CreateMailingRequest>
          name={'resume_hash'}
          isLoading={false}
          control={control}
          isMulti={false}
          options={options}
          rules={{
            required: 'Обязательное поле',
          }}
          placeholder={'Название резюме'}
        />
      </SelectorWrapper>
    );
  },
);
