/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `relevance` - по соответствию
 * * `publication_time` - по дате изменения
 * * `salary_desc` - по убыванию зарплаты
 * * `salary_asc` - по возрастанию зарплаты
 */
export enum OrderEnum {
    RELEVANCE = 'relevance',
    PUBLICATION_TIME = 'publication_time',
    SALARY_DESC = 'salary_desc',
    SALARY_ASC = 'salary_asc',
}
