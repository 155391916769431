// Generated by ts-to-zod
import { z } from 'zod';

import { EmploymentTypeEnumSchema } from './EmploymentTypeEnumSchema';
import { ExperienceEnumSchema } from './ExperienceEnumSchema';
import { OrderEnumSchema } from './OrderEnumSchema';
import { RegionEnumSchema } from './RegionEnumSchema';
import { ScheduleEnumSchema } from './ScheduleEnumSchema';
import { SearchPeriodEnumSchema } from './SearchPeriodEnumSchema';

export const CreateMailingRequestSchema = z
  .object({
    areas: z.array(RegionEnumSchema.optional()),
    resume_hash: z.string({ message: 'Поле обязательно' }),
    letter: z.string().optional().nullable(),
    order: OrderEnumSchema.optional(),
    search_period: SearchPeriodEnumSchema.optional(),
    search_key: z
      .string({ message: 'Поле обязательно' })
      .trim()
      .min(1, 'Не менее 1 символа'),
    employment_type: z.array(EmploymentTypeEnumSchema).optional(),
    hh_user: z.number({ message: 'Поле обязательно' }),
    plan: z
      .number({ message: 'Только цифры' })
      .min(1, 'Не менее 1')
      .max(2800, 'Не более 2800')
      .optional(),
    daily_plan: z
      .number({ message: 'Только цифры' })
      .min(1, 'Не менее 1')
      .max(200, 'Не более 200')
      .optional(),
    sleep_time: z.number().optional().nullable(),
    restricted_employers: z.array(z.number()).optional(),
    experience: ExperienceEnumSchema.optional(),
    schedule: z.array(ScheduleEnumSchema).optional(),
  })
  .refine(
    (data) => {
      if (data.plan !== undefined && data.daily_plan !== undefined) {
        return data.plan >= data.daily_plan;
      }
      return true;
    },
    {
      message:
        'Суммарное количество откликов не должен быть меньше ежедневного количества откликов',
      path: ['plan'],
    },
  );
