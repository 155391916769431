import React from 'react';
import styles from './HHCheckButton.module.scss';
import { ReactComponent as HHIcon } from 'assets/svg/hhRedCircleIcon.svg';
import { useGetSearchLinkMutation } from 'services/RTKService/mailing/endpoints/mailingApi';
import { SearchLinkRequest } from 'Entities';
import { useFormContext } from 'react-hook-form';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const HHCheckButton = ({ ...otherProps }: Props) => {
  const { handleSubmit } = useFormContext<SearchLinkRequest>();
  const [getSearchLink] = useGetSearchLinkMutation();

  const onsubmit = handleSubmit(async (data) => {
    const res = await getSearchLink({
      data,
    });
    if ('data' in res) {
      window.open(res.data, '_blank');
    }
  });

  return (
    <button
      className={styles['hh-check-button']}
      onClick={onsubmit}
      {...otherProps}
    >
      <a>
        <HHIcon width='56' height='56' />
        <p>Проверить правильность поискового запроса на hh.ru </p>
      </a>
    </button>
  );
};
