import { MenuBar } from 'Components/UI-kit/MenuBar/MenuBar';
import { Outlet } from 'react-router-dom';
import styles from './MenuLayout.module.scss';
import { menuBarNavigationItems } from '../../../config/navigation.data';
import { Heading } from '../../../Components/UI-kit/Heading/Heading';

const menuItems = menuBarNavigationItems;

export const MenuLayout = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <MenuBar menuItems={menuItems} />
        <div className={styles.content}>
          <Heading className={styles.heading} />
          <div className={styles.contentContainer}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
