import React, { memo } from 'react';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import {
  CreateMailingRequest,
  SearchPeriodEnum,
} from '../../../../../Entities';

const searchPeriodOptions: { value: SearchPeriodEnum; label: string }[] = [
  { value: SearchPeriodEnum._0, label: 'За всё время' },
  { value: SearchPeriodEnum._30, label: 'За месяц' },
  { value: SearchPeriodEnum._7, label: 'За неделю' },
  { value: SearchPeriodEnum._3, label: 'За последние три дня' },
  { value: SearchPeriodEnum._1, label: 'За сутки' },
];

interface IHookFormSearchPeriodSelectorProps {
  control: Control<CreateMailingRequest>;
}

export const HookFormSearchPeriodSelector: React.FC<IHookFormSearchPeriodSelectorProps> =
  memo(({ control }) => {
    return (
      <SelectorWrapper
        label={'Откликаться на вакансии опубликованные за'}
        options={searchPeriodOptions}
      >
        <HookFormReactSelector<CreateMailingRequest>
          placeholder={'Выберете период'}
          defaultValue={searchPeriodOptions[0].value}
          control={control}
          name={'search_period'}
          isLoading={false}
          isMulti={false}
          options={searchPeriodOptions}
          className={'w-full'}
        />
      </SelectorWrapper>
    );
  });
