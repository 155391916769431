// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__1z3M\\+ {
  padding: 0.375rem;
  display: flex;
  justify-content: space-between;
  border-radius: 1.125rem 1.125rem 0 0;
  box-shadow: var(--shadows-frame-indented);
  background: var(--frame-fill-indented);
  transition: all 0.3s;
}
.Header_header__1z3M\\+ > svg {
  max-width: 6.25rem;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0.9375rem 1rem;
}
.Header_header__1z3M\\+ button {
  padding: 0.875rem;
  width: -moz-fit-content;
  width: fit-content;
}

.Header_verticalCollapse__wtQcw {
  border-radius: 1.125rem;
  transition-delay: 0.3s;
}

.Header_horizonCollapse__GrrJC {
  border-radius: 1.125rem 1.125rem 0 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MenuBar/Header/Header.module.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,oCAAA;EACA,yCAAA;EACA,sCAAA;EACA,oBAAA;AAFF;AAIE;EACE,kBAAA;EACA,wBAAA;EAAA,mBAAA;EAEA,sBAAA;AAHJ;AAME;EACE,iBAAA;EACA,uBAAA;EAAA,kBAAA;AAJJ;;AAOA;EACE,uBAAA;EACA,sBAAA;AAJF;;AAOA;EACE,oCAAA;AAJF","sourcesContent":["@use 'src/assets/styles/screens.scss' as screen;\n@import 'src/assets/styles/utils';\n\n.header {\n  padding: rem(6);\n  display: flex;\n  justify-content: space-between;\n  border-radius: rem(18) rem(18) 0 0;\n  box-shadow: var(--shadows-frame-indented);\n  background: var(--frame-fill-indented);\n  transition: all 0.3s;\n\n  & > svg {\n    max-width: rem(100);\n    height: fit-content;\n\n    margin: rem(15) rem(16);\n  }\n\n  button {\n    padding: rem(14);\n    width: fit-content;\n  }\n}\n.verticalCollapse {\n  border-radius: rem(18);\n  transition-delay: 0.3s;\n}\n\n.horizonCollapse {\n  border-radius: rem(18) rem(18) 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__1z3M+`,
	"verticalCollapse": `Header_verticalCollapse__wtQcw`,
	"horizonCollapse": `Header_horizonCollapse__GrrJC`
};
export default ___CSS_LOADER_EXPORT___;
