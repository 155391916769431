import React from 'react';
import styles from './Collapse.module.scss';

export interface Props extends React.DetailsHTMLAttributes<HTMLDetailsElement> {
  summary: string;
}

export const Collapse = ({
  summary,
  children,
  className,
  ...otherProps
}: Props) => {
  return (
    <details className={[styles.collapse, className].join(' ')} {...otherProps}>
      <summary>{summary}</summary>
      {children}
    </details>
  );
};
