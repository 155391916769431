import { Button } from 'Components/UI-kit/Buttons/Button';
import { SelectOption, SelectorValue } from '../types';
import { ReactComponent as CloseIcon } from 'assets/svg/closeCross.svg';
import styles from './MultipleSelectOptions.module.scss';

export const MultipleSelectOptions = ({
  selectedOptions,
  value,
  changeValue,
}: {
  selectedOptions: SelectOption[];
  value: SelectorValue;
  changeValue: (...args: any[]) => void;
}) => {
  const onHandleUnSelect =
    (selectedValue: SelectOption['value']) => (event: React.MouseEvent) => {
      if (!value) return;
      if (!Array.isArray(value)) return;
      event?.stopPropagation();
      changeValue(
        (value as SelectorValue[]).filter((val) => val !== selectedValue),
      );
    };

  return (
    <div className={styles.container}>
      {selectedOptions.map(({ label, value: selectedValue, color }) => (
        <p
          key={selectedValue}
          className={[styles.selectedOption, styles[color || '']].join(' ')}
        >
          {label}
          <Button
            onClick={onHandleUnSelect(selectedValue)}
            className={styles.closeButton}
            Icon={CloseIcon}
          />
        </p>
      ))}
    </div>
  );
};
