import styles from './Header.module.scss';
import { Logo } from '../../Logo/Logo';
import { Button, ButtonType } from '../../Buttons/Button';
import { ReactComponent as CloseCrossIcon } from '../../../../assets/svg/closeCross.svg';
import { ReactComponent as MenuBurgerIcon } from '../../../../assets/svg/menuBurger.svg';
import { MenuStateEnum } from '../types';
import { FunctionComponent, ReactElement } from 'react';

const iconsState: Record<MenuStateEnum, FunctionComponent> = {
  verticalCollapse: MenuBurgerIcon,
  horizonCollapse: MenuBurgerIcon,
  expanded: CloseCrossIcon,
};

const logosState: Record<MenuStateEnum, ReactElement | null> = {
  verticalCollapse: <Logo key={'Logo'} />,
  horizonCollapse: null,
  expanded: <Logo key={'Logo'} />,
};

const buttonTitleState: Record<MenuStateEnum, string> = {
  verticalCollapse: 'Меню',
  horizonCollapse: '',
  expanded: '',
};

export const Header = ({
  variant,
  onHandleButton,
}: {
  variant: MenuStateEnum;
  onHandleButton: () => void;
}) => {
  return (
    <div className={[styles.header, styles[variant]].join(' ')}>
      {logosState[variant]}
      <Button
        key={'Button'}
        onClick={onHandleButton}
        Icon={iconsState[variant]}
        interfaceType={ButtonType.Small}
      >
        {buttonTitleState[variant]}
      </Button>
    </div>
  );
};
