import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import { Collapse } from 'Components/UI-kit/Collapse/Collapse';
import { HookFormTextInput } from 'Components/UI-kit/TextInput/HookFormTextInput';
import { useForm } from 'react-hook-form';
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from 'services/RTKService/auth/endpoints/authApi';
import {
  validConfirmPasswordRules,
  validEmailRules,
  validPasswordRules,
} from 'Shared/hookForm/validationRules';
import styles from './UserAccountPage.module.scss';
import { authThunk } from '../../../redux/slices/authSlice';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';

interface FormData {
  email?: string;
  // old_password: string;
  new_password?: string;
  confirm_new_password?: string;
}

export function UserAccountPage() {
  const dispatch = useTypedDispatch();
  const { data: userData } = useGetUserQuery();
  const { control, handleSubmit, watch } = useForm<FormData>({
    values: {
      email: userData?.email,
      new_password: undefined,
      confirm_new_password: undefined,
    },
  });
  const [update] = useUpdateUserMutation();
  const onSubmit = handleSubmit(async ({ email, new_password }) => {
    await update({
      data: { email, password: new_password },
      customOptions: {
        showSuccessToast: true,
        successMessage: 'Данные успешно изменены!',
      },
    });
  });

  const onHandleLogout = () => {
    dispatch(authThunk.logout());
  };

  return (
    <div className={styles.container}>
      <HookFormTextInput
        control={control}
        name='email'
        label='Email'
        type='email'
        rules={validEmailRules}
      />
      <Collapse className={styles.collapse} summary='Сменить пароль'>
        <div className={styles.collapseContent}>
          {/* todo: это поле актуально только в случае его проверки
           на сервере, на фронте его проверить не удасться, потому
            как он ни где не хранится ни в каком виде */}
          {/* <HookFormTextInput
            control={control}
            name='old_password'
            label='Старый пароль'
            type='password'
            rules={validPasswordRules}
          /> */}
          <HookFormTextInput
            control={control}
            name='new_password'
            label='Новый пароль'
            type='password'
            rules={validPasswordRules}
          />
          <HookFormTextInput
            control={control}
            name='confirm_new_password'
            label='Подтвердить новый пароль'
            type='password'
            rules={validConfirmPasswordRules<FormData>(watch, 'new_password')}
          />
        </div>
      </Collapse>
      <div className={styles.buttons}>
        <Button onClick={onSubmit} interfaceType={ButtonType.Primary}>
          Сохранить
        </Button>
        <Button onClick={onHandleLogout} interfaceType={ButtonType.Secondary}>
          Выйти из сервиса
        </Button>
      </div>
    </div>
  );
}
