import styles from './ProgressBar.module.scss';

export enum ProgressBarColor {
  Green = 'green',
  Gray = 'gray',
  Yellow = 'yellow',
}

interface Props {
  totalCount?: number;
  count?: number;
  color?: ProgressBarColor;
}

export const ProgressBar = ({
  totalCount = 100,
  count = 1,
  color = ProgressBarColor.Green,
}: Props) => {
  const percent =
    count > totalCount ? '100%' : `${(1 / (totalCount / count)) * 100}%`;

  return (
    <div className={styles.progressBar}>
      <div style={{ width: percent }} className={styles[color]} />
    </div>
  );
};
