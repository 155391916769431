import { SelectOption, SelectorValue } from '../types';

export const getSelectedOption = (
  value: SelectorValue,
  options: SelectOption[],
) => {
  if (!value) return;
  if (Array.isArray(value)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return options.filter((option) => value.includes(option.value));
  }
  return options.find((option) => option.value === value);
};
