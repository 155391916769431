// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSettingPage_container__\\+T4X8 {
  padding: 0.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/UserSettingPage/UserSettingPage.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF","sourcesContent":["@import 'src/assets/styles/utils';\n\n.container {\n  padding: rem(8) 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UserSettingPage_container__+T4X8`
};
export default ___CSS_LOADER_EXPORT___;
