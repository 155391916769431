import React from 'react';
import styles from './HHCheckButton.module.scss';
import { ReactComponent as HHIcon } from 'assets/svg/hhRedCircleIcon.svg';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  link?: string;
};

export const HHCheckButton = ({ link, ...otherProps }: Props) => {
  return (
    <button className={styles['hh-check-button']} {...otherProps}>
      <a target='_blank' href={link} rel='noreferrer'>
        <HHIcon width='56' height='56' />
        <p>Проверить правильность поискового запроса на hh.ru </p>
      </a>
    </button>
  );
};
