// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserAccountPage_container__6LvXa {
  padding: 2.5rem 0;
  max-width: 32rem;
}

.UserAccountPage_collapse__cUgvE {
  cursor: pointer;
  margin: 1.75rem 0 0;
}

.UserAccountPage_collapseContent__c--yw {
  margin: 1.25rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.UserAccountPage_buttons__4IiF4 {
  margin: 3.25rem 0 0;
  display: flex;
  gap: 0.75rem;
}
.UserAccountPage_buttons__4IiF4 button {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/UserAccountPage/UserAccountPage.module.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EAEA,gBAAA;AAFJ;;AAIA;EACI,eAAA;EACA,mBAAA;AADJ;;AAGA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AAAJ;;AAEA;EACI,mBAAA;EACA,aAAA;EACA,YAAA;AACJ;AAAI;EACG,SAAA;AAEP","sourcesContent":["@use 'src/assets/styles/texts.scss' as text;\n@import 'src/assets/styles/utils';\n.container{\n    padding: rem(40) 0 ;\n    // background: #ff0000;\n    max-width: rem(512);\n}\n.collapse{\n    cursor: pointer;\n    margin: rem(28) 0 0;\n}\n.collapseContent{\n    margin: rem(20) 0;\n    display: flex;\n    flex-direction: column;\n    gap: rem(28);\n}\n.buttons{\n    margin: rem(52) 0 0;\n    display: flex;\n    gap: rem(12);\n    button{\n       flex: 1 1 0;\n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UserAccountPage_container__6LvXa`,
	"collapse": `UserAccountPage_collapse__cUgvE`,
	"collapseContent": `UserAccountPage_collapseContent__c--yw`,
	"buttons": `UserAccountPage_buttons__4IiF4`
};
export default ___CSS_LOADER_EXPORT___;
