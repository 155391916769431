import { ReactElement, SVGProps } from 'react';
import { Flatten } from 'Shared/interfaces';

// type for props = css-class-name
export enum SelectorType {
  Basic = 'basic',
  Status = 'status',
  Account = 'account',
  Form = 'form',
}

export enum SelectOptionColor {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
}
export enum MenuPlacement {
  Top = 'top',
  Bottom = 'bottom',
}

export type SelectorValue = string | number | string[] | number[];

export interface SelectOption {
  label: string;
  value: string | number;
  color?: SelectOptionColor;
  renderIcon?: () => ReactElement<SVGProps<SVGSVGElement>>;
  isAllOption?: boolean;
}

export type OnHandleOptionType = ({
  selectValue,
  index,
}: {
  selectValue: Flatten<SelectorValue>;
  index: number;
}) => void;

export interface SelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  value: SelectorValue;
  changeValue: (...args: any[]) => void;
  type: SelectorType;
  options: SelectOption[];
  tag?: string;
  menuPlacement?: MenuPlacement;
  onCreateOption?: (
    inputValue: string,
  ) => Promise<void | 'clearInput'> | 'clearInput' | void;
  onDeleteOption?: (value: Flatten<SelectorValue>) => void;
  isAdaptiveWidth?: boolean;
  isSearchable?: boolean;
  error?: string;
}
