// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[role=combobox] {
  cursor: pointer;
  width: 100%;
  padding: 4px 16px 4px 4px;
  border-radius: 24px;
  background: var(--frame-fill-convex);
  box-shadow: var(--shadows-convex);
  transition: opacity, var(--transform-duration);
  transition-timing-function: ease-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div[role=combobox] svg {
  min-width: 20px;
  transition-duration: var(--transform-duration);
}
div[role=combobox] svg > * {
  fill: var(--fill-icon) !important;
}
div[role=combobox] svg.Combobox_rotated__Ma0py {
  transform: rotate(180deg);
}
div[role=combobox]:hover {
  background: var(--dropdown-fill-hovered);
  box-shadow: var(--shadows-convex);
}
div[role=combobox]:active, div[role=combobox]:focus {
  background: var(--frame-fill-indented);
  box-shadow: var(--shadows-indented);
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/Selector/Combobox/Combobox.module.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;EAEA,WAAA;EACA,yBAAA;EACA,mBAAA;EAEA,oCAAA;EACA,iCAAA;EAEA,8CAAA;EACA,oCAAA;EAEA,aAAA;EACA,8BAAA;EACA,mBAAA;AAJF;AAOE;EACE,eAAA;EACA,8CAAA;AALJ;AAOI;EACE,iCAAA;AALN;AAQI;EACE,yBAAA;AANN;AAWE;EACE,wCAAA;EACA,iCAAA;AATJ;AAYE;EAEE,sCAAA;EACA,mCAAA;AAXJ","sourcesContent":["div[role='combobox'] {\n  // defaults overwrite\n  cursor: pointer;\n  // don't change\n  width: 100%;\n  padding: 4px 16px 4px 4px;\n  border-radius: 24px;\n  // colors\n  background: var(--frame-fill-convex);\n  box-shadow: var(--shadows-convex);\n  // transitions\n  transition: opacity, var(--transform-duration);\n  transition-timing-function: ease-out;\n  // inner classes\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  // icon\n  svg {\n    min-width: 20px;\n    transition-duration: var(--transform-duration);\n\n    & > * {\n      fill: var(--fill-icon) !important;\n    }\n\n    &.rotated {\n      transform: rotate(180deg);\n    }\n  }\n\n  // states\n  &:hover {\n    background: var(--dropdown-fill-hovered);\n    box-shadow: var(--shadows-convex);\n  }\n\n  &:active,\n  &:focus {\n    background: var(--frame-fill-indented);\n    box-shadow: var(--shadows-indented);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotated": `Combobox_rotated__Ma0py`
};
export default ___CSS_LOADER_EXPORT___;
