import { NavigationItem } from '../../../../config/navigation.data';
import styles from './Navigation.module.scss';
import { NavLink } from 'react-router-dom';
import { MenuStateEnum } from '../types';

export const Navigation = ({
  menuItems,
  menuState,
}: {
  menuItems: NavigationItem[];
  menuState: MenuStateEnum;
}) => {
  return (
    <nav className={[styles.menu, styles[menuState]].join(' ')}>
      {menuItems.map(({ Icon, title, path, state }, index) => (
        <NavLink
          key={index}
          to={path}
          state={state}
          className={({ isActive }) => (isActive ? styles.isActive : '')}
        >
          <Icon />
          <span>{title}</span>
        </NavLink>
      ))}
    </nav>
  );
};
