import React, {
  DetailedHTMLProps,
  PropsWithChildren,
  ReactElement,
} from 'react';

import loader from '../../../../../assets/svg/loader.svg';
import { Options } from 'react-select';
import { ISelectOption } from '../../../../../Components/Selector/ReactSelector/ReactSelector';
import cn from 'classnames';
import {
  ToolTip,
  ToolTipProps,
} from '../../../../../Components/ToolTip/ToolTip';

interface ISelectorWrapperProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  options?: Options<ISelectOption>;
  label: string;
  toolTipContent?: string | ReactElement;
  toolTipProps?: Omit<ToolTipProps, 'toolTipContent'>;
}

export const SelectorWrapper: React.FC<
  PropsWithChildren<ISelectorWrapperProps>
> = ({
  options,
  children,
  label,
  className,
  toolTipContent,
  toolTipProps,
  ...rest
}) => {
  return (
    <div
      className={cn(
        'sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5',
        className,
      )}
      {...rest}
    >
      <div className={'flex items-center gap-3'}>
        <label className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
          {label}
        </label>
        {toolTipContent && (
          <ToolTip toolTipContent={toolTipContent} {...toolTipProps} />
        )}
      </div>
      <div>
        {options ? (
          options.length ? (
            <>{children}</>
          ) : (
            <img className='h-10' alt={'loader'} src={loader} />
          )
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
};
