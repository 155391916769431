import { AccountCard } from 'Components/UI-kit/AccountCard/AccountCard';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';

export const HHAccountsList = () => {
  const { ids } = useGetHhUser();
  return (
    <div className='gap-3 flex flex-wrap justify-center sm:justify-start'>
      {ids?.map((id) => (
        <AccountCard hh_id={id as number} key={id} />
      ))}
    </div>
  );
};
