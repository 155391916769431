import {
  useStopMailingMutation,
  useUpdateMailingMutation,
} from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { PatchedCreateMailingRequest, StatusEnum } from '../../../Entities';

export const useUpdateMailingTask = () => {
  const [stop, { isLoading: stopLoading }] = useStopMailingMutation();
  const [update, { isLoading: isUpdateLoading }] = useUpdateMailingMutation();

  const updateTask = async (
    id: number,
    mailingTask: PatchedCreateMailingRequest,
    status?: StatusEnum,
  ) => {
    if (status === StatusEnum.RUNNING || status === StatusEnum.PENDING) {
      await stop({
        id,
      });
    }
    await update({ id, patchedMailing: mailingTask });
  };

  return {
    updateTask,
    isLoading: isUpdateLoading || stopLoading,
  };
};
