import styles from './SignInPage.module.scss';
import { Logo } from '../../../Components/UI-kit/Logo/Logo';
import { SignInCard } from '../../../Components/UI-kit/SignInCard/SignInCard';
import { useNavigate } from 'react-router-dom';

export const SignInPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.centredContent}>
        <Logo />
        <SignInCard onClickSignUp={() => navigate('/signUp')} />
      </div>
    </div>
  );
};
