import React, { memo } from 'react';
import { SelectorWrapper } from './SelectorWrapper';
import { Control, Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import image2 from 'assets/images/screenShot1.png';
import image3 from 'assets/images/screenShot2.png';
import { ReactSelector } from '../../../../../Components';
import { TooltipVariants } from 'Components/ToolTip/ToolTip';
import { CreateMailingRequest } from '../../../../../Entities';
import { useEmployerOptions } from '../../../../../hooks/useEmployerOptions';

interface IHookFormEmploymentTypeSelectorProps {
  control: Control<CreateMailingRequest>;
}

export const HookFormRestrictedEmployersSelector: React.FC<IHookFormEmploymentTypeSelectorProps> =
  memo(({ control }) => {
    const { employerOptions, createOption, isLoading } = useEmployerOptions();

    return (
      <SelectorWrapper
        toolTipProps={{
          variants: TooltipVariants.topRight,
        }}
        toolTipContent={<ToolTipContent />}
        label={'Нежелательные компании'}
      >
        <Controller
          control={control}
          name={'restricted_employers'}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ReactSelector
                onChange={onChange}
                value={value}
                options={employerOptions}
                isMulti
                renderComponent={({ value: getValue, onChange, options }) => {
                  const handleCreate = async (inputValue: string) => {
                    const newOption = await createOption({ link: inputValue });
                    if (!newOption) return;
                    onChange([newOption, ...getValue(value)]);
                  };

                  return (
                    <CreatableSelect
                      isLoading={isLoading}
                      formatCreateLabel={(inputValue: string) => (
                        <span>Добавить: {inputValue}</span>
                      )}
                      onBlur={onBlur}
                      menuPlacement={'top'}
                      isMulti
                      isClearable
                      onChange={onChange}
                      onCreateOption={handleCreate}
                      options={options}
                      value={getValue(value)}
                      placeholder={'Вставьте ссылку сюда'}
                    />
                  );
                }}
              />
            );
          }}
        />
      </SelectorWrapper>
    );
  });

const ToolTipContent = () => {
  return (
    <div className='flex flex-col w-max gap-3  overflow-hidden z-auto'>
      <img
        className={'object-cover rounded-xl border-black border-2'}
        src={image2}
        alt='image2'
      />
      <img
        className={'object-cover rounded-xl border-black border-2'}
        src={image3}
        alt='image3'
      />
    </div>
  );
};
