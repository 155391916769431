import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuth } from '../redux/slices/authSlice';
import { useGetMailingListQuery } from '../services/RTKService/mailing/endpoints/mailingApi';
import { useGetHhUserListQuery } from '../services/RTKService/hhUser/endpoints/hhUserApi';

export const useInitializeAuthedApp = () => {
  const isAuthed = useSelector(selectIsAuth);

  useEffect(() => {
    if (!isAuthed) {
      return;
    }
  }, [isAuthed]);

  useGetHhUserListQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      data,
      isLoading,
    }),
    skip: !isAuthed,
  });

  useGetMailingListQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data,
    }),
    skip: !isAuthed,
  });
};
