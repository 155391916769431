// src/components/ui/Modal/Modal.tsx
import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ButtonType } from '../Buttons/Button';
import styles from './BaseModal.module.scss';
import cn from 'classnames';
import { Button } from '../Buttons/Button';
import { ReactComponent as CloseIcon } from 'assets/svg/closeCross.svg';

export interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  className?: string;
  modalId?: string;
}

export const BaseModal: FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  className,
  modalId,
}) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <div className={styles.modal}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={cn(styles.content, className)}>
        <div className={styles.header}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <Button
            className={styles.closeButton}
            interfaceType={ButtonType.SmallGhost}
            Icon={CloseIcon}
            onClick={onClose}
          />
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>,
    document.getElementById(modalId || 'modal-root') || document.body,
  );
};
