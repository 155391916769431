// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolTip_tooltip__MNuly {
  position: relative;
  min-width: 16px;
  cursor: pointer;
}

/* Tooltip text */
.ToolTip_tooltip__MNuly .ToolTip_tooltiptext__eW5OT {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.ToolTip_tooltip__MNuly:hover .ToolTip_tooltiptext__eW5OT {
  visibility: visible;
}

.ToolTip_tooltip__MNuly:hover .ToolTip_tooltipContent__M1QI1 {
  visibility: visible;
}

.ToolTip_tooltipContent__M1QI1 {
  visibility: hidden;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.ToolTip_top__Dq7sZ {
  bottom: 100%;
  transform: translateX(-50%);
}

.ToolTip_bottom__cnr2p {
  transform: translateX(-50%);
}

.ToolTip_left__vMJLE {
  transform: translateY(50%);
  right: 100%;
}

.ToolTip_right__SmIfJ {
  transform: translateY(-50%);
  left: 100%;
}

.ToolTip_top-left__RkwdM {
  bottom: 100%;
  right: 100%;
}

.ToolTip_top-right__oqq2c {
  bottom: 100%;
  left: 100%;
}

.ToolTip_bottom-left__\\+I1i0 {
  top: 100%;
  right: 100%;
}

.ToolTip_bottom-right__ggK9o {
  top: 100%;
  left: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/ToolTip/ToolTip.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA,iBAAA;AACA;EACE,kBAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EAEA,oDAAA;EACA,kBAAA;EACA,UAAA;AAAF;;AAGA,oEAAA;AACA;EACE,mBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EAEE,kBAAA;EACA,oDAAA;EACA,kBAAA;EACA,UAAA;AADF;;AAIA;EACE,YAAA;EACA,2BAAA;AADF;;AAIA;EACE,2BAAA;AADF;;AAIA;EACE,0BAAA;EACA,WAAA;AADF;;AAIA;EACE,2BAAA;EACA,UAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;AADF;;AAIA;EACE,YAAA;EACA,UAAA;AADF;;AAIA;EACE,SAAA;EACA,WAAA;AADF;;AAIA;EACE,SAAA;EACA,UAAA;AADF","sourcesContent":[".tooltip {\n  position: relative;\n  min-width: 16px;\n  cursor: pointer;\n}\n\n/* Tooltip text */\n.tooltip .tooltiptext {\n  visibility: hidden;\n  background-color: black;\n  color: #fff;\n  text-align: center;\n  padding: 5px 5px;\n  border-radius: 6px;\n\n  /* Position the tooltip text - see examples below! */\n  position: absolute;\n  z-index: 1;\n}\n\n/* Show the tooltip text when you mouse over the tooltip container */\n.tooltip:hover .tooltiptext {\n  visibility: visible;\n}\n\n.tooltip:hover .tooltipContent {\n  visibility: visible;\n}\n\n.tooltipContent {\n  // top: 0;\n  visibility: hidden;\n  /* Position the tooltip text - see examples below! */\n  position: absolute;\n  z-index: 1;\n}\n\n.top {\n  bottom: 100%;\n  transform: translateX(-50%)\n}\n\n.bottom {\n  transform: translateX(-50%)\n}\n\n.left {\n  transform: translateY(50%);\n  right: 100%;\n}\n\n.right {\n  transform: translateY(-50%);\n  left: 100%;\n}\n\n.top-left {\n  bottom: 100%;\n  right: 100%;\n}\n\n.top-right {\n  bottom: 100%;\n  left: 100%;\n}\n\n.bottom-left {\n  top: 100%;\n  right: 100%;\n}\n\n.bottom-right {\n  top: 100%;\n  left: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `ToolTip_tooltip__MNuly`,
	"tooltiptext": `ToolTip_tooltiptext__eW5OT`,
	"tooltipContent": `ToolTip_tooltipContent__M1QI1`,
	"top": `ToolTip_top__Dq7sZ`,
	"bottom": `ToolTip_bottom__cnr2p`,
	"left": `ToolTip_left__vMJLE`,
	"right": `ToolTip_right__SmIfJ`,
	"top-left": `ToolTip_top-left__RkwdM`,
	"top-right": `ToolTip_top-right__oqq2c`,
	"bottom-left": `ToolTip_bottom-left__+I1i0`,
	"bottom-right": `ToolTip_bottom-right__ggK9o`
};
export default ___CSS_LOADER_EXPORT___;
