// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[role=listbox] {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 12px;
  background: var(--frame-fill-convex);
  display: flex;
  box-shadow: var(--shadows-listbox);
  width: 100%;
  transition: height, var(--transform-duration);
  transition-timing-function: ease-out;
  margin-top: var(--select-gap);
  overflow: hidden;
  z-index: 30;
}

.ListBox_top__0XdQp {
  bottom: calc(100% + var(--select-gap));
  flex-direction: column;
}

.ListBox_bottom__WVzod {
  top: 100%;
  flex-direction: column-reverse;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/Selector/ListBox/ListBox.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,mBAAA;EACA,oCAAA;EACA,aAAA;EAEA,kCAAA;EACA,WAAA;EACA,6CAAA;EACA,oCAAA;EAEA,6BAAA;EACA,gBAAA;EACA,WAAA;AADF;;AAIA;EACE,sCAAA;EACA,sBAAA;AADF;;AAIA;EACE,SAAA;EACA,8BAAA;AADF","sourcesContent":["div[role='listbox'] {\n  position: absolute;\n  left: 0;\n  right: 0;\n  border-radius: 12px;\n  background: var(--frame-fill-convex);\n  display: flex;\n  // flex-direction: column-reverse;\n  box-shadow: var(--shadows-listbox);\n  width: 100%;\n  transition: height, var(--transform-duration);\n  transition-timing-function: ease-out;\n  // gap between combobox and listbox\n  margin-top: var(--select-gap);\n  overflow: hidden;\n  z-index: 30;\n}\n\n.top {\n  bottom: calc(100% + var(--select-gap));\n  flex-direction: column;\n}\n\n.bottom {\n  top: 100%;\n  flex-direction: column-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": `ListBox_top__0XdQp`,
	"bottom": `ListBox_bottom__WVzod`
};
export default ___CSS_LOADER_EXPORT___;
