import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
type ThemeContextType = {
  theme: 'dark' | 'light' | null;
  onDark: () => void;
  onLight: () => void;
};
const ThemeContext = createContext<ThemeContextType>({
  theme: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDark: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLight: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState<ThemeContextType['theme']>(null);

  useEffect(() => {
    const item = localStorage.getItem('theme');
    switch (item) {
      case 'dark':
        setTheme('dark');
        break;
      case 'light':
        setTheme('light');
        break;
    }
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    function handleThemeChange(mql: MediaQueryListEvent | MediaQueryList) {
      if (mql.matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    }
    if (!localStorage.getItem('theme')) {
      handleThemeChange(mediaQueryList);
    }
    mediaQueryList.addEventListener('change', handleThemeChange);
    return () => {
      mediaQueryList.removeEventListener('change', handleThemeChange);
    };
  }, []);

  useEffect(() => {
    if (!theme) return;
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const onDark = () => {
    setTheme('dark');
  };

  const onLight = () => {
    setTheme('light');
  };

  return (
    <ThemeContext.Provider value={{ theme, onDark, onLight }}>
      {children}
    </ThemeContext.Provider>
  );
};
