// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputWithButton_inputWithButton__ehaUr {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  max-width: 562px;
  gap: 12px;
}
.InputWithButton_inputWithButton__ehaUr button {
  flex: 1 1;
  text-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/HHAccountsPage/InputWithButton/InputWithButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,SAAA;AACF;AAAE;EACE,SAAA;EACA,iBAAA;AAEJ","sourcesContent":[".inputWithButton {\n  margin-top: 16px;\n  display: flex;\n  align-items: flex-start;\n  max-width: 562px;\n  gap: 12px;\n  button {\n    flex: 1 1 0;\n    text-wrap: nowrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWithButton": `InputWithButton_inputWithButton__ehaUr`
};
export default ___CSS_LOADER_EXPORT___;
