/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `doesNotMatter` - Не имеет значения
 * * `noExperience` - Нет опыта
 * * `between1And3` - От 1 до 3х лет
 * * `between3And6` - От 3х до 6-ти лет
 * * `moreThan6` - Более 6-ти лет
 */
export enum ExperienceEnum {
    DOES_NOT_MATTER = 'doesNotMatter',
    NO_EXPERIENCE = 'noExperience',
    BETWEEN1AND3 = 'between1And3',
    BETWEEN3AND6 = 'between3And6',
    MORE_THAN6 = 'moreThan6',
}
