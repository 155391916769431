import styles from './SignUpPage.module.scss';
import { Logo } from '../../../Components/UI-kit/Logo/Logo';
import { SignUpCard } from 'Components/UI-kit/SignUpCard/SignUpCard';
import { useNavigate } from 'react-router-dom';

export const SignUpPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.centredContent}>
        <Logo />
        <SignUpCard onClickSignIn={() => navigate('/signIn')} />
      </div>
    </div>
  );
};
