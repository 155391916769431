import React, { memo, ReactElement } from 'react';
import Select, { OnChangeValue, Options } from 'react-select';
import makeAnimated from 'react-select/animated';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

const animatedComponents = makeAnimated();

export interface ISelectOption {
  value: string | number;
  label: string;
}

export interface ISelectProps extends StateManagerProps {
  options?: Options<ISelectOption>;
  value?: string | number | string[] | number[];
  onChange: (...event: any[]) => void;
  renderComponent?: (props: {
    value: (
      value?: string | number | string[] | number[],
    ) => Options<ISelectOption>;
    onChange: (newValue: OnChangeValue<ISelectOption, boolean>) => void;
    options: Options<ISelectOption>;
  }) => ReactElement;
}

export const ReactSelector: React.FC<ISelectProps> = memo(
  ({
    options = [],
    value = '',
    onChange,
    isMulti,
    renderComponent,
    ...rest
  }: ISelectProps) => {
    const onChangeHandler = (
      newValue: OnChangeValue<ISelectOption, boolean>,
    ) => {
      onChange(
        isMulti
          ? (newValue as ISelectOption[]).map(
              (item: ISelectOption) => item.value,
            )
          : (newValue as ISelectOption).value,
      );
    };

    const getValue = (value?: string | number | string[] | number[]) => {
      if (value) {
        if (isMulti) {
          return options.filter(
            (option) =>
              (value as unknown as Array<string | number>).indexOf(
                option.value,
              ) >= 0,
          );
        } else {
          return options.find((option) => option.value === value);
        }
      } else {
        return isMulti ? [] : ('' as any);
      }
    };

    if (renderComponent) {
      return renderComponent({
        value: getValue,
        onChange: onChangeHandler,
        options,
      });
    }

    return (
      <Select
        classNamePrefix='custom-select'
        value={getValue(value)}
        options={options}
        onChange={onChangeHandler}
        isMulti={isMulti}
        components={animatedComponents}
        {...rest}
      />
    );
  },
);
