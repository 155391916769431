import React, { memo } from 'react';
import { Control } from 'react-hook-form';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { CreateMailingRequest, OrderEnum } from '../../../../../Entities';

const orderOptions: { value: OrderEnum; label: string }[] = [
  { value: OrderEnum.RELEVANCE, label: 'По соответствию' },
  { value: OrderEnum.PUBLICATION_TIME, label: 'По дате изменени' },
  { value: OrderEnum.SALARY_DESC, label: 'По убыванию зарплат' },
  { value: OrderEnum.SALARY_ASC, label: 'По возрастанию зарплаты' },
];

interface IHookFormOrderSelectorProps {
  control: Control<CreateMailingRequest>;
}

export const HookFormOrderSelector: React.FC<IHookFormOrderSelectorProps> =
  memo(({ control }) => {
    return (
      <SelectorWrapper label={'Сортировать вакансии по'} options={orderOptions}>
        <HookFormReactSelector<CreateMailingRequest>
          placeholder={'Выберете тип'}
          defaultValue={orderOptions[0].value}
          control={control}
          name={'order'}
          isLoading={false}
          isMulti={false}
          options={orderOptions}
          className={'w-full'}
        />
      </SelectorWrapper>
    );
  });
