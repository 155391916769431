import { SelectorType } from 'Components/UI-kit/Selector/types';

import { WithHookFormControlSelector } from 'Components/UI-kit/Selector/WithHookFormControlSelector';
import { CreateMailingRequest, PatchedUpdateMailingRequest } from 'Entities';
import { useGetHhUser } from 'hooks/useGetHhUser';
import { useFormContext } from 'react-hook-form';

export const HhUserContainer = () => {
  const { control } = useFormContext<
    CreateMailingRequest | PatchedUpdateMailingRequest
  >();

  const { hhUserOptions } = useGetHhUser();
  return (
    <WithHookFormControlSelector
      name={'hh_user'}
      control={control}
      placeholder={'HH аккаунт'}
      options={hhUserOptions}
      type={SelectorType.Form}
    />
  );
};
