// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Content_content__LP\\+zG {
  border-radius: 0 0 1.125rem 1.125rem;
  padding: 0.375rem;
  box-shadow: var(--shadows-frame-indented);
  background: var(--frame-fill-indented);
  row-gap: 0.25rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  transition-delay: 0.1s;
  overflow: auto;
  max-height: 100vh;
  z-index: 40;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
}
@media (min-width: 640px) {
  .Content_content__LP\\+zG {
    max-height: 100%;
    bottom: 0;
  }
}

.Content_verticalCollapse__v3Pyv {
  max-height: 0;
  padding: 0 0.375rem;
  transition-delay: 0s;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MenuBar/Content/Content.module.scss","webpack://./src/assets/styles/screens.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,iBAAA;EACA,yCAAA;EACA,sCAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,sBAAA;EACA,cAAA;EACA,iBAAA;EACA,WAAA;EAEA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAFF;ACEE;EDjBF;IAmBI,gBAAA;IACA,SAAA;EAAF;AACF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;AADF","sourcesContent":["@use 'src/assets/styles/screens.scss' as screen;\n@import 'src/assets/styles/utils';\n.content {\n  border-radius: 0 0 rem(18) rem(18);\n  padding: rem(6);\n  box-shadow: var(--shadows-frame-indented);\n  background: var(--frame-fill-indented);\n  row-gap: rem(4);\n  display: flex;\n  flex-direction: column;\n  transition: all 0.3s;\n  transition-delay: 0.1s;\n  overflow: auto;\n  max-height: 100vh;\n  z-index: 40;\n\n  position: absolute;\n  top: 60px;\n  left: 0;\n  right: 0;\n  @include screen.sm {\n    max-height: 100%;\n    bottom: 0;\n  }\n}\n.horizonCollapse {\n}\n.verticalCollapse {\n  max-height: 0;\n  padding: 0 rem(6);\n  transition-delay: 0s;\n}\n","$xxs: 320px;\n$xs: 560px;\n$sm: 640px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@mixin xxs {\n  @media (min-width: $xxs) {\n    @content;\n  }\n}\n@mixin xs {\n  @media (min-width: $xs) {\n    @content;\n  }\n}\n@mixin sm {\n  @media (min-width: $sm) {\n    @content;\n  }\n}\n@mixin md {\n  @media (min-width: $md) {\n    @content;\n  }\n}\n@mixin lg {\n  @media (min-width: $lg) {\n    @content;\n  }\n}\n@mixin xl {\n  @media (min-width: $xl) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media (min-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Content_content__LP+zG`,
	"verticalCollapse": `Content_verticalCollapse__v3Pyv`
};
export default ___CSS_LOADER_EXPORT___;
