import { useGetMailingListQuery } from '../services/RTKService/mailing/endpoints/mailingApi';
import { useEffect, useState } from 'react';

import { StatusExtendsAll } from '../Pages/newDesign/MailingPage/MailingList/FilterBar/FilterBar';
import { Dictionary } from '@reduxjs/toolkit';
import { RetrieveMailing } from 'Entities';
// Сортировка по дате создания
const sortByDate = (
  ids: number[],
  entities: Dictionary<RetrieveMailing>,
  sort: 'asc' | 'desc',
) => {
  if (!ids.length) return [];
  if (!entities) return [];
  const sortedIds = [...ids].sort((a, b) => {
    const dateA = new Date(entities[a]?.start_time ?? '').getTime();
    const dateB = new Date(entities[b]?.start_time ?? '').getTime();
    return sort === 'asc' ? dateA - dateB : dateB - dateA;
  });
  return sortedIds as number[];
};

export const useGetMailingList = () => {
  // Фильтрация по статусу
  const [mailingstatus, setMailingstatus] = useState<
    keyof typeof StatusExtendsAll
  >(StatusExtendsAll.ALL);
  // Сортировка по дате создания
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  // Фильтрация по hhAccountId
  const [hhAccountId, setHhAccountId] = useState<number>(-1);
  // Фильтрация и сортировка
  const [filteredIds, setFilteredIds] = useState<number[]>([]);
  // Флаг для polling
  const [isPolling, setIsPolling] = useState(false);

  const returnValue = useGetMailingListQuery(undefined, {
    pollingInterval: isPolling ? 1000 : undefined,
  });

  const entities = returnValue?.data?.entities;
  const ids = returnValue?.data?.ids;
  // Проверка на наличие RUNNING и PENDING  рассылок, если есть, то включаем polling
  useEffect(() => {
    const someRunningIds = filteredIds?.filter((id) => {
      const status = entities?.[id]?.status;
      switch (status) {
        case StatusExtendsAll.PENDING:
        case StatusExtendsAll.RUNNING:
          return true;
        default:
          return false;
      }
    });

    if (!someRunningIds.length) return;
    setIsPolling(true);

    return () => {
      setIsPolling(false);
    };
  }, [filteredIds, entities]);

  // Фильтрация и сортировка
  useEffect(() => {
    if (!ids) return;
    if (!entities) return;
    // Фильтрация по статусу
    const newFilteredIdsbyStatus = ids.filter((id) =>
      mailingstatus === StatusExtendsAll.ALL
        ? true
        : entities[id]?.status === mailingstatus,
    );
    // Фильтрация по hhAccountId
    const newFilteredIdsByHhAccountId = newFilteredIdsbyStatus.filter((id) =>
      hhAccountId !== -1 ? entities[id]?.hh_user === hhAccountId : true,
    );
    // Сортировка по дате
    const sortedIds = sortByDate(
      newFilteredIdsByHhAccountId as number[],
      entities,
      sort,
    );

    setFilteredIds(sortedIds as number[]);
  }, [ids, mailingstatus, sort, hhAccountId, entities]);

  return {
    filteredIds,
    setMailingstatus,
    mailingstatus,
    setSort,
    sort,
    setHhAccountId,
    hhAccountId,
    ...returnValue,
  };
};
