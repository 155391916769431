import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ButtonWithLoader } from '../../../../Components';
import { hhUserAsyncActions } from '../../../../redux/slices/hhUserSlice/hhUserAsyncActions';
import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useSelector } from 'react-redux';
import { StyledInput } from './StyledInput';
import { Description } from './Description';
import { selectIsLoading } from '../../../../redux/slices/appSlice/appSlice';
import { HhUserDescription } from './HhUserDescription';
import loader from '../../../../assets/svg/loader.svg';
import { _selectHhUser } from '../../../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { isFulfilled } from '@reduxjs/toolkit';

type State = 'noHHUser' | 'noCookie' | 'loading' | 'accept';
export const LinkHHAccountByCookie: React.FC = memo(() => {
  const [state, setState] = useState<State>('loading');
  const [cookies, setCookies] = useState<string>('');
  const hhUser = useSelector(_selectHhUser);
  const hhUserStatus = hhUser?.cookies_alive;
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (isLoading) {
      setState('loading');
      return;
    } else if (!hhUser) {
      setState('noHHUser');
      return;
    } else if (!hhUserStatus) {
      setState('noCookie');
      return;
    } else {
      setState('accept');
    }
  }, [hhUser, hhUserStatus, isLoading]);

  const onChangeCookie = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCookies(e.currentTarget.value);
  }, []);

  const onLinkHHUser = () => {
    if (!hhUser || !cookies) return;
    dispatch(
      hhUserAsyncActions.linkHhUserByCookies({
        cookies: JSON.parse(cookies),
        hhUserId: hhUser.id.toString(),
      }),
    );
  };

  const onDeleteHHUser = () => {
    if (!hhUser) return;
    dispatch(hhUserAsyncActions.deleteHhUser(hhUser.id));
  };

  const onCreateHhUser = async () => {
    if (!cookies) return;
    const response = await dispatch(
      hhUserAsyncActions.createHhUser({ cookies: JSON.parse(cookies) }),
    );
    if (isFulfilled(response)) {
      setCookies('');
    }
  };

  const render = {
    loading: (
      <div className='mt-3'>
        <img className='h-10' alt={'loader'} src={loader} />
      </div>
    ),
    noHHUser: (
      <div className='mt-3'>
        <Description />
        <StyledInput
          type='text'
          name='cookie'
          id='cookie'
          onChange={onChangeCookie}
          placeholder='Cookie'
          value={cookies}
          autoComplete='new-password'
        />
        <ButtonWithLoader
          onClick={onCreateHhUser}
          className='mt-6 flex-nowrap px-5 py-2.5 mb-2 w-52  rounded-lg'
        >
          Привязать аккаунт
        </ButtonWithLoader>
      </div>
    ),
    noCookie: (
      <div className='mt-3'>
        <HhUserDescription hhUser={hhUser} />
        <StyledInput
          type='text'
          name='cookie'
          id='cookie'
          onChange={onChangeCookie}
          placeholder='Cookie'
          value={cookies}
          autoComplete='new-password'
        />
        <div className={'flex row space-x-5'}>
          <ButtonWithLoader
            onClick={onLinkHHUser}
            className='mt-6 flex-nowrap px-5 py-2.5 mb-2 w-52  rounded-lg'
          >
            Обновить Cookie
          </ButtonWithLoader>
          <ButtonWithLoader
            onClick={onDeleteHHUser}
            className='mt-6 flex-nowrap px-5 py-2.5 mb-2 w-52  rounded-lg'
          >
            Отвязать аккаунт
          </ButtonWithLoader>
        </div>
      </div>
    ),
    accept: (
      <div className='mt-3'>
        <HhUserDescription hhUser={hhUser} />
        <ButtonWithLoader
          onClick={onDeleteHHUser}
          className='mt-6 flex-nowrap px-5 py-2.5 mb-2 w-52  rounded-lg'
        >
          Отвязать аккаунт
        </ButtonWithLoader>
      </div>
    ),
  };

  return render[state];
});
