import styles from './FilterBar.module.scss';
import { Dispatch, SetStateAction } from 'react';
import { StatusEnum } from '../../../../../Entities';
import { TimeSorter } from 'Components/UI-kit/TimeSorter/TimeSorter';
import { useGetHhUser } from '../../../../../hooks/useGetHhUser';
import { Selector, SelectorType } from 'Components/UI-kit/Selector';

/**
 * @description Список опций для выбора статуса рассылки
 *  * * `PLAN_FULFILLED` - Все отклики разосланы
 * * `DAILY_PLAN_FULFILLED` - Дневной план на рассылку выполнен
 * * `OUT_OF_VACANCIES` - Закончились вакансии по данному запросу
 * * `HH_LIMIT_REACHED` - Достигнут лимит запросов, установленный hh.ru
 * * `RUNNING` - Запущена
 * * `CREATED` - Создана
 * * `PENDING` - Ожидает очереди
 * * `STOPPED` - Остановлена
 * * `ERROR` - Ошибка
 * * `ALL` - Все рассылки
 */

export const StatusExtendsAll = {
  ...StatusEnum,
  ALL: 'ALL',
} as const;

const statusOptions: { value: keyof typeof StatusExtendsAll; label: string }[] =
  [
    {
      value: StatusExtendsAll.ALL,
      label: 'Все статусы',
    },
    { value: StatusExtendsAll.PLAN_COMPLETED, label: 'Все отклики разосланы' },
    { value: StatusExtendsAll.RUNNING, label: 'Запущена' },
    { value: StatusExtendsAll.STOPPED, label: 'Остановлена' },
    { value: StatusExtendsAll.ERROR, label: 'Ошибка' },
    { value: StatusExtendsAll.CREATED, label: 'Создана' },
    { value: StatusExtendsAll.PENDING, label: 'Ожидает очереди' },
    {
      value: StatusExtendsAll.OUT_OF_VACANCIES,
      label: 'Закончились вакансии',
    },
    {
      value: StatusExtendsAll.HH_LIMIT_REACHED,
      label: 'Достигнут лимит запросов',
    },
    {
      value: StatusExtendsAll.DAILY_PLAN_COMPLETED,
      label: 'Дневной план выполнен',
    },
  ];

export const FilterBar = ({
  setMailingstatus,
  mailingstatus,
  hhAccountId,
  setHhAccountId,
  sort,
  setSort,
}: {
  setMailingstatus: Dispatch<SetStateAction<keyof typeof StatusExtendsAll>>;
  mailingstatus: keyof typeof StatusExtendsAll;
  hhAccountId: number;
  setHhAccountId: Dispatch<SetStateAction<number>>;
  sort: 'asc' | 'desc';
  setSort: Dispatch<SetStateAction<'asc' | 'desc'>>;
}) => {
  const { hhUserOptions } = useGetHhUser();

  const handleStatusChange = (value: keyof typeof StatusExtendsAll) => {
    setMailingstatus(value);
  };

  const handleHhAccountChange = (value: number) => {
    setHhAccountId(value);
  };

  const handleSortChange = () => {
    setSort(sort === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div className={styles.filterBar}>
      <Selector
        className={styles.statusSelector}
        options={statusOptions}
        value={mailingstatus}
        changeValue={handleStatusChange}
        type={SelectorType.Basic}
        isAdaptiveWidth
      />

      <TimeSorter sort={sort} onChange={handleSortChange} />

      {hhAccountId && (
        <Selector
          className={styles.hhAccountSelector}
          options={getHhUserOptions(hhUserOptions)}
          value={hhAccountId}
          changeValue={handleHhAccountChange}
          type={SelectorType.Account}
          tag='HH.ru'
          isAdaptiveWidth
        />
      )}
    </div>
  );
};

function getHhUserOptions(
  options: {
    value: number;
    label: string;
  }[] = [],
) {
  const defaultOption = { value: -1, label: 'Все аккаунты' };
  if (options.length) {
    return [defaultOption, ...options];
  }
  return [defaultOption];
}
