import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import styles from './Logo.module.scss';

export const Logo = ({
  className,

  ...otherProps
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <LogoIcon className={[styles.logo, className].join(' ')} {...otherProps} />
  );
};
