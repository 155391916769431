import { HHCheckButton } from '../../../../Components/UI-kit/HHCheckButton/HHCheckButton';
import styles from './EditMailingStep2.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../../config/navigation.data';

export const EditMailingStep2 = () => {
  const navigate = useNavigate();

  const onHandleBack = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep1,
    });
  };

  const onHandleNext = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep3,
    });
  };

  return (
    <div className={styles.container}>
      <HHCheckButton />
      <div className={styles.buttons}>
        <Button onClick={onHandleBack} interfaceType={ButtonType.Secondary}>
          Нет, исправить
        </Button>

        <Button onClick={onHandleNext} interfaceType={ButtonType.Primary}>
          Да, всё верно
        </Button>
      </div>
    </div>
  );
};
