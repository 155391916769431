import { ButtonType } from 'Components/UI-kit/Buttons/Button';
import { PropsWithChildren } from 'react';
import { ReactComponent as CloseIcon } from 'assets/svg/closeCross.svg';
import { ReactElement, SVGProps } from 'react';
import { SelectOptionColor } from '../types';
import styles from './Option.module.scss';
import { Button } from 'Components/UI-kit/Buttons/Button';

export type OptionProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLLIElement>,
  HTMLLIElement
> & {
  isSelect?: boolean;
  isActive?: boolean;
  isAllOption?: boolean;
  color?: SelectOptionColor;
  renderIcon?: () => ReactElement<SVGProps<SVGSVGElement>>;
  onHandleDelete?: () => void;
};

export const Option = ({
  onClick,
  isAllOption,
  isSelect,
  isActive,
  color,
  children,
  renderIcon,
  onHandleDelete,
}: PropsWithChildren<OptionProps>) => {
  return (
    <li
      role='option'
      aria-selected={isSelect}
      className={[
        styles[color || ''],
        isActive ? styles.active : '',
        isAllOption ? styles.allOption : '',
      ]
        .filter((style) => style)
        .join(' ')}
      onClick={onClick}
    >
      <div>
        {children} {renderIcon && renderIcon()}
      </div>
      {!!onHandleDelete && (
        <Button
          onClick={(e) => {
            //нужно чтобы при нажатии на удалить не срабатывал выбор опции
            e.stopPropagation();
            onHandleDelete();
          }}
          className={styles.closeButton}
          Icon={CloseIcon}
          interfaceType={ButtonType.SmallGhost}
        />
      )}
    </li>
  );
};
