import { useGetHhUserListQuery } from '../services/RTKService/hhUser/endpoints/hhUserApi';
import { useCallback } from 'react';

export const useGetHhUser = () => {
  const { data, isLoading } = useGetHhUserListQuery();

  const getResumeOptionsById = useCallback(
    (id?: number) => {
      if (!id) return [];
      return data?.normalizeHhUserList.entities[id]?.resumeOptions ?? [];
    },
    [data?.normalizeHhUserList],
  );
  const getHhUserById = (id?: number) => {
    if (!id || !data) return;
    return data.normalizeHhUserList.entities[id];
  };

  return {
    ids: data?.normalizeHhUserList.ids as number[] | undefined,
    entities: data?.normalizeHhUserList.entities,
    result: data?.result,
    hhUserOptions: data?.hhUserOptions ?? [],
    getResumeOptionsById,
    getHhUserById,
    isLoading,
  };
};
