import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../services/RTKService/auth/endpoints/authApi';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import { authThunk } from '../../redux/slices/authSlice';
import { addServerErrors } from '../../Shared/hookForm/addServerErrors';
import { UserRequest } from '../../Entities';

export const useSignIn = () => {
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserRequest>();

  const [login, { isLoading }] = useLoginMutation();

  const onSubmit = handleSubmit(async (payload) => {
    const res = await login({
      data: payload,
      customOptions: {
        showErrorToast: false,
        notToken: true,
      },
    });
    if ('data' in res) {
      await dispatch(authThunk.login(res.data));
      navigate('/dashboard');
      return;
    }
    if ('error' in res) {
      addServerErrors(res, setError);
    }
  });

  const onLink = () => {
    navigate('/signUp');
  };

  return { control, onSubmit, isLoading, onLink, errors };
};
