import { Outlet } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CreateMailingRequest,
  EmploymentTypeEnum,
  ExperienceEnum,
  OrderEnum,
  ScheduleEnum,
  SearchPeriodEnum,
} from '../../../../Entities';

import styles from './CreateMailingFormProvider.module.scss';
import { useEffect } from 'react';
import { CreateMailingRequestSchema } from '../../../../schemas/CreateMailingRequestSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';
import { LoadFormModal } from './LoadFormModal/LoadFormModal';
import { usePersistOnUnmountAndUnload } from '../hooks/usePersistOnUnmountAndUnload';
import { FORM_VALUES } from '../constants';

const getDefaultFormValues = (
  resumeOptions: { value: string; label: string }[],
  hhUserId: number | undefined,
): CreateMailingRequest => {
  return {
    employment_type: [EmploymentTypeEnum.FULL],
    areas: [],
    search_key: resumeOptions[0]?.label,
    hh_user: hhUserId as number,
    letter: '',
    order: OrderEnum.RELEVANCE,
    search_period: SearchPeriodEnum._0,
    sleep_time: undefined,
    resume_hash: resumeOptions[0]?.value,
    plan: 1000,
    daily_plan: 100,
    restricted_employers: [],
    experience: ExperienceEnum.DOES_NOT_MATTER,
    schedule: [ScheduleEnum.FULL_DAY],
  };
};

export const CreateMailingFormProvider = () => {
  const { ids, entities, getResumeOptionsById } = useGetHhUser();
  const defaultHhUserId = ids && ids[0];

  const methods = useForm<CreateMailingRequest>({
    resolver: zodResolver(CreateMailingRequestSchema),
    values: getDefaultFormValues(
      getResumeOptionsById(defaultHhUserId),
      defaultHhUserId,
    ),
  });

  const { watch, setValue, trigger, getValues } = methods;

  usePersistOnUnmountAndUnload({ key: FORM_VALUES, getValues });

  useEffect(() => {
    //следим за hh_user в форме и меняем resume_hash и search_key в случае изменения
    const select_Hh_userId = watch('hh_user');
    if (!select_Hh_userId) return;
    const selectHh_user = entities && entities[select_Hh_userId];
    if (!selectHh_user) return;
    const { resumes } = selectHh_user;
    setValue('search_key', resumes[0]?.name);
    setValue('resume_hash', resumes[0]?.hash);
  }, [watch('hh_user')]);

  useEffect(() => {
    //следим за resume_hash в форме и меняем desired_vacancy_search_key в случае изменения
    const select_resume_hash = watch('resume_hash');
    const select_hh_userId = watch('hh_user');
    if (!select_resume_hash) return;
    const options = getResumeOptionsById(select_hh_userId);
    const selectResumeOption = options.find(
      ({ value }) => value === select_resume_hash,
    );
    if (!selectResumeOption) return;
    const desired_vacancy_search_key = selectResumeOption.label;
    setValue('search_key', desired_vacancy_search_key);
    trigger('search_key');
  }, [watch('resume_hash')]);

  return (
    <div className={styles.container}>
      <FormProvider {...methods}>
        <Outlet />
        <LoadFormModal />
      </FormProvider>
    </div>
  );
};
