import React from 'react';
import { OnHandleOptionType, SelectOption } from './types';

/**
 * The source code is mostly taken from
 * https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
 * And it is mostly remained unchanged in order to satisfy a11y
 */

// for page-up and page-down
const PAGE_SIZE = 10;

export const useSelectorKeyboardEvents = (
  options: SelectOption[],
  setShowOptions: React.Dispatch<React.SetStateAction<boolean>>,
  changeValue: OnHandleOptionType,
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
) => {
  /*
   *   This content is licensed according to the W3C Software License at
   *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
   */

  // Save a list of named combobox actions, for future readability
  enum SelectActions {
    Close,
    CloseSelect,
    First,
    Last,
    Next,
    Open,
    PageDown,
    PageUp,
    Previous,
    Select,
    Type,
  }

  /*
   * Helper functions
   */

  // filter an array of options against an input string
  // returns an array of options that begin with the filter string, case-independent
  const filterOptions = (
    localOptions: SelectOption[] = [],
    filter: string,
  ): SelectOption[] => {
    return localOptions.filter(
      (option) =>
        option.label.toLowerCase().indexOf(filter.toLowerCase()) === 0,
    );
  };

  // map a key press to an action
  const getActionFromKey = (
    event: React.KeyboardEvent<HTMLDivElement>,
    menuOpen: boolean,
  ) => {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // all keys that will do the default open action
    // handle opening when closed
    if (!menuOpen && openKeys.includes(key)) {
      return SelectActions.Open;
    }

    // home and end move the selected option when open or closed
    if (key === 'Home') {
      return SelectActions.First;
    }
    if (key === 'End') {
      return SelectActions.Last;
    }

    // handle typing characters when open or closed
    if (
      key === 'Backspace' ||
      key === 'Clear' ||
      (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)
    ) {
      return SelectActions.Type;
    }

    // handle keys when open
    if (menuOpen) {
      if (key === 'ArrowUp' && altKey) {
        return SelectActions.CloseSelect;
      } else if (key === 'ArrowDown' && !altKey) {
        return SelectActions.Next;
      } else if (key === 'ArrowUp') {
        return SelectActions.Previous;
      } else if (key === 'PageUp') {
        return SelectActions.PageUp;
      } else if (key === 'PageDown') {
        return SelectActions.PageDown;
      } else if (key === 'Escape') {
        return SelectActions.Close;
      } else if (key === 'Enter' || key === ' ') {
        return SelectActions.CloseSelect;
      }
    }
  };

  // return the index of an option from an array of options, based on a search string
  // if the filter is multiple iterations of the same letter (e.g "aaa"), then cycle through first-letter matches
  const getIndexByLetter = (
    options: SelectOption[],
    filter: string,
    startIndex = 0,
  ) => {
    const orderedOptions = [
      ...options.slice(startIndex),
      ...options.slice(0, startIndex),
    ];
    const firstMatch = filterOptions(orderedOptions, filter)[0];
    const allSameLetter = (array: string[]) =>
      array.every((letter) => letter === array[0]);

    // first check if there is an exact match for the typed string
    if (firstMatch) {
      return options.indexOf(firstMatch);
    }

    // if the same letter is being repeated, cycle through first-letter matches
    else if (allSameLetter(filter.split(''))) {
      const matches = filterOptions(orderedOptions, filter[0]);
      return options.indexOf(matches[0]);
    }

    // if no matches, return -1
    else {
      return -1;
    }
  };

  // get an updated option index after performing an action
  const getUpdatedIndex = (
    currentIndex: number,
    maxIndex: number,
    action: SelectActions,
  ) => {
    switch (action) {
      case SelectActions.First:
        return 0;
      case SelectActions.Last:
        return maxIndex;
      case SelectActions.Previous:
        return Math.max(0, currentIndex - 1);
      case SelectActions.Next:
        return Math.min(maxIndex, currentIndex + 1);
      case SelectActions.PageUp:
        return Math.max(0, currentIndex - PAGE_SIZE);
      case SelectActions.PageDown:
        return Math.min(maxIndex, currentIndex + PAGE_SIZE);
      default:
        return currentIndex;
    }
  };

  let searchTimeout: number | undefined = undefined;
  let searchString = '';
  const getSearchString = (char: string) => {
    // reset typing timeout and start new timeout
    // this allows us to make multiple-letter matches, like a native select
    if (typeof searchTimeout === 'number') {
      window.clearTimeout(searchTimeout);
    }

    searchTimeout = window.setTimeout(() => {
      searchString = '';
    }, 500);

    // add most recent letter to saved search string
    searchString += char;
    return searchString;
  };

  const onComboType = (letter: string, activeIndex: number) => {
    // open the listbox if it is closed
    setShowOptions(true);

    // find the index of the first matching option
    const localSearchString = getSearchString(letter);
    const searchIndex = getIndexByLetter(
      options,
      localSearchString,
      activeIndex + 1,
    );

    // if a match was found, go to it
    if (searchIndex >= 0) {
      setActiveIndex(searchIndex);
    }
    // if no matches, clear the timeout and search string
    else {
      window.clearTimeout(searchTimeout);
      searchString = '';
    }
  };

  const onComboKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    activeIndex: number,
    areOptionsShown: boolean,
  ): void => {
    const { key } = event;
    const max = options.length - 1;

    const action = getActionFromKey(event, areOptionsShown);

    // eslint-disable no-fallthrough
    switch (action) {
      case SelectActions.Last:
      case SelectActions.First:
        setShowOptions(true);
      // intentional fallthrough
      case SelectActions.Next:
      case SelectActions.Previous:
      case SelectActions.PageUp:
      case SelectActions.PageDown:
        event.preventDefault();
        return setActiveIndex(getUpdatedIndex(activeIndex, max, action));
      case SelectActions.CloseSelect:
        event.preventDefault();
        changeValue({
          selectValue: options[activeIndex].value,
          index: activeIndex,
        });
      // intentional fallthrough
      case SelectActions.Close:
        event.preventDefault();
        return setShowOptions(false);
      case SelectActions.Type:
        return onComboType(key, activeIndex);
      case SelectActions.Open:
        event.preventDefault();
        return setShowOptions(true);
    }
  };
  // eslint-enable no-fallthrough
  return { onComboKeyDown };
};
