import Screenshot_1 from 'assets/images/Screenshot 1.png';
import Screenshot_2 from 'assets/images/Screenshot 2.png';
import styles from './CookieStealingGuide.module.scss';
import { DivProps } from 'Shared/interfaces';

export const CookieStealingGuide = ({ className, ...otherProps }: DivProps) => {
  return (
    <div className={[styles.container, className].join(' ')} {...otherProps}>
      <ol>
        <li>
          Установите расширение HHmailer authorizaion из{' '}
          <a
            target={'_blank'}
            href={
              'https://chrome.google.com/webstore/detail/hhmailer-authorizaion/nomccogeegidhfhjljnkdbdfmgeegfji?hl=ru'
            }
            rel='noreferrer'
          >
            Интернет-магазин chrome
          </a>
          <p>
            Это расширение копирует только куки с сайта hh.ru и не собирает
            другие данные.
          </p>
          <p>
            В данный момент, работать с нашим сервисом возможно исключительно из
            Google Chrome и Яндекс Браузер.
          </p>
        </li>
        <li>
          Откройте сайт{' '}
          <a target={'_blank'} href={'https://hh.ru'} rel='noreferrer'>
            hh.ru
          </a>{' '}
          и войдите в свой профиль.
        </li>
        <li>
          Нажмите на иконку расширения и нажмите «Скопировать куки». Расширение
          скопирует ваши куки для hh.ru в буфер обмена.
          <div className={styles.imageContainer}>
            <img
              className={'rounded-[8px]'}
              src={Screenshot_1}
              alt='Screenshot_1'
            />
            <img
              className={'rounded-[8px]'}
              src={Screenshot_2}
              alt='Screenshot_2'
            />
          </div>
        </li>
        <li>Вставьте скопированные куки в поле ниже и нажмите кнопку:</li>
      </ol>
    </div>
  );
};
