import styles from './Support.module.scss';
import { Button, ButtonType } from '../../Buttons/Button';
import { ReactComponent as TelegramIcon } from '../../../../assets/svg/telegram.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/svg/email.svg';
import { MenuStateEnum } from '../types';
import { DivProps } from '../../../../Shared/interfaces';

import { EMAIL_SUPPORT, TELEGRAMM_SUPPORT } from 'config/const';

export const Support = ({
  menuState,
  className,
  ...rest
}: DivProps<{ menuState: MenuStateEnum }>) => {
  return (
    <div
      className={[styles.support, className, styles[menuState]].join(' ')}
      {...rest}
    >
      <h5>Поддержка</h5>
      <div className={styles.buttons}>
        <a href={TELEGRAMM_SUPPORT} target={'_blank'} rel='noreferrer'>
          <Button interfaceType={ButtonType.Small} Icon={TelegramIcon}>
            {menuState === MenuStateEnum.horizonCollapse ? null : 'Телеграм'}
          </Button>
        </a>

        <a href={`mailto:${EMAIL_SUPPORT}`} target={'_blank'} rel='noreferrer'>
          <Button interfaceType={ButtonType.Small} Icon={EmailIcon}>
            {menuState === MenuStateEnum.horizonCollapse ? null : 'Почта'}
          </Button>
        </a>
      </div>
    </div>
  );
};
