import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../../services/RTKService/auth/endpoints/authApi';
import { addServerErrors } from '../../../Shared/hookForm/addServerErrors';
import {
  validConfirmPasswordRules,
  validEmailRules,
  validPasswordRules,
} from '../../../Shared/hookForm/validationRules';
import { ErrorsMapper } from '../../FormElements/ServerErrors/ErrorsMapper';
import { Button, ButtonType } from '../Buttons/Button';
import { HookFormTextInput } from '../TextInput/HookFormTextInput';
import styles from './SignUpCard.module.scss';
import { Registration } from '../../../Entities';
import { NavigationPath } from '../../../config/navigation.data';

interface ISignUpForm {
  email: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  onClickSignIn: () => void;
}

export const SignUpCard = ({ onClickSignIn }: Props) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    watch,
  } = useForm<ISignUpForm>();

  const [register] = useRegisterMutation();
  const onSubmit = handleSubmit(async ({ email, password }) => {
    const res = await register({
      data: { email, password },
      customOptions: { showErrorToast: false },
    });
    if ('data' in res) {
      reset();
      navigate(NavigationPath.confirm_email);
    }
    if ('error' in res) {
      addServerErrors(res, setError);
    }
  });
  return (
    <div className={styles.registrationCard}>
      <h2>Регистрация</h2>
      <div className={styles.inputsHolder}>
        <HookFormTextInput<ISignUpForm>
          control={control}
          name={'email'}
          label={'Почта'}
          isHiddenLabel
          placeholder={'Почта'}
          rules={validEmailRules}
          autoComplete={'email'}
          isShowError={false}
        />
        <HookFormTextInput<ISignUpForm>
          control={control}
          name={'password'}
          label={'Пароль'}
          isHiddenLabel
          placeholder={'Пароль'}
          rules={validPasswordRules}
          autoComplete={'password'}
          type={'password'}
          isShowError={false}
        />
        <HookFormTextInput<ISignUpForm>
          control={control}
          name={'confirmPassword'}
          label={'Повторите пароль'}
          isHiddenLabel
          placeholder={'Повторите пароль'}
          rules={validConfirmPasswordRules<ISignUpForm>(watch, 'password')}
          autoComplete={'password'}
          type={'password'}
          isShowError={false}
        />
      </div>
      <ErrorsMapper<Registration>
        errors={errors}
        allErrors
        renderFunc={(mappedErrors) => (
          <div className={styles.errorContainer}>
            {mappedErrors.map((err, index) => (
              <span key={index} className={styles.errorMessage}>
                {err}
              </span>
            ))}
          </div>
        )}
      />
      <div className={styles.buttonsHolder}>
        <Button onClick={onSubmit} interfaceType={ButtonType.Primary}>
          Продолжить
        </Button>
        <Button onClick={onClickSignIn} interfaceType={ButtonType.Secondary}>
          Войти
        </Button>
      </div>
    </div>
  );
};
