import { createAsyncThunk } from '@reduxjs/toolkit';

import { hhUserApi } from '../../../services/RTKService/hhUser/endpoints/hhUserApi';
import { HhUserRequest } from '../../../Entities';

const linkHhUserByCookies = createAsyncThunk<
  void,
  { hhUserId: string; cookies: string }
>('settings/mutateHhUserData', async ({ hhUserId, cookies }, { dispatch }) => {
  await dispatch(
    hhUserApi.endpoints.patchUser.initiate({
      data: { cookies },
      hhUserId,
    }),
  );
});

const createHhUser = createAsyncThunk(
  'settings/createHhUser',
  async (params: HhUserRequest, { dispatch }) => {
    await dispatch(hhUserApi.endpoints.postHhUser.initiate({ data: params }));
  },
);

const deleteHhUser = createAsyncThunk(
  'settings/deleteHhUser',
  async (hhUserId: number, { dispatch }) => {
    await dispatch(hhUserApi.endpoints.deleteHhUser.initiate({ id: hhUserId }));
  },
);

export const hhUserAsyncActions = {
  linkHhUserByCookies,
  createHhUser,
  deleteHhUser,
};
