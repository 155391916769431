import styles from './MailingList.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { Link } from 'react-router-dom';
import { NavigationPath } from '../../../../config/navigation.data';
import { Loader } from '../../../../Components/UI-kit/Loader/Loader';
import { FilterBar } from './FilterBar/FilterBar';
import { useGetMailingList } from '../../../../hooks/useGetMailingList';
import {
  MailingCard,
  MailingInfo,
} from '../../../../Components/UI-kit/MaillingCard/MailingCard';
import { useMemo } from 'react';

export const MailingList = () => {
  const {
    isLoading,
    data,
    filteredIds,
    setMailingstatus,
    mailingstatus,
    hhAccountId,
    setHhAccountId,
    sort,
    setSort,
  } = useGetMailingList();

  const mailingList = useMemo(
    () =>
      filteredIds.map((id) => (
        <MailingCard mailingInfo={data?.entities[id] as MailingInfo} key={id} />
      )),
    [filteredIds, data?.entities],
  );

  if (!data) return null;
  if (isLoading) return <Loader />;

  if (!data.ids.length) {
    return <EmptyState />;
  }

  return (
    <div className={styles.container}>
      <FilterBar
        setMailingstatus={setMailingstatus}
        mailingstatus={mailingstatus}
        hhAccountId={hhAccountId}
        setHhAccountId={setHhAccountId}
        sort={sort}
        setSort={setSort}
      />
      <div className={styles.mailingList}>{mailingList}</div>
    </div>
  );
};

export const EmptyState = () => {
  return (
    <div className={styles.emptyContainer}>
      <h2 className={styles.h2}>Нет рассылок</h2>
      <Link to={NavigationPath.mailingCreateStep1}>
        <Button interfaceType={ButtonType.Primary}>Новая рассылка</Button>
      </Link>
    </div>
  );
};
