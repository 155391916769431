import { Button, ButtonProps, ButtonType } from '../../Buttons/Button';
import { useStopMailingMutation } from '../../../../services/RTKService/mailing/endpoints/mailingApi';

type Created = ButtonProps & {
  mailing_id: number;
};
export const StopButton = ({ mailing_id, children, ...rest }: Created) => {
  const [stop] = useStopMailingMutation();

  const onHandleButton = async () => {
    await stop({ id: mailing_id });
  };

  return (
    <Button
      onClick={onHandleButton}
      interfaceType={ButtonType.Primary}
      {...rest}
    >
      {children}
    </Button>
  );
};
