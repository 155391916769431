/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `20` - 20 вакансий
 * * `50` - 50 вакансий
 * * `100` - 100 вакансий
 */
export enum ItemsOnPageEnum {
    _20 = '20',
    _50 = '50',
    _100 = '100',
}
