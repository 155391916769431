import { useEffect, useState } from 'react';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';
import styles from './HhUserInfo.module.scss';
import { Statistics } from '../../Statistics/Statistics';
import { ProgressBarColor } from '../../ProgressBar/ProgressBar';
import { MenuStateEnum } from '../types';
import { Selector } from 'Components/UI-kit/Selector';
import { MenuPlacement, SelectorType } from 'Components/UI-kit/Selector';
// import { StatusBarList } from './StatusBarList/StatusBarList';

export const HhUserInfo = ({ menuState }: { menuState: MenuStateEnum }) => {
  const [hhUserId, changeHhUserId] = useState<number | undefined>();
  const { hhUserOptions, getHhUserById } = useGetHhUser();
  const hhUser = getHhUserById(hhUserId);
  const applies_count = hhUser?.applies_count;

  useEffect(() => {
    if (!hhUserOptions.length) return;
    changeHhUserId(hhUserOptions[0]?.value);
  }, [hhUserOptions]);

  if (!hhUserId || !hhUser) return null;

  return (
    <div className={[styles.container, styles[menuState]].join(' ')}>
      {/* TODO: добавить статусбар когда будет готов бек */}
      {/* <StatusBarList menuState={menuState} /> */}
      <div className={styles.summary}>
        <Statistics
          className={styles.statistics}
          progressBarColor={ProgressBarColor.Yellow}
          dailyFeedbackRate={applies_count ?? 0}
          totalFeedbackRate={200}
        />
        <Selector
          menuPlacement={MenuPlacement.Top}
          className={styles.selector}
          tag='hh.ru'
          options={hhUserOptions}
          value={hhUserId}
          changeValue={changeHhUserId}
          type={SelectorType.Account}
        />
      </div>
    </div>
  );
};
