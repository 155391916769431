/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `PLAN_COMPLETED` - Все отклики разосланы
 * * `DAILY_PLAN_COMPLETED` - Дневной план на рассылку выполнен
 * * `OUT_OF_VACANCIES` - Закончились вакансии по данному запросу
 * * `HH_LIMIT_REACHED` - Достигнут лимит запросов, установленный hh.ru
 * * `RUNNING` - Запущена
 * * `CREATED` - Создана
 * * `PENDING` - Ожидает очереди
 * * `STOPPED` - Остановлена
 * * `ERROR` - Ошибка
 */
export enum StatusEnum {
    PLAN_COMPLETED = 'PLAN_COMPLETED',
    DAILY_PLAN_COMPLETED = 'DAILY_PLAN_COMPLETED',
    OUT_OF_VACANCIES = 'OUT_OF_VACANCIES',
    HH_LIMIT_REACHED = 'HH_LIMIT_REACHED',
    RUNNING = 'RUNNING',
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    STOPPED = 'STOPPED',
    ERROR = 'ERROR',
}
