import { Button, ButtonProps, ButtonType } from '../../Buttons/Button';
import { useDeleteMailingMutation } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { useState } from 'react';
import { DeleteModal } from './DeleteModal/DeleteModal';

type Props = ButtonProps & {
  mailing_id: number;
};

export const DeleteButton = ({ mailing_id, children, ...rest }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [remove, { isLoading }] = useDeleteMailingMutation();
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onHandleDelete = async () => {
    await remove({ id: mailing_id });
    setOpenModal(false);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        id='delete-modal-x-button'
        onClick={onOpenModal}
        interfaceType={ButtonType.Primary}
        {...rest}
      >
        {children}
      </Button>
      <DeleteModal
        isLoading={isLoading}
        onDelete={onHandleDelete}
        isOpen={openModal}
        onClose={onCloseModal}
        modalId='delete-modal-x'
        title='Удалить рассылку?'
      />
    </>
  );
};
