import { Outlet } from 'react-router-dom';
import { useGetMailingByIdQuery } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AreasEnum,
  CreateMailingRequest,
  EmploymentTypeEnum,
  ExperienceEnum,
  OrderEnum,
  RetrieveMailing,
  ScheduleEnum,
  SearchPeriodEnum,
} from '../../../../Entities';

import styles from './Mailing.module.scss';
import { useGetIdFromSearchParams } from '../hooks/useGetIdFromSearchParams';
import { useEffect } from 'react';
import { CreateMailingRequestSchema } from '../../../../schemas/CreateMailingRequestSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';

const getDefaultFormValues = (
  apiData: (RetrieveMailing & { restricted_employerId: number[] }) | undefined,
  resumeOptions: { value: string; label: string }[],
  hhUserId: number | undefined,
): CreateMailingRequest => {
  if (apiData) {
    return {
      employment_type: apiData.employment_type,
      areas: apiData.areas,
      search_key: apiData.search_key,
      hh_user: apiData.hh_user,
      letter: apiData.letter,
      order: apiData.order,
      search_period: apiData.search_period,
      sleep_time: apiData.sleep_time,
      resume_hash: apiData.resume_hash,
      plan: apiData.plan,
      daily_plan: apiData.daily_plan,
      restricted_employers: apiData.restricted_employerId,
      experience: apiData.experience,
      schedule: apiData.schedule,
    };
  }
  return {
    employment_type: [EmploymentTypeEnum.FULL],
    areas: [AreasEnum._0],
    search_key: resumeOptions[0]?.label,
    hh_user: hhUserId as number,
    letter: '',
    order: OrderEnum.RELEVANCE,
    search_period: SearchPeriodEnum._0,
    sleep_time: undefined,
    resume_hash: resumeOptions[0]?.value,
    plan: 1000,
    daily_plan: 100,
    restricted_employers: [],
    experience: ExperienceEnum.DOES_NOT_MATTER,
    schedule: [ScheduleEnum.FULL_DAY],
  };
};

export const Mailing = () => {
  const { ids, entities, getResumeOptionsById } = useGetHhUser();

  const hhUserId = ids && ids[0];

  const editableMailingId = useGetIdFromSearchParams();

  const { data: mailingData } = useGetMailingByIdQuery(
    { id: editableMailingId as number },
    { skip: !editableMailingId },
  );
  const methods = useForm<CreateMailingRequest>({
    resolver: zodResolver(CreateMailingRequestSchema),
    values: getDefaultFormValues(
      mailingData,
      getResumeOptionsById(hhUserId),
      hhUserId,
    ),
  });
  const { watch, setValue, trigger } = methods;

  useEffect(() => {
    //следим за hh_user в форме и меняем resume_hash и desired_vacancy_search_key в случае изменения
    const select_Hh_userId = watch('hh_user');
    if (!select_Hh_userId) return;
    const selectHh_user = entities && entities[select_Hh_userId];
    if (!selectHh_user) return;
    const { resumes } = selectHh_user;
    setValue('search_key', resumes[0]?.name);
    setValue('resume_hash', resumes[0]?.hash);
  }, [watch('hh_user')]);

  useEffect(() => {
    //следим за resume_hash в форме и меняем desired_vacancy_search_key в случае изменения
    const select_resume_hash = watch('resume_hash');
    const select_hh_userId = watch('hh_user');
    if (!select_resume_hash) return;
    const options = getResumeOptionsById(select_hh_userId);
    const selectResumeOption = options.find(
      ({ value }) => value === select_resume_hash,
    );
    if (!selectResumeOption) return;
    const desired_vacancy_search_key = selectResumeOption.label;
    setValue('search_key', desired_vacancy_search_key);
    trigger('search_key');
  }, [watch('resume_hash')]);

  return (
    <div className={styles.container}>
      <FormProvider {...methods}>
        <Outlet />
      </FormProvider>
    </div>
  );
};
