import { DivProps } from '../../../../Shared/interfaces';
import styles from './InputWithButton.module.scss';
import { TextInput } from '../../../../Components/UI-kit/TextInput/TextInput';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { ChangeEvent, useState } from 'react';
import { usePostHhUserMutation } from '../../../../services/RTKService/hhUser/endpoints/hhUserApi';

export type InputWithButtonProps = {
  onHandleButton?: (cookie: string) => Promise<'success' | 'failure'>;
};
export const InputWithButton = ({
  onHandleButton,
  className,
  ...otherProps
}: DivProps<InputWithButtonProps>) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [post] = usePostHhUserMutation();

  const onPostHHAccount = async (
    cookie: string,
  ): Promise<'success' | 'failure'> => {
    const response = await post({ data: { cookies: JSON.parse(cookie) } });
    if ('error' in response) {
      return 'failure';
    }
    return 'success';
  };

  const onClick = async () => {
    if (!inputValue) return;

    const result = await onPostHHAccount(inputValue);
    onHandleButton && (await onHandleButton(inputValue));
    if (result === 'success') {
      setInputValue('');
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  return (
    <div
      className={[styles.inputWithButton, className].join(' ')}
      {...otherProps}
    >
      <TextInput
        value={inputValue}
        className={styles.textInput}
        placeholder='Скопированные куки'
        onChange={onChange}
      />
      <Button
        disabled={!inputValue}
        onClick={onClick}
        interfaceType={ButtonType.BigPrimary}
      >
        Привязать аккаунт
      </Button>
    </div>
  );
};
