import React, { useRef } from 'react';
import styles from './ToolTip.module.scss';

/**
 * Enum for tooltip positioning options.
 */
export enum TooltipPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

/**
 * Props for the ToolTip component.
 * @property content - The content to display inside the tooltip, can be a string or React node.
 * @property children - The element that triggers the tooltip on hover.
 * @property followMouse - If true, the tooltip follows the mouse cursor.
 * @property position - The preferred position of the tooltip relative to the trigger element.
 * @property className - Additional class names to apply to the tooltip for custom styling.
 */
interface ToolTipProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  followMouse?: boolean;
  position?: TooltipPosition;
  className?: string;
}

/**
 * A component that displays a tooltip with customizable content and positioning.
 * The tooltip can optionally follow the mouse cursor.
 *
 * @param {ToolTipProps} props - The props for the component.
 * @returns {JSX.Element} The rendered tooltip component.
 */
export const ToolTip: React.FC<ToolTipProps> = ({
  content,
  children,
  followMouse = false,
  position = TooltipPosition.Top,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  /**
   * Updates the position of the tooltip based on the mouse event.
   *
   * @param {React.MouseEvent<HTMLDivElement>} e - The mouse event triggering the position update.
   */
  const updateTooltipPosition = (e: React.MouseEvent<HTMLDivElement>) => {
    if (followMouse && containerRef.current && tooltipRef.current) {
      const { clientWidth, clientHeight } = tooltipRef.current;
      const rect = containerRef.current.getBoundingClientRect();
      tooltipRef.current.style.left = `${e.clientX - rect.left + clientWidth / 2}px`;
      tooltipRef.current.style.top = `${e.clientY - rect.top - clientHeight}px`;
    }
  };

  const classNames = [styles.tooltipContent, styles[position], className]
    .filter(Boolean)
    .join(' ');

  return (
    <div
      ref={containerRef}
      onMouseMove={updateTooltipPosition}
      className={styles.tooltipContainer}
    >
      {children}
      <div ref={tooltipRef} className={classNames}>
        {typeof content === 'string' ? (
          <p className={styles.tooltipText}>{content}</p>
        ) : (
          content
        )}
      </div>
    </div>
  );
};
