// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_progressBar__cGSIL {
  border-radius: 4px;
  width: 100%;
  height: 6px;
  box-shadow: var(--shadows-progressBar);
  background: var(--progressBar-fill-line);
}
.ProgressBar_progressBar__cGSIL > div {
  border-radius: 4px;
  height: 100%;
}

.ProgressBar_green__iYgc5 {
  background: var(--progressBar-common-lineActiveGreen);
}

.ProgressBar_gray__UoPer {
  background: var(--progressBar-lineDayLimit);
}

.ProgressBar_yellow__w\\+GAB {
  background: var(--progressBar-lineReadyYellow);
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,sCAAA;EACA,wCAAA;AACF;AACE;EACE,kBAAA;EACA,YAAA;AACJ;;AAGA;EACE,qDAAA;AAAF;;AAGA;EACE,2CAAA;AAAF;;AAIA;EACE,8CAAA;AADF","sourcesContent":[".progressBar {\n  border-radius: 4px;\n  width: 100%;\n  height: 6px;\n  box-shadow: var(--shadows-progressBar);\n  background: var(--progressBar-fill-line);\n\n  &>div {\n    border-radius: 4px;\n    height: 100%;\n  }\n}\n\n.green {\n  background: var(--progressBar-common-lineActiveGreen);\n}\n\n.gray {\n  background: var(--progressBar-lineDayLimit);\n\n}\n\n.yellow {\n  background: var(--progressBar-lineReadyYellow);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `ProgressBar_progressBar__cGSIL`,
	"green": `ProgressBar_green__iYgc5`,
	"gray": `ProgressBar_gray__UoPer`,
	"yellow": `ProgressBar_yellow__w+GAB`
};
export default ___CSS_LOADER_EXPORT___;
