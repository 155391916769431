import { useEffect } from 'react';

/**
 * Does `handler` function on the click outside of all given refs
 */
export const useOutsideClick = (
  refs: React.MutableRefObject<HTMLElement | null>[],
  handler: () => void,
) => {
  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (
        refs.every(
          (ref) => ref.current && !ref.current.contains(target as Node),
        )
      ) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, refs);
};
