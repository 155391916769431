import { useSelector } from 'react-redux';
import Stats from '../../Components/Stats/Stats';
import BlockingWrapper from '../../Components/BlockingWrapper/BlockingWrapper';
import BlockingPopup from './components/BlockingPopup';
import './Mailing.css';
import { ControlButtonsGroup } from './components/ControlButtonsGroup/ControlButtonsGroup';
import { Heading } from '../../Components';
import {
  _selectHhUser,
  selectHhUserIsLoading,
} from '../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { selectMailingItemsArray } from '../../services/RTKService/mailing/MailingSelectors/MailingSelectors';
import { HookFormMailingSettings } from './components/MailingSettings/HookFormMailingSettings';

export const Mailing = () => {
  const hhUser = useSelector(_selectHhUser);
  const hhUserLoading = useSelector(selectHhUserIsLoading);
  const tasks = useSelector(selectMailingItemsArray);
  const id = tasks.length ? tasks[0].id : undefined;
  const hhUserStatus = hhUser?.cookies_alive;

  return (
    <div className='py-4 xl:w-3/4  sm:w-full'>
      <BlockingWrapper
        isBlocked={!hhUserStatus && !hhUserLoading}
        blockContent={
          <BlockingPopup
            type='success'
            headerText='Привяжите свой аккаунт HeadHunter'
            text='Чтобы использовать наш сервис, необходимо привязать
            HeadHunter-аккаунт, содержащий хотя бы одно резюме.'
            buttonText='Привязать аккаунт'
          />
        }
      >
        <div className={'sticky top-0 py-3 z-10 bg-gray-100'}>
          <div className='flex justify-between mb-3'>
            <Heading
              title={id ? 'Редактировать рассылку' : 'Создать рассылку'}
            />
            <ControlButtonsGroup id={id} />
          </div>
          {id && <Stats taskId={id} className='task__stats' />}
        </div>
        <HookFormMailingSettings id={id} />
      </BlockingWrapper>
    </div>
  );
};
