import classNames from 'classnames';
import styles from './Notification.module.scss';
import { ReactComponent as CircleYellow } from 'assets/svg/circle_yellow.svg';

// type for props = css-class-name
export enum NotificationType {
  Outlined = 'outlined',
}
export const Notification = ({
  notificationType,
}: {
  notificationType?: NotificationType;
}) => {
  return (
    <div
      className={classNames(
        styles.notification,
        notificationType && styles[notificationType],
      )}
    >
      <CircleYellow />
      <p>
        Максимальное количество откликов для одного аккаунта hh.ru
        <br />
        за день — 200. Учитывайте это при создании нескольких рассылок.
      </p>
    </div>
  );
};
