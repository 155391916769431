/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `0` - за все время
 * * `30` - за месяц
 * * `7` - за неделю
 * * `3` - за последние 3 дня
 * * `1` - за сутки
 */
export enum SearchPeriodEnum {
    _0 = '0',
    _30 = '30',
    _7 = '7',
    _3 = '3',
    _1 = '1',
}
