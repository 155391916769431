import { getSelectedOption } from '../utils/getSelectedOption';
import { SelectOption, SelectorValue } from '../types';
import { MultipleSelectOptions } from '../MultipleSelectOptions/MultipleSelectOptions';
import { SingleSelectOption } from '../SingleSelectOption/SingleSelectOption';

export const RenderSelectOption = ({
  value,
  options,
  placeholder,
  tag,
  onChangeSearchValue,
  changeValue,
  searchValue,
  areOptionsShown,
  isSearchable,
}: {
  value: SelectorValue;
  options: SelectOption[];
  placeholder?: string;
  tag?: string;
  onChangeSearchValue: (inputValue: string) => void;
  changeValue: (...args: any[]) => void;
  searchValue: string;
  isSearchable: boolean;
  areOptionsShown: boolean;
}) => {
  const selectedOptions = getSelectedOption(value, options);

  if (!selectedOptions) return <span>{placeholder}</span>;

  if (Array.isArray(selectedOptions)) {
    return (
      <MultipleSelectOptions
        selectedOptions={selectedOptions}
        value={value}
        changeValue={changeValue}
      />
    );
  }

  return (
    <SingleSelectOption
      isSearchable={isSearchable}
      searchValue={searchValue}
      selectedOption={selectedOptions}
      tag={tag}
      onChangeSearchValue={onChangeSearchValue}
      areOptionsShown={areOptionsShown}
    />
  );
};
