import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as AccountsIcon } from '../assets/svg/accounts.svg';
import { ReactComponent as MailIcon } from '../assets/svg/mail.svg';
import { ReactComponent as SettingsIcon } from '../assets/svg/settings.svg';

export enum AppRoutes {
  signIn = 'signIn',
  signUp = 'signUp',
  mailing = 'mailing',
  mailing_create = 'create',
  mailing_create_step1 = 'step1/*',
  mailing_create_step2 = 'step2/*',
  mailing_create_step3 = 'step3/*',
  mailing_edit = 'edit/*',
  statistics = 'statistics',
  settings = 'settings',
  hhAccounts = 'hh-accounts',
  user_settings = 'user-settings',
  account = 'account',
  payment = 'payment',
  email_confirmation = 'email-confirmation',
  confirm_email = 'confirm-email',
}

export enum NavigationPath {
  confirm_email = '/confirm-email',
  mailing = '/mailing',
  mailingEdit = '/mailing/edit',
  mailingCreateStep1 = '/mailing/create/step1',
  mailingCreateStep2 = '/mailing/create/step2',
  mailingCreateStep3 = '/mailing/create/step3',
  hh_accounts = '/hh-accounts',
  user_settings = '/user-settings',
  user_settings_account = '/user-settings/account',
  user_settings_payment = '/user-settings/payment',
}

export interface NavigationItem {
  path: NavigationPath;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  state: Record<string, any>;
}

export type UserSettingsNavigationItem = Pick<
  NavigationItem,
  'title' | 'path' | 'state'
>;

export const menuBarNavigationItems: NavigationItem[] = [
  {
    Icon: MailIcon,
    title: 'Рассылки',
    path: NavigationPath.mailing,
    state: {},
  },
  {
    Icon: AccountsIcon,
    title: 'Аккаунты hh.ru',
    path: NavigationPath.hh_accounts,
    state: {},
  },
  {
    Icon: SettingsIcon,
    title: 'Настройки',
    path: NavigationPath.user_settings,
    state: {},
  },
];

export const userSettingsNavigationItems: UserSettingsNavigationItem[] = [
  {
    title: 'Профиль',
    path: NavigationPath.user_settings_account,
    state: {},
  },
  // {
  //   title: 'Оплата',
  //   path: NavigationPath.user_settings_payment,
  //   state: {},
  // },
];

export type MailingStepsNavigationItem = {
  path: NavigationPath;
};
export const mailingStepsNavigationItems: MailingStepsNavigationItem[] = [
  { path: NavigationPath.mailingCreateStep1 },
  { path: NavigationPath.mailingCreateStep2 },
  { path: NavigationPath.mailingCreateStep3 },
];
