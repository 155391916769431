import ReactDOM from 'react-dom/client';
import 'assets/styles/index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AuthGuard } from './Providers/AuthGuard/AuthGuard';
import { ThemProvider } from 'features/theme/context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Router>
    <ThemProvider>
      <Provider store={store}>
        <AuthGuard>
          <App />
        </AuthGuard>
      </Provider>
    </ThemProvider>
  </Router>,
);
