import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import {
  ChatBubbleBottomCenterIcon,
  FolderIcon,
  HomeIcon,
  MinusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import cn from 'classnames';
import { Fragment, useState } from 'react';
import { authThunk } from '../../redux/slices/authSlice';
import { Dialog, Transition } from '@headlessui/react';
import { Logo } from '../../Components';

export function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const navStyle = (
    element: string,
    isMobile?: boolean,
    navLinkLocation?: string,
  ) => {
    let isActive = location.pathname === navLinkLocation;

    const isDashBoardPath =
      location.pathname === '/dashboard' &&
      navLinkLocation === '/dashboard/statistics';

    if (isDashBoardPath) {
      isActive = true;
    }

    if (isMobile) {
      switch (element) {
        case 'outer': {
          const commonOuter =
            'group flex items-center px-2 py-2 text-base font-medium rounded-md';
          return isActive
            ? cn('bg-gray-900 text-white', commonOuter)
            : cn(
                'text-gray-300 hover:bg-gray-700 hover:text-white',
                commonOuter,
              );
        }
        case 'inner': {
          const commonInner = 'mr-4 flex-shrink-0 h-6 w-6';
          return isActive
            ? cn('text-gray-300', commonInner)
            : cn('text-gray-400 group-hover:text-gray-300', commonInner);
        }
      }
    } else {
      switch (element) {
        case 'outer': {
          const commonOuter =
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md';
          return isActive
            ? cn('bg-gray-900 text-white', commonOuter)
            : cn(
                'text-gray-300 hover:bg-gray-700 hover:text-white',
                commonOuter,
              );
        }
        case 'inner': {
          const commonInner = 'mr-3 flex-shrink-0 h-6 w-6';
          return isActive
            ? cn('text-gray-300', commonInner)
            : cn('text-gray-400 group-hover:text-gray-300', commonInner);
        }
      }
    }
  };

  const dispatch = useTypedDispatch();

  const getNavigationElements = (isMobile: boolean) => {
    const navigation = [
      { name: 'Рассылки', href: '/dashboard/statistics', icon: HomeIcon },
      {
        name: 'Настройки',
        href: localStorage.getItem('path') || '/dashboard/settings/hhauth',
        icon: FolderIcon,
      },
    ];

    return navigation.map((item) => (
      <NavLink
        key={item.name}
        to={item.href}
        className={navStyle('outer', isMobile, item.href)}
      >
        <item.icon
          className={navStyle('inner', isMobile, item.href)}
          aria-hidden='true'
        />
        {item.name}
      </NavLink>
    ));
  };

  const handleLogout = () => {
    dispatch(authThunk.logout());
    navigate('/');
  };

  const renderCore = (isMobile: boolean) => {
    return (
      <div className='flex flex-col flex-1 h-0 pt-5 pb-4 overflow-y-auto'>
        <Logo />

        <nav className='flex-1 mt-5 px-2 space-y-1'>
          {getNavigationElements(isMobile)}
        </nav>

        <a
          className={navStyle('outer', isMobile)}
          href={'https://t.me/hhmailer_support'}
          target={'_blank'}
          rel='noreferrer'
        >
          <ChatBubbleBottomCenterIcon
            className={navStyle('inner', isMobile)}
            aria-hidden='true'
          />
          <span>Супорт</span>
        </a>

        <div className='text-[#D1D5DB] text-center mt-4 mb-4'>
          <button className='hover:underline' onClick={handleLogout}>
            Выйти
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className='h-screen flex overflow-hidden bg-gray-100'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 flex z-40 md:hidden'
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full bg-gray-800'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Закрыть</span>
                    <XMarkIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </Transition.Child>
              {renderCore(true)}
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          <div className='flex flex-col h-0 flex-1 bg-gray-800'>
            {renderCore(false)}
          </div>
        </div>
      </div>

      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <div className='md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3'>
          <button
            className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Открыть</span>
            <MinusIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none'>
          <div className='py-6'>
            <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
