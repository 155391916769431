import { useEffect, useState } from 'react';
import { SelectOption } from '../types';
import styles from './SingleSelectOption.module.scss';

export const SingleSelectOption = ({
  selectedOption,
  tag,
  onChangeSearchValue,
  searchValue,
  areOptionsShown,
  isSearchable,
}: {
  selectedOption: SelectOption;
  tag?: string;
  onChangeSearchValue: (inputValue: string) => void;
  searchValue: string;
  areOptionsShown: boolean;
  isSearchable: boolean;
}) => {
  // Локальное состояние для значения инпута
  const [displayValue, setDisplayValue] = useState(selectedOption.label);

  // Обновляем отображаемое значение при изменении выбранной опции
  useEffect(() => {
    setDisplayValue(selectedOption.label);
  }, [selectedOption]);

  useEffect(() => {
    if (!searchValue.trim()) {
      setDisplayValue(selectedOption.label);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!areOptionsShown) {
      setDisplayValue(selectedOption.label);
    }
  }, [areOptionsShown]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setDisplayValue(newValue);
    onChangeSearchValue(newValue);
  };

  return (
    <p
      key={selectedOption.value}
      className={[styles.container, styles[selectedOption?.color || '']].join(
        ' ',
      )}
    >
      {tag && (
        <span role='tag' className={styles.tag}>
          {tag}
        </span>
      )}
      {isSearchable ? (
        <input
          className='w-fit bg-inherit text-inherit ring-0 outline-none'
          type='text'
          value={displayValue}
          onChange={onChange}
          onClick={(e) => {
            if (areOptionsShown) e.stopPropagation();
          }}
          onFocus={(e) => {
            e.target.select();
          }}
        />
      ) : (
        selectedOption.label
      )}
    </p>
  );
};
