import { hhUserApi } from '../endpoints/hhUserApi';
import { createSelector } from '@reduxjs/toolkit';

const selectHhUserResult = hhUserApi.endpoints.getHhUserList.select();

export const selectHhUserIsLoading = createSelector(
  selectHhUserResult,
  ({ isLoading }) => isLoading,
);

export const _selectHhUser = createSelector(selectHhUserResult, ({ data }) => {
  if (!data?.result) {
    return null;
  }
  if (data.result.length === 0) {
    return null;
  }
  return data.result[0];
});

export const selectHhUserId = createSelector(selectHhUserResult, ({ data }) => {
  if (!data) {
    return null;
  }
  if (data.result.length === 0) {
    return null;
  }
  return data.result[0].id ?? data.result[0].hh_id;
});
// export const a = '';
