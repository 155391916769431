// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateMailing_container__TEBEE {
  position: relative;
  margin: 0 0 3.25rem;
  width: 100%;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  background: inherit;
}
.CreateMailing_container__TEBEE .CreateMailing_mailingStepsContainer__o2h30 {
  width: 100%;
  padding: 0.375rem 0;
  background: inherit;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
}
.CreateMailing_container__TEBEE .CreateMailing_mailingSteps__4oh25 {
  width: 100%;
}
@media (min-width: 560px) {
  .CreateMailing_container__TEBEE .CreateMailing_mailingSteps__4oh25 {
    width: -moz-fit-content;
    width: fit-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/CreateMailing/CreateMailing.module.scss","webpack://./src/assets/styles/screens.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;AAFF;AAIE;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;AAFJ;AAIE;EACE,WAAA;AAFJ;ACRE;EDSA;IAGI,uBAAA;IAAA,kBAAA;EAAJ;AACF","sourcesContent":["@use 'src/assets/styles/screens.scss' as screens;\n@import 'src/assets/styles/utils';\n\n.container {\n  position: relative;\n  margin: 0 0 rem(52);\n  width: 100%;\n  max-width: rem(512);\n  display: flex;\n  flex-direction: column;\n  gap: rem(36);\n  background: inherit;\n\n  .mailingStepsContainer {\n    width: 100%;\n    padding: rem(6) 0;\n    background: inherit;\n    position: sticky;\n    top: 0;\n    left: 0;\n    right: 0;\n    z-index: 40;\n  }\n  .mailingSteps {\n    width: 100%;\n    @include screens.xs {\n      width: fit-content;\n    }\n  }\n}\n","$xxs: 320px;\n$xs: 560px;\n$sm: 640px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@mixin xxs {\n  @media (min-width: $xxs) {\n    @content;\n  }\n}\n@mixin xs {\n  @media (min-width: $xs) {\n    @content;\n  }\n}\n@mixin sm {\n  @media (min-width: $sm) {\n    @content;\n  }\n}\n@mixin md {\n  @media (min-width: $md) {\n    @content;\n  }\n}\n@mixin lg {\n  @media (min-width: $lg) {\n    @content;\n  }\n}\n@mixin xl {\n  @media (min-width: $xl) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media (min-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CreateMailing_container__TEBEE`,
	"mailingStepsContainer": `CreateMailing_mailingStepsContainer__o2h30`,
	"mailingSteps": `CreateMailing_mailingSteps__4oh25`
};
export default ___CSS_LOADER_EXPORT___;
