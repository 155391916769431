import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import { HookFormTextInput } from 'Components/UI-kit/TextInput/HookFormTextInput';
import { useForm } from 'react-hook-form';
import { useChangeUserPasswordMutation } from 'services/RTKService/auth/endpoints/authApi';
import {
  validConfirmPasswordRules,
  validPasswordRules,
} from 'Shared/hookForm/validationRules';
import styles from './UserAccountPage.module.scss';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { authThunk } from 'redux/slices/authSlice';

interface FormData {
  old_password?: string;
  new_password?: string;
  confirm_new_password?: string;
}

export function UserAccountPage() {
  const dispatch = useTypedDispatch();
  const { control, handleSubmit, watch } = useForm<FormData>();

  const [changePassword] = useChangeUserPasswordMutation();

  const onSubmit = handleSubmit(async ({ new_password, old_password }) => {
    await changePassword({
      data: { password: new_password, old_password },
      customOptions: {
        showSuccessToast: true,
        successMessage: 'Данные успешно изменены!',
      },
    });
  });

  const onHandleLogout = () => {
    dispatch(authThunk.logout());
  };

  return (
    <div className={styles.container}>
      <HookFormTextInput
        control={control}
        name='old_password'
        label='Старый пароль'
        type='password'
        rules={validPasswordRules}
        autoComplete='off'
      />
      <HookFormTextInput
        control={control}
        name='new_password'
        label='Новый пароль'
        type='password'
        rules={validPasswordRules}
        autoComplete='off'
      />
      <HookFormTextInput
        control={control}
        name='confirm_new_password'
        label='Подтвердить новый пароль'
        type='password'
        rules={validConfirmPasswordRules<FormData>(watch, 'new_password')}
        autoComplete='off'
      />
      <div className={styles.buttons}>
        <Button onClick={onSubmit} interfaceType={ButtonType.Primary}>
          Сохранить
        </Button>
        <Button onClick={onHandleLogout} interfaceType={ButtonType.Secondary}>
          Выйти из сервиса
        </Button>
      </div>
    </div>
  );
}
