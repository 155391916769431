import { FC, memo } from 'react';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import { CreateMailingRequest, ExperienceEnum } from '../../../../../Entities';

const experienceOptions: { value: ExperienceEnum; label: string }[] = [
  { value: ExperienceEnum.DOES_NOT_MATTER, label: 'Не имеет значения' },
  { value: ExperienceEnum.NO_EXPERIENCE, label: 'Нет опыта' },
  { value: ExperienceEnum.BETWEEN1AND3, label: 'От 1 до 3х лет' },
  { value: ExperienceEnum.BETWEEN3AND6, label: 'От 3х до 6-ти лет' },
  { value: ExperienceEnum.MORE_THAN6, label: 'Более 6-ти лет' },
];

interface Props {
  control: Control<CreateMailingRequest>;
}

export const ExperienceSelector: FC<Props> = memo(({ control }) => {
  return (
    <SelectorWrapper label={'По опыту работы'}>
      <HookFormReactSelector<CreateMailingRequest>
        defaultValue={experienceOptions[0].value}
        control={control}
        name={'experience'}
        options={experienceOptions}
        className={'w-full'}
        menuPlacement={'top'}
      />
    </SelectorWrapper>
  );
});
