// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[role=listbox] {
  position: absolute;
  border-radius: 12px;
  background: var(--frame-fill-convex);
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadows-listbox);
  width: 100%;
  transition: height, var(--transform-duration);
  transition-timing-function: ease-out;
  margin-top: 8px;
  overflow: hidden;
  z-index: 30;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/Selector/ListBox/ListBox.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,oCAAA;EACA,aAAA;EACA,sBAAA;EACA,kCAAA;EACA,WAAA;EACA,6CAAA;EACA,oCAAA;EAGA,eAAA;EACA,gBAAA;EACA,WAAA;AADF","sourcesContent":["div[role='listbox'] {\n  position: absolute;\n  border-radius: 12px;\n  background: var(--frame-fill-convex);\n  display: flex;\n  flex-direction: column;\n  box-shadow: var(--shadows-listbox);\n  width: 100%;\n  transition: height, var(--transform-duration);\n  transition-timing-function: ease-out;\n\n  // gap between combobox and listbox\n  margin-top: 8px;\n  overflow: hidden;\n  z-index: 30;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
