import React from 'react';
import styles from './Tabs.module.scss';
import { Link } from 'react-router-dom';
import { UserSettingsNavigationItem } from '../../../config/navigation.data';

export type NavLink = {
  path: string;
  title: string;
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  links: UserSettingsNavigationItem[];
  value: string;
  changeValue: React.Dispatch<React.SetStateAction<string>>;
}

export const Tabs: React.FC<Props> = ({
  links,
  value,
  changeValue,
  ...otherProps
}) => {
  return (
    <nav {...otherProps}>
      <ul>
        {links.map(({ path, title, state }) => (
          <li
            key={title}
            className={title === value ? styles.active : ''}
            onClick={() => {
              changeValue(title);
            }}
          >
            <Link to={path} state={state}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
