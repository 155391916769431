import React from 'react';
import { TextInput } from 'Components/UI-kit/TextInput/TextInput';
import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';

interface InputWithButtonProps {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onHandlePastToClipboard?: () => void;
  onCreateOption?: () => void;
  isEmptyOptions?: boolean;
  isSearchable?: boolean;
}

export const InputWithButton: React.FC<InputWithButtonProps> = ({
  inputValue,
  setInputValue,
  onHandlePastToClipboard,
  onCreateOption,
  isEmptyOptions,
  isSearchable,
}) => {
  const placeholder =
    onCreateOption && isSearchable
      ? 'Найти или добавить ссылку на компанию'
      : onCreateOption
      ? 'Добавить ссылку на компанию'
      : isSearchable
      ? 'Поиск'
      : '';

  return (
    <div className={'flex gap-3 relative'}>
      {onCreateOption || isSearchable ? (
        <TextInput
          autoComplete={'none'}
          placeholder={placeholder}
          onChange={({ currentTarget: { value } }) => setInputValue(value)}
          value={inputValue}
        />
      ) : null}
      {onCreateOption && isEmptyOptions ? (
        <Button
          onClick={onCreateOption}
          className={'absolute right-2 top-1/2 -translate-y-1/2'}
          interfaceType={ButtonType.Small}
        >
          {'Добавить'}
        </Button>
      ) : null}
    </div>
  );
};
