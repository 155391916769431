// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuBar_menuBarConstants__L0-Q9 {
  --menu-width: 3.75rem;
  --menu-height: 3.75rem;
}

@media (min-width: 1024px) {
  .MenuBar_themeSwitcher__jsIqI {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MenuBar/MenuBar.module.scss","webpack://./src/assets/styles/screens.scss"],"names":[],"mappings":"AAIA;EACE,qBAAA;EACA,sBAAA;AAHF;;AC0BE;EDrBF;IAEI,wBAAA;EAFF;AACF","sourcesContent":["@use 'src/assets/styles/texts.scss' as text;\n@use 'src/assets/styles/screens.scss' as screen;\n@import 'src/assets/styles/utils';\n\n.menuBarConstants {\n  --menu-width: 3.75rem;\n  --menu-height: 3.75rem;\n}\n.themeSwitcher {\n  @include screen.lg {\n    display: none !important;\n  }\n}\n","$xxs: 320px;\n$xs: 560px;\n$sm: 640px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@mixin xxs {\n  @media (min-width: $xxs) {\n    @content;\n  }\n}\n@mixin xs {\n  @media (min-width: $xs) {\n    @content;\n  }\n}\n@mixin sm {\n  @media (min-width: $sm) {\n    @content;\n  }\n}\n@mixin md {\n  @media (min-width: $md) {\n    @content;\n  }\n}\n@mixin lg {\n  @media (min-width: $lg) {\n    @content;\n  }\n}\n@mixin xl {\n  @media (min-width: $xl) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media (min-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuBarConstants": `MenuBar_menuBarConstants__L0-Q9`,
	"themeSwitcher": `MenuBar_themeSwitcher__jsIqI`
};
export default ___CSS_LOADER_EXPORT___;
