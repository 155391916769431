import { Logo } from 'Components/UI-kit/Logo/Logo';
import styles from './ConfirmEmailPage.module.scss';
export const ConfirmEmailPage = () => {
  return (
    <div className={styles.container}>
      <Logo />
      <h2>
        Пожалуйста, перейдите по ссылке из письма, отправленного на почту
        example@gmail.com
      </h2>
    </div>
  );
};
