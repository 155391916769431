import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import { BaseModal, ModalProps } from '../../../BaseModal/BaseModal';
import styles from './DeleteModal.module.scss';

type DeleteModalProps = ModalProps & {
  onDelete: () => void;
  isLoading: boolean;
};

export function DeleteModal(props: DeleteModalProps) {
  return (
    <BaseModal {...props}>
      <div className={styles.content}>
        <p>
          Данные о рассылке будут безвозвратно стерты. Вы действительно хотите
          удалить рассылку?
        </p>
        <div className={styles.buttons}>
          <Button
            onClick={props.onDelete}
            interfaceType={ButtonType.Primary}
            disabled={props.isLoading}
          >
            Да, удалить
          </Button>
          <Button onClick={props.onClose} interfaceType={ButtonType.Secondary}>
            Нет
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
