import {
  useGetMailingByIdQuery,
  useStartMailingMutation,
  useStopMailingMutation,
} from '../../../services/RTKService/mailing/endpoints/mailingApi';

export const useGetStartStopMailingTask = (id?: number) => {
  const {
    data: taskData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetMailingByIdQuery(
    { id: id as number },
    {
      skip: !id,
    },
  );

  const [onStart, { isLoading: isLoadingStart }] = useStartMailingMutation();
  const [onStop, { isLoading: isLoadingStop }] = useStopMailingMutation();

  const onStartTask = async () => {
    if (isSuccess) {
      await onStart({
        id: id as number,
      });
      refetch();
    }
  };
  const onStopTask = async () => {
    if (isSuccess) {
      await onStop({
        id: id as number,
      });
      refetch();
    }
  };
  return {
    taskData,
    onStartTask,
    onStopTask,
    isLoading: isLoading || isLoadingStart || isLoadingStop,
  };
};
