import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { SelectorProps } from './types';
import { Selector } from './Selector';

interface Props<T extends FieldValues>
  extends Omit<SelectorProps, 'value' | 'changeValue'> {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: RegisterOptions<T, FieldPath<T>>;
  isLoading?: boolean;
  showError?: boolean;
}

export const WithHookFormControlSelector = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { control, rules, name, options, onCreateOption, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Selector
            {...rest}
            options={options}
            value={value}
            changeValue={onChange}
            onCreateOption={onCreateOption}
            error={error?.message}
          />
        );
      }}
    />
  );
};
