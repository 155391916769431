// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateMailingFormProvider_container__4B1Ru {
  position: relative;
  margin: 0 0 3.25rem;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  background: var(--frame-fill-convex-darker);
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/UpdateMailingFormProvider/UpdateMailingFormProvider.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,2CAAA;AADF","sourcesContent":["@import 'src/assets/styles/utils';\n\n.container {\n  position: relative;\n  margin: 0 0 rem(52);\n  max-width: rem(512);\n  display: flex;\n  flex-direction: column;\n  gap: rem(36);\n  background: var(--frame-fill-convex-darker);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UpdateMailingFormProvider_container__4B1Ru`
};
export default ___CSS_LOADER_EXPORT___;
