import { PropsWithChildren } from 'react';
import { DivProps } from '../../../../Shared/interfaces';
import { MenuStateEnum } from '../types';
import styles from './Container.module.scss';

export const Container = ({
  children,
  menuState,
  className,
  ...rest
}: PropsWithChildren<DivProps<{ menuState: MenuStateEnum }>>) => {
  return (
    <div className={[styles.relativeContainer, className].join(' ')}>
      <div
        className={[styles.container, styles[menuState]].join(' ')}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};
