import { PropsWithChildren } from 'react';
import { MenuStateEnum } from '../types';
import styles from './Content.module.scss';
import { DivProps } from '../../../../Shared/interfaces';

export const Content = ({
  children,
  variant,
  className,
  ...rest
}: PropsWithChildren<DivProps<{ variant: MenuStateEnum }>>) => {
  return (
    <div
      className={[styles.content, className, styles[variant]].join(' ')}
      {...rest}
    >
      {children}
    </div>
  );
};
