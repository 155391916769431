import { Route, Routes } from 'react-router-dom';
import './App.css';
import { EmailConfirmation } from './Pages';
import { useInitializeApp } from './hooks/useInitializeApp';
import { useInitializeAuthedApp } from './hooks/useInitializeAuthedApp';

import { ConfirmEmailPage } from 'Pages/newDesign/ConfirmEmailPage/ConfirmEmailPage';
import { HHAccountsPage } from 'Pages/newDesign/HHAccountsPage/HHAccountsPage';
import { MainPage } from 'Pages/newDesign/MainPage/MainPage';
import { MenuLayout } from 'Pages/newDesign/MenuLayout/MenuLayout';
import { NotFoundPage } from 'Pages/newDesign/NotFoundPage/NotFoundPage';
import { UserAccountPage } from 'Pages/newDesign/UserAccountPage/UserAccountPage';
import { RootLayout } from './Pages/newDesign/RootLayout/RootLayout';
import { SignInPage } from './Pages/newDesign/SignInPage/SignInPage';
import { SignUpPage } from './Pages/newDesign/SignUpPage/SignUpPage';
import { UserSettingPage } from './Pages/newDesign/UserSettingPage/UserSettingPage';
import { AppRoutes } from './config/navigation.data';
import { Mailing } from './Pages/newDesign/MailingPage/Mailing/Mailing';
import { EditMailingStep1 } from './Pages/newDesign/MailingPage/EditMailingStep1/EditMailingStep1';
import { EditMailingStep2 } from './Pages/newDesign/MailingPage/EditMailingStep2/EditMailingStep2';
import { EditMailingStep3 } from './Pages/newDesign/MailingPage/EditMailingStep3/EditMailingStep3';
import { UpdateMailing } from './Pages/newDesign/MailingPage/UpdateMailing/UpdateMailing';
import { CreateMailing } from './Pages/newDesign/MailingPage/CreateMailing/CreateMailing';
import { MailingList } from './Pages/newDesign/MailingPage/MailingList/MailingList';
import { ErrorToast } from 'Components';
import { SuccessToast } from 'Components';
function App() {
  useInitializeApp();
  useInitializeAuthedApp();

  return (
    <>
      <ErrorToast />
      <SuccessToast />
      <Routes>
        <Route path='/' element={<RootLayout />}>
          <Route index element={<MainPage />} />
          <Route path={AppRoutes.signIn} element={<SignInPage />} />
          <Route path={AppRoutes.signUp} element={<SignUpPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>

        <Route
          path={AppRoutes.email_confirmation}
          element={<EmailConfirmation />}
        />
        <Route path={AppRoutes.confirm_email} element={<ConfirmEmailPage />} />

        <Route element={<MenuLayout />}>
          <Route path={AppRoutes.mailing}>
            <Route index element={<MailingList />} />
            <Route element={<Mailing />}>
              <Route
                path={AppRoutes.mailing_edit}
                element={<UpdateMailing />}
              />
              <Route
                path={AppRoutes.mailing_create}
                element={<CreateMailing />}
              >
                <Route
                  path={AppRoutes.mailing_create_step1}
                  element={<EditMailingStep1 />}
                />
                <Route
                  path={AppRoutes.mailing_create_step2}
                  element={<EditMailingStep2 />}
                />
                <Route
                  path={AppRoutes.mailing_create_step3}
                  element={<EditMailingStep3 />}
                />
              </Route>
            </Route>
          </Route>

          <Route path={AppRoutes.hhAccounts} element={<HHAccountsPage />} />

          <Route path={AppRoutes.user_settings} element={<UserSettingPage />}>
            <Route index={true} element={<UserAccountPage />} />
            <Route path={AppRoutes.account} element={<UserAccountPage />} />
            <Route path={AppRoutes.payment} element={<h1>payment</h1>} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
