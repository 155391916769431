import { ReactComponent as SunIcon } from 'assets/svg/sun.svg';
import { ReactComponent as MoonIcon } from 'assets/svg/moon.svg';
import styles from './ThemesSwitch.module.scss';
import { Button, ButtonType } from '../Buttons/Button';
import { DivProps } from '../../../Shared/interfaces';
import { useTheme } from 'features/theme/context';

export enum ThemesSwitchVariant {
  Rounded = 'rounded',
  Squarer = 'squarer',
  Vertical = 'vertical',
}

interface Props extends DivProps {
  variant?: ThemesSwitchVariant;
}

export const ThemesSwitch = ({
  variant = ThemesSwitchVariant.Rounded,
  className,
  ...otherProps
}: Props) => {
  const { onDark, onLight, theme } = useTheme();

  return (
    <div
      className={[styles.switch, styles[variant], className].join(' ')}
      {...otherProps}
    >
      <Button
        interfaceType={ButtonType.SmallGhost}
        onClick={onLight}
        className={[theme === 'light' && styles.activeButton].join(' ')}
        Icon={SunIcon}
      >
        <span>Светлая</span>
      </Button>
      <Button
        interfaceType={ButtonType.SmallGhost}
        onClick={onDark}
        className={[theme === 'dark' && styles.activeButton].join(' ')}
        Icon={MoonIcon}
      >
        <span>Тёмная</span>
      </Button>
    </div>
  );
};
