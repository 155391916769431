import { PropsWithChildren } from 'react';

import { useAuthorize } from './useAuthorize';

const OPEN_ROUTES = [
  '/',
  '/signIn',
  '/signUp',
  '/confirm-email',
  '/email-confirmation',
];

export const AuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthorized = useAuthorize({ openRoutes: OPEN_ROUTES });

  return <>{isAuthorized && children}</>;
};
