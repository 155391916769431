import { FieldErrors } from 'react-hook-form';
import { ReactElement } from 'react';
import { FieldValues } from 'react-hook-form';
import { entries } from 'Shared/Utils/typedEntries';
import { ErrorMessage } from 'Components/UI-kit/ErrorMessage/ErrorMessage';

type IServerErrorsProps<T extends FieldValues> = {
  errors: FieldErrors<T>;
  renderFunc?: (mappedErrors: string[]) => ReactElement;
  allErrors?: boolean;
};

export const ErrorsMapper = <T extends Record<string, any>>({
  errors,
  renderFunc,
  allErrors = false,
}: IServerErrorsProps<T>) => {
  if (Object.keys(errors).length === 0) {
    return null;
  }

  const mappedErrors = entries(errors).reduce((acc, [, value]) => {
    if (allErrors) {
      if (value && value.message) {
        return [...acc, value.message as string];
      }
    }
    if (value && value.type === 'server' && value.message) {
      return [...acc, value.message as string];
    }
    return acc;
  }, [] as string[]);

  if (!mappedErrors.length) {
    return null;
  }
  if (renderFunc) {
    return renderFunc(mappedErrors);
  }
  return <ErrorMessage errors={mappedErrors} />;
};
