// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterBar_filterBar__o2DWT {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.FilterBar_filterBar__o2DWT > * {
  flex: 1 1;
  text-wrap: nowrap;
  min-width: 100%;
}
@media (min-width: 640px) {
  .FilterBar_filterBar__o2DWT > * {
    flex: 0 0 15rem;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 15rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/MailingList/FilterBar/FilterBar.module.scss","webpack://./src/assets/styles/screens.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,eAAA;EACA,WAAA;AAFF;AAGE;EACE,SAAA;EACA,iBAAA;EACA,eAAA;AADJ;ACUE;EDNE;IACE,eAAA;IACA,2BAAA;IAAA,sBAAA;IACA,YAAA;EADJ;AACF","sourcesContent":["@use 'src/assets/styles/screens' as screen;\n@import 'src/assets/styles/utils';\n\n.filterBar {\n  display: flex;\n  flex-wrap: wrap;\n  gap: rem(8);\n  & > * {\n    flex: 1 1 0;\n    text-wrap: nowrap;\n    min-width: 100%;\n  }\n  @include screen.sm {\n    & > * {\n      flex: 0 0 rem(240);\n      min-width: fit-content;\n      width: rem(240);\n    }\n  }\n}\n// .statusSelector {\n//   --select-width: rem(240);\n//   width: rem(240);\n// }\n// .hhAccountSelector {\n//   --select-width: 100%;\n//   width: fit-content;\n// }\n","$xxs: 320px;\n$xs: 560px;\n$sm: 640px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@mixin xxs {\n  @media (min-width: $xxs) {\n    @content;\n  }\n}\n@mixin xs {\n  @media (min-width: $xs) {\n    @content;\n  }\n}\n@mixin sm {\n  @media (min-width: $sm) {\n    @content;\n  }\n}\n@mixin md {\n  @media (min-width: $md) {\n    @content;\n  }\n}\n@mixin lg {\n  @media (min-width: $lg) {\n    @content;\n  }\n}\n@mixin xl {\n  @media (min-width: $xl) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media (min-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBar": `FilterBar_filterBar__o2DWT`
};
export default ___CSS_LOADER_EXPORT___;
