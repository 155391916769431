import { CookieStealingGuide } from './CookieStealingGuide/CookieStealingGuide';
import { HHAccountsList } from './HHAccountsList/HHAccountsList';
import { CollapsableInputWithButton } from './CollapsableInputWithButton/CollapsableInputWithButton';
import { InputWithButton } from './InputWithButton/InputWithButton';
import { Loader } from '../../../Components/UI-kit/Loader/Loader';
import { useGetHhUser } from '../../../hooks/useGetHhUser';

export const HHAccountsPage = () => {
  const { ids, isLoading } = useGetHhUser();
  const variants = {
    noAccounts: [
      <CookieStealingGuide key={'CookieStealingGuide'} />,
      <InputWithButton key={'InputWithButton'} className='ml-[36px]' />,
    ],
    haveAccounts: [
      <HHAccountsList key={'HHAccountsList'} />,
      <CollapsableInputWithButton key={'InputWithButtonCollapse'} />,
    ],
  };

  if (isLoading) return <Loader />;
  return (
    <div className={'flex flex-col justify-center  sm:justify-start'}>
      {!isLoading && variants[ids?.length ? 'haveAccounts' : 'noAccounts']}
    </div>
  );
};
