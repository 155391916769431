import React, { FunctionComponent, SVGProps } from 'react';
import styles from './Button.module.scss';

// type for props = css-class-name
export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Narrow = 'narrow',
  MailCard = 'mail-card',
  Small = 'small',
  SmallGhost = 'smallGhost',
  BigPrimary = 'big-primary',
  BigSecondary = 'big-secondary',
  AppCard = 'app-card',
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  interfaceType?: ButtonType;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  interfaceType,
  Icon,
  className,
  ...otherProps
}) => {
  return (
    <button
      className={[
        interfaceType && styles[interfaceType],
        styles.button,
        className,
      ].join(' ')}
      {...otherProps}
    >
      {Icon && <Icon role='img' aria-hidden='true' />}
      {children}
    </button>
  );
};
