import React, { memo } from 'react';
import { HookFormControlledTextarea } from '../../../../../Components';
import { InputWrapper } from './InputWrapper';
import { Control } from 'react-hook-form';
import { CreateMailingRequest } from '../../../../../Entities';

interface ICoverLetterInputProps {
  control: Control<CreateMailingRequest>;
}

export const CoverLetterTextarea: React.FC<ICoverLetterInputProps> = memo(
  ({ control }) => {
    return (
      <InputWrapper label={'Сопроводительное письмо'}>
        <HookFormControlledTextarea<CreateMailingRequest>
          name={'letter'}
          control={control}
          showError={false}
          placeholder={'Сопроводительное письмо'}
          rows={5}
        />
      </InputWrapper>
    );
  },
);
