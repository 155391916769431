// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultipleSelectOptions_container__wifBP {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.MultipleSelectOptions_selectedOption__gYnz- {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--frame-fill-moreIndented);
  box-shadow: var(--shadows-indented);
  text-wrap: nowrap;
}

.MultipleSelectOptions_closeButton__Eab-r {
  padding: 0;
  width: -moz-fit-content;
  width: fit-content;
}
.MultipleSelectOptions_closeButton__Eab-r svg {
  padding: 4px;
  min-width: 20px;
  border-radius: 6px;
  background: var(--input-default-fill);
  fill: var(--fill-icon);
}
.MultipleSelectOptions_closeButton__Eab-r svg:hover {
  background: var(--input-hover-fill);
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/Selector/MultipleSelectOptions/MultipleSelectOptions.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,eAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;EACA,kBAAA;EACA,0CAAA;EACA,mCAAA;EACA,iBAAA;AAEF;;AAAA;EACE,UAAA;EACA,uBAAA;EAAA,kBAAA;AAGF;AAFE;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,qCAAA;EACA,sBAAA;AAIJ;AAHI;EACE,mCAAA;AAKN","sourcesContent":[".container {\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n}\n.selectedOption {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  padding: 4px 8px;\n  border-radius: 8px;\n  background: var(--frame-fill-moreIndented);\n  box-shadow: var(--shadows-indented);\n  text-wrap: nowrap;\n}\n.closeButton {\n  padding: 0;\n  width: fit-content;\n  svg {\n    padding: 4px;\n    min-width: 20px;\n    border-radius: 6px;\n    background: var(--input-default-fill);\n    fill: var(--fill-icon);\n    &:hover {\n      background: var(--input-hover-fill);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MultipleSelectOptions_container__wifBP`,
	"selectedOption": `MultipleSelectOptions_selectedOption__gYnz-`,
	"closeButton": `MultipleSelectOptions_closeButton__Eab-r`
};
export default ___CSS_LOADER_EXPORT___;
