import { FieldValues, Path, UseFormWatch } from 'react-hook-form';
import { validEmail } from '../regExp/regExp';

export const validEmailRules = {
  required: 'Поле не должно быть пустым',
  pattern: {
    value: validEmail,
    message: 'Пожалуйста введите правильный адрес',
  },
};
export const validPasswordRules = {
  minLength: {
    value: 8,
    message: 'Не менее 8 символов',
  },
  maxLength: { value: 128, message: 'Не более 128 символов' },
};

export const validConfirmPasswordRules = <T extends FieldValues>(
  watch: UseFormWatch<T>,
  path?: Path<T>,
) => ({
  validate: (val?: string) => {
    if (watch<Path<T>>(path as Path<T>) != val) {
      return 'Пароль не совпадает';
    }
  },
});
