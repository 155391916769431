import { useNavigate, useSearchParams } from 'react-router-dom';
import { UpdateSelectors } from '../Selectors/UpdateSelectors';
import { DesiredAppliesInputs } from '../DesiredAppliesInputs/DesiredAppliesInputs';
import { useFormContext } from 'react-hook-form';
import styles from './UpdateMailing.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { NavigationPath } from '../../../../config/navigation.data';
import {
  useGetMailingByIdQuery,
  useStartMailingMutation,
  useStopMailingMutation,
  useUpdateMailingMutation,
} from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { PatchedUpdateMailingRequest, StatusEnum } from '../../../../Entities';
import { HHCheckButton } from 'Components/UI-kit/HHCheckButton/HHCheckButton';

export const UpdateMailing = () => {
  const [params] = useSearchParams();

  const mailingId = params.get('id') ? Number(params.get('id')) : null;

  const { data: mailingData, refetch: MailingRefetch } = useGetMailingByIdQuery(
    { id: mailingId as number },
    { skip: !mailingId },
  );

  const [stop] = useStopMailingMutation();
  const [start] = useStartMailingMutation();

  const { handleSubmit } = useFormContext<
    PatchedUpdateMailingRequest & { hh_user: number }
  >();

  const [update] = useUpdateMailingMutation();

  const navigate = useNavigate();

  const onHandleBack = () => {
    navigate({ pathname: NavigationPath.mailing });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onHandleSave = handleSubmit(async ({ hh_user, ...patchedMailing }) => {
    if (!mailingId || !mailingData) return;

    let stopResponse:
      | { data: 'Task was stopped' }
      | { error: unknown | null }
      | null = null;

    //если рассылка запущена, останавливаем
    if (mailingData.status === StatusEnum.RUNNING) {
      stopResponse = await stop({ id: mailingId });
      MailingRefetch();
      //если ругается, то ни чего не делаем, после стопа ревалидируется кеш, где будет актуальный статус рассылки
      if ('error' in stopResponse) return;
    }

    const updateResponse = await update({
      id: mailingId,
      patchedMailing,
      customOptions: { showSuccessToast: true, successMessage: 'Обновлено!' },
    });
    if ('data' in updateResponse)
      navigate({ pathname: NavigationPath.mailing });

    //если происходил кейс с остановкой рассылки, запускаем обратно, если нет просто ревалидируем кеш
    if (stopResponse) await start({ id: mailingId });

    MailingRefetch();
  });

  return (
    <div className={styles.container}>
      <UpdateSelectors />
      <DesiredAppliesInputs />
      <HHCheckButton />
      <div className={styles.buttons}>
        <Button onClick={onHandleBack} interfaceType={ButtonType.Secondary}>
          Назад
        </Button>
        <Button onClick={onHandleSave} interfaceType={ButtonType.Primary}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};
