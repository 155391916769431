import { Button, ButtonProps, ButtonType } from '../../Buttons/Button';

import { useStartMailingMutation } from '../../../../services/RTKService/mailing/endpoints/mailingApi';

type Props = ButtonProps & {
  mailing_id: number;
};
export const StartButton = ({ mailing_id, children, ...rest }: Props) => {
  const [start] = useStartMailingMutation();
  const onHandleStop = async () => {
    await start({ id: mailing_id });
  };
  return (
    <Button
      onClick={onHandleStop}
      interfaceType={ButtonType.Secondary}
      {...rest}
    >
      {children}
    </Button>
  );
};
