import { useMediaQuery } from './useMediaQuery';
import { useMemo } from 'react';

/**
 *
 xxs = min-width: 320px; 
 xs = min-width: 560px; 
 sm = min-width: 640px; 
 md = min-width: 768px; 
 lg = min-width: 1024px; 
 xl = min-width: 1280px; 
 xxl = min-width: 1536px; 
   */
export enum ScreenEnum {
  /** min-width: 320px*/
  xxs = 'xxs',

  /** min-width: 560px*/
  xs = 'xs',

  /** min-width: 640px*/
  sm = 'sm',

  /** min-width: 768px*/
  md = 'md',

  /** min-width: 1024px*/
  lg = 'lg',

  /** min-width: 1280px*/
  xl = 'xl',

  /** min-width: 1536px*/
  xxl = 'xxl',

  /** min-width: 9999999999999 px*/
  all = 'all',
}
export const useMatchScreenWidth = () => {
  const all = useMediaQuery('(min-width: 1px)');
  const xxs = useMediaQuery('(min-width: 320px)');
  const xs = useMediaQuery('(min-width: 560px)');
  const sm = useMediaQuery('(min-width: 640px)');
  const md = useMediaQuery('(min-width: 768px)');
  const lg = useMediaQuery('(min-width: 1024px)');
  const xl = useMediaQuery('(min-width: 1280px)');
  const xxl = useMediaQuery('(min-width: 1536px)');
  let match: ScreenEnum = ScreenEnum.all;
  if (all) match = ScreenEnum.all;
  if (xxs) match = ScreenEnum.xxs;
  if (xs) match = ScreenEnum.xs;
  if (sm) match = ScreenEnum.sm;
  if (md) match = ScreenEnum.md;
  if (lg) match = ScreenEnum.lg;
  if (xl) match = ScreenEnum.xl;
  if (xxl) match = ScreenEnum.xxl;

  return useMemo(
    () => ({ match, all, xxs, xs, sm, md, lg, xl, xxl }),
    [all, xxs, xs, sm, md, lg, xl, xxl],
  );
};
