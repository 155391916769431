import styles from './RootLayout.module.scss';
import { ThemesSwitch } from '../../../Components/UI-kit/ThemesSwitch/ThemesSwitch';
import { Outlet } from 'react-router-dom';

export const RootLayout = () => {
  return (
    <div className={styles.container}>
      <ThemesSwitch className={styles.themesSwitch} />
      <div className={styles.centredContent}>
        <Outlet />
      </div>
      <a
        href={'https://t.me/hhmailer_support'}
        target={'_blank'}
        rel='noreferrer'
      >
        Поддержка hhmailer
      </a>
      <div className={styles.background} />
    </div>
  );
};
