import { ReactComponent as EyeSlashIcon } from 'assets/svg/eye-slash.svg';
import { ReactComponent as EyeIcon } from 'assets/svg/eye.svg';
import React, { ChangeEvent, useEffect, useId, useRef, useState } from 'react';
import styles from './TextInput.module.scss';

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isHiddenLabel?: boolean;
  description?: string;
  error?: string;
  onShowPassword?: () => void;
  onHidePassword?: () => void;
  changeValue?: React.Dispatch<React.SetStateAction<string>>;
  controlIsValid?: boolean;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'] | 'show-password';
  isShowError?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  isHiddenLabel,
  description,
  value = '',
  changeValue,
  controlIsValid = true,
  error,
  type,
  isShowError = true,
  onChange,
  ...otherProps
}) => {
  const [inputType, setInputType] = useState<TextInputProps['type']>(type);
  useEffect(() => {
    setInputType(type);
  }, [type]);
  const onShowPassword = () => {
    setInputType('show-password');
  };
  const onHidePassword = () => {
    setInputType('password');
  };
  // id of the selector element,needed for a11y
  const selectorId = useId();

  const labelId = selectorId + '-label';
  const inputId = selectorId + '-input';
  const descriptionId = selectorId + '-description';

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.setCustomValidity('');
  }, []);

  const [isValid, setIsValid] = useState<boolean>(true);
  useEffect(() => {
    setIsValid(controlIsValid);
  }, [controlIsValid]);

  const onType = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    //если type==='number' преобразуем в число
    const prepareValue = getPrepareValue(value, type);
    changeValue && changeValue(prepareValue as string);
    onChange &&
      onChange({
        ...event,
        target: { ...event.target, value: prepareValue as string },
      });

    if (error) {
      setIsValid(!!inputRef?.current?.reportValidity());
    }
  };
  return (
    <div className={styles['text-input']}>
      <label
        className={!label ? 'hidden' : isHiddenLabel ? 'visually-hidden' : ''}
        id={labelId}
        htmlFor={inputId}
      >
        {label}
      </label>
      <div className={styles.inputContainer}>
        <input
          id={inputId}
          ref={inputRef}
          className={!isValid || error ? styles.invalid : ''}
          value={value}
          aria-invalid={isValid}
          aria-describedby={descriptionId}
          type={
            !inputType || inputType === 'show-password' ? 'text' : inputType
          }
          onChange={onType}
          onInvalid={(e) => {
            e.preventDefault();
          }}
          {...otherProps}
        />
        {inputType === 'password' && (
          <EyeSlashIcon onClick={onShowPassword} className={styles.eyeIcon} />
        )}
        {inputType === 'show-password' && (
          <EyeIcon onClick={onHidePassword} className={styles.eyeIcon} />
        )}
      </div>
      <div
        id={descriptionId}
        className={[styles.description, error && styles.error].join(' ')}
        role={isValid || error ? '' : 'alert'}
      >
        {error ? (isShowError ? error : null) : description}
      </div>
    </div>
  );
};

function getPrepareValue(value: string, type?: React.HTMLInputTypeAttribute) {
  switch (type) {
    case 'number':
      return value ? Number(value) : '';
    default:
      return value ? value : '';
  }
}
