import React, { memo, ReactElement } from 'react';
import styles from './ToolTip.module.scss';
import { ReactComponent as QuestionIcon } from 'assets/svg/ToolTipIcon.svg';

export enum TooltipVariants {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
}

export interface ToolTipProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  toolTipContent?: string | ReactElement;
  contentContainerStyle?: string;
  variants?: TooltipVariants;
}

export const ToolTip: React.FC<ToolTipProps> = memo(
  ({
    toolTipContent,
    contentContainerStyle,
    className,
    variants = TooltipVariants.bottom,
    ...rest
  }) => {
    if (!toolTipContent) return null;
    return (
      <div className={[styles.tooltip, className].join(' ')} {...rest}>
        <QuestionIcon />
        {typeof toolTipContent === 'string' ? (
          <span
            className={[
              styles.tooltipText,
              styles[variants],
              contentContainerStyle,
            ].join(' ')}
          >
            {toolTipContent}
          </span>
        ) : (
          <div
            className={[
              styles.tooltipContent,
              styles[variants],
              contentContainerStyle,
            ].join(' ')}
          >
            {toolTipContent}
          </div>
        )}
      </div>
    );
  },
);
