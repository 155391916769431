// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HhUserInfo_container__nVsMN {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.HhUserInfo_horizonCollapse__HAVY3 .HhUserInfo_summary__G5NSY {
  display: none;
}

.HhUserInfo_summary__G5NSY {
  padding: 0.375rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
  border-radius: 4px;
  box-shadow: var(--shadows-frame-convex);
  background: var(--frame-fill-convex-darker);
}

.HhUserInfo_statistics__\\+Da1B {
  padding: 0.5rem 0.5rem 0;
}

.HhUserInfo_selector__7Vpzz {
  --select-width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MenuBar/HhUserInfo/HhUserInfo.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAAF;;AAGE;EACE,aAAA;AAAJ;;AAGA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uCAAA;EACA,2CAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,+BAAA;AAAF","sourcesContent":["@import 'src/assets/styles/utils';\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n.horizonCollapse {\n  .summary {\n    display: none;\n  }\n}\n.summary {\n  padding: rem(6);\n  display: flex;\n  flex-direction: column;\n  row-gap: rem(6);\n  border-radius: 4px;\n  box-shadow: var(--shadows-frame-convex);\n  background: var(--frame-fill-convex-darker);\n}\n\n.statistics {\n  padding: rem(8) rem(8) 0;\n}\n\n.selector {\n  --select-width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HhUserInfo_container__nVsMN`,
	"horizonCollapse": `HhUserInfo_horizonCollapse__HAVY3`,
	"summary": `HhUserInfo_summary__G5NSY`,
	"statistics": `HhUserInfo_statistics__+Da1B`,
	"selector": `HhUserInfo_selector__7Vpzz`
};
export default ___CSS_LOADER_EXPORT___;
