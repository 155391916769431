import React, { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { CgCheckO, CgDanger } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';

interface IToastProps {
  message: string | null;
  onClose: () => void;
  messageMode: 'success' | 'error';
}

export const Toast: React.FC<IToastProps> = memo(
  ({ messageMode, message, onClose }) => {
    const [isShow, setIsShow] = useState(false);
    const modalRootRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      if (message) {
        setTimeout(() => {
          setIsShow(true);
        }, 300);
      } else {
        setIsShow(false);
      }
    }, [message]);

    const onClick = () => {
      if (message) {
        setIsShow(false);
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    };
    if (!message) {
      return null;
    }
    return (
      <div
        className={cn(
          'transition-all  duration-300 absolute right-2 z-20 bg-white m-0.5 flex flex-row px-3 py-3 space-x-3 rounded-lg overflow-hidden shadow-xl',
          isShow ? 'top-5 ' : '-top-32 ',
        )}
      >
        <div>
          {messageMode === 'success' ? (
            <CgCheckO fontSize={24} className='text-green-500' />
          ) : (
            <CgDanger fontSize={24} className='text-red-500' />
          )}
        </div>
        <div>
          <div className='text-black font-semibold cursor-pointer'>
            {messageMode === 'success' ? 'Успешно!' : 'Ошибка!'}
          </div>
          <div className='text-gray-500 font-medium cursor-pointer'>
            {message || 'Непредвиденная ошибка'}
          </div>
        </div>
        <div>
          <button
            onClick={onClick}
            className='transition hover:scale-110 text-gray-500'
          >
            <IoMdClose fontSize={24} />
          </button>
        </div>
      </div>
    );
  },
);
