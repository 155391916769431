import { createAsyncThunk } from '@reduxjs/toolkit';
import { appActions } from './appActions';

const initializeApp = createAsyncThunk(
  'app/initializeApplication',
  async (_) => {
    // console.log('здесь будут запросы');
  },
);
const showError = createAsyncThunk<void, { error: string }>(
  'app/showError',
  async (payload, { dispatch }) => {
    dispatch(appActions.setError(payload));
    dispatch(appActions.setShowErrorToast(true));
    const id = setTimeout(() => {
      dispatch(appActions.setShowErrorToast(false));
      dispatch(appActions.setError({ error: null }));
      clearTimeout(id);
    }, 3000);
  },
);

export const AppAsyncActions = {
  initializeApp,
  showError,
};
