import React, { memo } from 'react';
import { ControlledInput } from '../../../../../Components';
import { InputWrapper } from './InputWrapper';
import { Control } from 'react-hook-form';
import { CreateMailingRequest } from '../../../../../Entities';

interface IDesiredNumberOfAppliesPerDayInputProps {
  control: Control<CreateMailingRequest>;
}

export const DesiredNumberOfAppliesPerDayInput: React.FC<IDesiredNumberOfAppliesPerDayInputProps> =
  memo(({ control }) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const resultValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        let resultValue: string | undefined = value;

        if (!value) {
          resultValue = undefined;
        } else if (+value > 200) {
          resultValue = '200';
        }

        return resultValue;
      };

      return resultValue(event);
    };

    return (
      <InputWrapper
        label={'Желаемое количество откликов в день (максимум 200)'}
      >
        <ControlledInput<CreateMailingRequest>
          name={'daily_plan'}
          placeholder={'Максимум 200'}
          onChange={handleOnChange}
          control={control}
          type={'number'}
          showRequiredStar
          valueAsNumber
          showError
          rules={{
            required: 'Обязательное поле',
            max: 'Не более 200',
            pattern: {
              value: /^\d+$/,
              message: 'Только цифры',
            },
          }}
        />
      </InputWrapper>
    );
  });
