// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditMailingStep3_container__B\\+kVe {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
}
.EditMailingStep3_container__B\\+kVe .EditMailingStep3_buttons__ghFIH {
  margin-top: 1rem;
  display: flex;
  gap: 0.75rem;
}
.EditMailingStep3_container__B\\+kVe .EditMailingStep3_buttons__ghFIH > button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/EditMailingStep3/EditMailingStep3.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;AAME;EACE,gBAAA;EACA,aAAA;EACA,YAAA;AAJJ;AAKI;EACE,WAAA;AAHN","sourcesContent":["@import 'src/assets/styles/utils';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: rem(36);\n  //.inputs {\n  //  display: flex;\n  //  gap: rem(12);\n  //}\n  .buttons {\n    margin-top: rem(52-36);\n    display: flex;\n    gap: rem(12);\n    & > button {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EditMailingStep3_container__B+kVe`,
	"buttons": `EditMailingStep3_buttons__ghFIH`
};
export default ___CSS_LOADER_EXPORT___;
