import { Ref } from 'react';
import { DivProps } from 'Shared/interfaces';
import { OnHandleOptionType, SelectOption, SelectorValue } from '../types';
import { useSelectorKeyboardEvents } from '../useSelectorKeyboardEvents';
import { RenderSelectOption } from '../RenderSelectOption/RenderSelectOption';
import styles from './Combobox.module.scss';
import { ReactComponent as DropdownIcon } from 'assets/svg/dropdownIcon.svg';

export const Combobox = ({
  comboboxRef,
  areOptionsShown,
  activeIndex,
  selectorId,
  placeholder,
  value,
  options,
  tag,
  changeValue,
  setActiveIndex,
  onHandleOption,
  setShowOptions,
  onChangeSearchValue,
  searchValue,
  isSearchable,
}: DivProps<{
  comboboxRef: Ref<HTMLDivElement>;
  areOptionsShown: boolean;
  activeIndex: number;
  selectorId: string;
  placeholder?: string;
  value: SelectorValue;
  options: SelectOption[];
  tag?: string;
  changeValue: (...args: any[]) => void;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  setShowOptions: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleOption: OnHandleOptionType;
  onChangeSearchValue: (inputValue: string) => void;
  searchValue: string;
  isSearchable: boolean;
}>) => {
  const onComboClick = () => {
    setShowOptions(!areOptionsShown);
  };

  const { onComboKeyDown } = useSelectorKeyboardEvents(
    options,
    setShowOptions,
    onHandleOption,
    setActiveIndex,
  );

  return (
    <div
      className={styles.combobox}
      ref={comboboxRef}
      role='combobox'
      aria-controls={selectorId + '-combobox'}
      aria-expanded={areOptionsShown}
      aria-haspopup='listbox'
      aria-labelledby={selectorId + '-label'}
      onClick={onComboClick}
      onKeyDown={(event) => onComboKeyDown(event, activeIndex, areOptionsShown)}
      tabIndex={0}
      aria-activedescendant={selectorId + activeIndex}
    >
      <RenderSelectOption
        isSearchable={isSearchable}
        areOptionsShown={areOptionsShown}
        searchValue={searchValue}
        value={value}
        options={options}
        placeholder={placeholder}
        tag={tag}
        onChangeSearchValue={onChangeSearchValue}
        changeValue={changeValue}
      />

      <DropdownIcon className={areOptionsShown ? styles.rotated : ''} />
    </div>
  );
};
