import React, { useId } from 'react';
import styles from './TextArea.module.scss';

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  label?: string;
  isHiddenLabel?: boolean;
  description?: string;
  error?: string;
  isShowError?: boolean;
};

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  isHiddenLabel,
  description,
  error,
  isShowError = true,
  ...rest
}) => {
  // id of the selector element,needed for a11y
  const selectorId = useId();
  const labelId = selectorId + '-label';
  const inputId = selectorId + '-input';
  const descriptionId = selectorId + '-description';
  return (
    <div className={styles.textArea}>
      <label
        className={!label ? 'hidden' : isHiddenLabel ? 'visually-hidden' : ''}
        id={labelId}
        htmlFor={inputId}
      >
        {label}
      </label>
      <textarea {...rest} />
      <div
        id={descriptionId}
        className={[styles.description, error && styles.error].join(' ')}
        role={error ? 'alert' : 'description'}
      >
        {error ? (isShowError ? error : null) : description}
      </div>
    </div>
  );
};
