import { memo } from 'react';
import styles from './Heading.module.scss';
import { ThemesSwitch } from '../ThemesSwitch/ThemesSwitch';
import { Link, useLocation } from 'react-router-dom';
import { NavigationPath } from '../../../config/navigation.data';
import { Button, ButtonType } from '../Buttons/Button';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { DivProps } from '../../../Shared/interfaces';

const defaultHeader: Record<string, string> = {
  [NavigationPath.mailing]: 'Мои рассылки',
  [NavigationPath.mailingCreateStep1]: 'Создание рассылки',
  [NavigationPath.mailingCreateStep2]: 'Создание рассылки',
  [NavigationPath.mailingCreateStep3]: 'Создание рассылки',
  [NavigationPath.mailingEdit]: 'Редактирование рассылки',
  [NavigationPath.hh_accounts]: 'Аккаунты hh.ru',
  [NavigationPath.user_settings]: 'Профиль',
  [NavigationPath.user_settings_account]: 'Профиль',
  [NavigationPath.user_settings_payment]: 'Оплата',
};
export const Heading = memo(({ className, ...rest }: DivProps) => {
  const location = useLocation();

  const getCreateButton = (path: string) => {
    switch (path as NavigationPath) {
      case NavigationPath.mailing:
        return (
          <Link to={NavigationPath.mailingCreateStep1}>
            <Button Icon={PlusIcon} interfaceType={ButtonType.Narrow}>
              Новая
            </Button>
          </Link>
        );
      default:
        return null;
    }
  };
  return (
    <div className={[styles.container, className].join(' ')} {...rest}>
      <div className={styles.headingContainer}>
        <h2>{defaultHeader[location.pathname] ?? null}</h2>
        {getCreateButton(location.pathname)}
      </div>

      <ThemesSwitch className={styles.themesSwitch} />
    </div>
  );
});
