import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { TextInput, TextInputProps } from './TextInput';

interface Props<T extends FieldValues>
  extends Omit<TextInputProps, 'changeValue' | 'value' | 'onBlur'> {
  control: Control<T>;
  name: Path<T>;
  rules?: RegisterOptions<T, FieldPath<T>>;
}

export const HookFormTextInput = <T extends FieldValues>({
  control,
  name,
  rules,
  type,
  ...otherProps
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <TextInput
            onBlur={onBlur}
            changeValue={onChange}
            value={value}
            error={error?.message}
            type={type}
            {...otherProps}
          />
        );
      }}
    />
  );
};
