// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditMailingStep1_container__4elHp {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
.EditMailingStep1_container__4elHp .EditMailingStep1_buttons__ST5D7 {
  margin-top: 1rem;
  display: flex;
  gap: 0.75rem;
}
.EditMailingStep1_container__4elHp .EditMailingStep1_buttons__ST5D7 > button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/newDesign/MailingPage/EditMailingStep1/EditMailingStep1.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAAF;AACE;EACE,gBAAA;EACA,aAAA;EACA,YAAA;AACJ;AAAI;EACE,WAAA;AAEN","sourcesContent":["@import 'src/assets/styles/utils';\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: rem(28);\n  .buttons {\n    margin-top: rem(52-36);\n    display: flex;\n    gap: rem(12);\n    & > button {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EditMailingStep1_container__4elHp`,
	"buttons": `EditMailingStep1_buttons__ST5D7`
};
export default ___CSS_LOADER_EXPORT___;
