// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContinueModal_continueModal__bdixr {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ContinueModal_buttons__s0w5A {
  display: flex;
  gap: 1rem;
}
.ContinueModal_buttons__s0w5A > button {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/MaillingCard/ContinueButton/ContinueModal/ContinueModal.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;AACE;EACE,SAAA;AACJ","sourcesContent":["@import 'src/assets/styles/utils.scss';\n\n.continueModal {\n  display: flex;\n  flex-direction: column;\n  gap: rem(16);\n}\n.buttons {\n  display: flex;\n  gap: rem(16);\n  & > button {\n    flex: 1 1 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"continueModal": `ContinueModal_continueModal__bdixr`,
	"buttons": `ContinueModal_buttons__s0w5A`
};
export default ___CSS_LOADER_EXPORT___;
