import { useState } from 'react';
import { Button } from '../../../../Components/UI-kit/Buttons/Button';
import { ReactComponent as RollUp } from '../../../../assets/svg/rollUp.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/svg/plus.svg';
import { InputWithButton } from '../InputWithButton/InputWithButton';
import { Collapse } from '../../../../Components/UI-kit/Collapse/Collapse';
import { CookieStealingGuide } from '../CookieStealingGuide/CookieStealingGuide';
import styles from './CollapsableInputWithButton.module.scss';

export const CollapsableInputWithButton = () => {
  const [show, setShow] = useState(false);
  return (
    <div className={styles.container}>
      <Button
        onClick={() => setShow(!show)}
        key={'CollapsButton'}
        Icon={show ? RollUp : PlusIcon}
      >
        Добавить аккаунт
      </Button>
      {show && (
        <div className={styles.collapse}>
          <p>Вставьте скопированные куки в поле ниже и нажмите кнопку:</p>
          <InputWithButton />
          <Collapse summary='Инструкция добавления аккаунта'>
            <CookieStealingGuide className={styles.cookieStealingGuide} />
          </Collapse>
        </div>
      )}
    </div>
  );
};
