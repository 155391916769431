import { Logo } from 'Components/UI-kit/Logo/Logo';
import styles from './ConfirmEmailPage.module.scss';
import { useLocation } from 'react-router-dom';
export const ConfirmEmailPage = () => {
  const { state } = useLocation();
  console.log(location);

  return (
    <div className={styles.container}>
      <Logo />
      <h2>
        Пожалуйста, перейдите по ссылке из письма, отправленного на почту
        <p>{state?.email ?? null}</p>
      </h2>
    </div>
  );
};
