import styles from './NotFoundPage.module.scss';
export const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      <h2>O kurva 404!</h2>
      <h3>Что то пошло не так,</h3>
      <h3> Страница не найдена</h3>
    </div>
  );
};
