import { FieldValues, useFormContext } from 'react-hook-form';
import { useGetCodeRegionsQuery } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { WithHookFormControlSelector } from '../../../../Components/UI-kit/Selector/WithHookFormControlSelector';
import { HookFormTextInput as TextInput } from '../../../../Components/UI-kit/TextInput/HookFormTextInput';
import { WithHookFormControlTextArea as TextArea } from '../../../../Components/UI-kit/TextArea/WithHookFormControlTextArea';
import styles from './Selectors.module.scss';
import {
  CreateMailingRequest,
  EmploymentTypeEnum,
  ExperienceEnum,
  OrderEnum,
  ScheduleEnum,
  SearchPeriodEnum,
} from '../../../../Entities';
import { useEmployerOptions } from '../../../../hooks/useEmployerOptions';
import { useEffect } from 'react';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';
import { MenuPlacement, SelectorType } from 'Components/UI-kit/Selector';

const orderOptions: { value: OrderEnum; label: string }[] = [
  { value: OrderEnum.RELEVANCE, label: 'По соответствию' },
  { value: OrderEnum.PUBLICATION_TIME, label: 'По дате изменени' },
  { value: OrderEnum.SALARY_DESC, label: 'По убыванию зарплат' },
  { value: OrderEnum.SALARY_ASC, label: 'По возрастанию зарплаты' },
];

const searchPeriodOptions: { value: SearchPeriodEnum; label: string }[] = [
  { value: SearchPeriodEnum._0, label: 'За всё время' },
  { value: SearchPeriodEnum._30, label: 'За месяц' },
  { value: SearchPeriodEnum._7, label: 'За неделю' },
  { value: SearchPeriodEnum._3, label: 'За последние три дня' },
  { value: SearchPeriodEnum._1, label: 'За сутки' },
];
const employmentTypeOptions: { value: EmploymentTypeEnum; label: string }[] = [
  { value: EmploymentTypeEnum.FULL, label: 'Полная занятость' },
  { value: EmploymentTypeEnum.PART, label: 'Частичная занятость' },
  { value: EmploymentTypeEnum.PROJECT, label: 'Проектная деятельность' },
  { value: EmploymentTypeEnum.VOLUNTEER, label: 'Волонтерство' },
  { value: EmploymentTypeEnum.PROBATION, label: 'Стажировка' },
];

const ScheduleOptions: { value: ScheduleEnum; label: string }[] = [
  { value: ScheduleEnum.FULL_DAY, label: 'Полный день' },
  { value: ScheduleEnum.FLY_IN_FLY_OUT, label: 'Вахтовый метод' },
  { value: ScheduleEnum.SHIFT, label: 'Сменный график' },
  { value: ScheduleEnum.FLEXIBLE, label: 'Гибкий график' },
  { value: ScheduleEnum.REMOTE, label: 'Удаленная работа' },
];
const experienceOptions: { value: ExperienceEnum; label: string }[] = [
  { value: ExperienceEnum.DOES_NOT_MATTER, label: 'Не имеет значения' },
  { value: ExperienceEnum.NO_EXPERIENCE, label: 'Нет опыта' },
  { value: ExperienceEnum.BETWEEN1AND3, label: 'От 1 до 3х лет' },
  { value: ExperienceEnum.BETWEEN3AND6, label: 'От 3х до 6-ти лет' },
  { value: ExperienceEnum.MORE_THAN6, label: 'Более 6-ти лет' },
];

export const Selectors = <T extends FieldValues = CreateMailingRequest>() => {
  const { control, watch, setValue, getValues } =
    useFormContext<CreateMailingRequest>();

  const { hhUserOptions, getResumeOptionsById } = useGetHhUser();

  const { data: regionOptions = [] } = useGetCodeRegionsQuery();

  const { createOption, deleteOption, getOptions, employerOptions } =
    useEmployerOptions();

  useEffect(() => {
    const ignore = getOptions(watch('restricted_employers'));
  }, []);

  const onCreateOption = async (inputValue: string) => {
    if (!inputValue.trim()) return;
    const response = await createOption({ link: inputValue });
    if (!response) return;
    const restricted_employers = getValues('restricted_employers');
    const new_restricted_employers = [
      ...new Set([...(restricted_employers ?? []), response.value]),
    ];
    setValue('restricted_employers', new_restricted_employers);

    return 'clearInput';
  };

  const onDeleteOption = async (id: string | number) => {
    await deleteOption(Number(id));
  };

  return (
    <div className={styles.container}>
      <WithHookFormControlSelector
        name={'hh_user'}
        control={control}
        placeholder={'HH аккаунт'}
        options={hhUserOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'resume_hash'}
        control={control}
        placeholder={'Резюме для откликов'}
        options={getResumeOptionsById(watch('hh_user'))}
        type={SelectorType.Form}
      />

      <WithHookFormControlSelector
        name={'areas'}
        control={control}
        placeholder={'Регион'}
        options={regionOptions}
        type={SelectorType.Form}
        isSearchable={true}
      />

      <TextInput
        control={control}
        name={'search_key'}
        label={'Желаемая должность'}
        placeholder={'Укажите должность'}
      />

      <TextArea
        control={control}
        label={'Сопроводительное письмо (необязательно)'}
        name={'letter'}
        placeholder={'Сопроводительное письмо'}
        rows={5}
      />

      <WithHookFormControlSelector
        name={'order'}
        control={control}
        placeholder={'Сортировать вакансии'}
        options={orderOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'search_period'}
        control={control}
        placeholder={'Откликаться на вакансии, опубликованные'}
        menuPlacement={MenuPlacement.Top}
        options={searchPeriodOptions}
        type={SelectorType.Form}
      />

      <WithHookFormControlSelector
        name={'employment_type'}
        control={control}
        placeholder={'Тип вакансии'}
        menuPlacement={MenuPlacement.Top}
        options={employmentTypeOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'schedule'}
        control={control}
        placeholder={'График вакансии'}
        menuPlacement={MenuPlacement.Top}
        options={ScheduleOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'experience'}
        control={control}
        placeholder={'Опыт работы'}
        menuPlacement={MenuPlacement.Top}
        options={experienceOptions}
        type={SelectorType.Form}
      />
      <WithHookFormControlSelector
        name={'restricted_employers'}
        control={control}
        placeholder={'Запрещенные работодатели'}
        menuPlacement={MenuPlacement.Top}
        options={employerOptions}
        type={SelectorType.Form}
        onCreateOption={onCreateOption}
        onDeleteOption={onDeleteOption}
      />
    </div>
  );
};
