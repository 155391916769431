import React, { memo } from 'react';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import { TRegionCodesItem } from '../../../../../services/RTKService/types/mailing-types/mailing.types';
import { useGetCodeRegionsQuery } from '../../../../../services/RTKService/mailing/endpoints/mailingApi';
import { CreateMailingRequest } from '../../../../../Entities';

interface IHookFormRegionSelectorProps {
  control: Control<CreateMailingRequest>;
}

const emptyArr: TRegionCodesItem[] = [];
export const HookFormRegionSelector: React.FC<IHookFormRegionSelectorProps> =
  memo(({ control }) => {
    const { options } = useGetCodeRegionsQuery(
      {},
      {
        selectFromResult: ({ data }) => ({
          options: data ? data : emptyArr,
        }),
      },
    );

    return (
      <SelectorWrapper options={options} label={'Регион'}>
        <HookFormReactSelector<CreateMailingRequest>
          defaultValue={options[0]?.value}
          name={'areas'}
          isLoading={false}
          control={control}
          isMulti={false}
          options={options}
          showError={false}
          placeholder={'Название региона'}
        />
      </SelectorWrapper>
    );
  });
