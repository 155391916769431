import { Outlet } from 'react-router-dom';
import { useGetMailingByIdQuery } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { FormProvider, useForm } from 'react-hook-form';
import {
  RetrieveMailing,
  PatchedUpdateMailingRequest,
} from '../../../../Entities';

import styles from './UpdateMailingFormProvider.module.scss';
import { useGetIdFromSearchParams } from '../hooks/useGetIdFromSearchParams';
import { zodResolver } from '@hookform/resolvers/zod';

import { PatchedCreateMailingRequestSchema } from 'schemas/PatchedCreateMailingRequestSchema';

const getFormValues = (
  apiData: (RetrieveMailing & { restricted_employerId: number[] }) | undefined,
): (PatchedUpdateMailingRequest & { hh_user: number }) | undefined => {
  if (apiData) {
    return {
      employment_type: apiData.employment_type,
      areas: apiData.areas,
      search_key: apiData.search_key,
      hh_user: apiData.hh_user,
      letter: apiData.letter,
      order: apiData.order,
      search_period: apiData.search_period,
      sleep_time: apiData.sleep_time,
      resume_hash: apiData.resume_hash,
      plan: apiData.plan,
      daily_plan: apiData.daily_plan,
      restricted_employers: apiData.restricted_employerId,
      experience: apiData.experience,
      schedule: apiData.schedule,
    };
  }
};

export const UpdateMailingFormProvider = () => {
  const editableMailingId = useGetIdFromSearchParams();

  const { data: mailingData } = useGetMailingByIdQuery(
    { id: editableMailingId as number },
    { skip: !editableMailingId },
  );

  const methods = useForm<PatchedUpdateMailingRequest>({
    resolver: zodResolver(PatchedCreateMailingRequestSchema),
    values: getFormValues(mailingData),
  });

  return (
    <div className={styles.container}>
      <FormProvider {...methods}>
        <Outlet />
      </FormProvider>
    </div>
  );
};
