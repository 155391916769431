// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
li:hover, li:focus, li.Option_active__yNOvf {
  box-shadow: var(--shadows-convex);
}
li:active {
  box-shadow: var(--shadows-indented);
}
li > button {
  opacity: 0;
}
li.Option_active__yNOvf > button, li:hover > button {
  opacity: 1;
}
li:has(button:active) {
  box-shadow: var(--shadows-convex);
}
li .Option_closeButton__dQX-D {
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI-kit/Selector/Option/Option.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EAGE,iCAAA;AADJ;AAIE;EACE,mCAAA;AAFJ;AAKE;EACE,UAAA;AAHJ;AAOI;EACE,UAAA;AALN;AAQE;EACE,iCAAA;AANJ;AAQE;EACE,YAAA;EACA,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;AANJ","sourcesContent":["li {\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 8px;\n\n  &:hover,\n  &:focus,\n  &.active {\n    box-shadow: var(--shadows-convex);\n  }\n\n  &:active {\n    box-shadow: var(--shadows-indented);\n  }\n\n  & > button {\n    opacity: 0;\n  }\n  &.active,\n  &:hover {\n    & > button {\n      opacity: 1;\n    }\n  }\n  &:has(button:active) {\n    box-shadow: var(--shadows-convex);\n  }\n  .closeButton {\n    padding: 5px;\n    width: fit-content;\n    height: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `Option_active__yNOvf`,
	"closeButton": `Option_closeButton__dQX-D`
};
export default ___CSS_LOADER_EXPORT___;
