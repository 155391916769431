import {
  MenuPlacement,
  OnHandleOptionType,
  SelectOption,
  SelectorValue,
} from '../types';
import { Ref } from 'react';
import { Flatten } from 'Shared/interfaces';
import styles from './ListBox.module.scss';
import { RenderOptions } from './RenderOptions';
import { InputWithButton } from './InputWithButton';

export type ListBoxProps = {
  areOptionsShown: boolean;
  listboxRef: Ref<HTMLDivElement>;
  menuPlacement: MenuPlacement;
  selectorId: string;
  activeIndex: number;
  value: SelectorValue;
  onHandleOption: OnHandleOptionType;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  options: SelectOption[];
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onCreateOption?: () => void;
  onHandlePastToClipboard?: () => void;
  onDeleteOption?: (value: Flatten<SelectorValue>) => void;
  searchValue: string;
  onChangeSearchValue: (inputValue: string) => void;
  isSearchable?: boolean;
};
export const ListBox = ({
  areOptionsShown,
  listboxRef,
  menuPlacement,
  selectorId,
  activeIndex,
  value,
  onHandleOption,
  options,
  onCreateOption = undefined,
  inputValue,
  setInputValue,
  onHandlePastToClipboard,
  onDeleteOption,
  searchValue,
  isSearchable,
}: ListBoxProps) => {
  const filteredOptionsbySearchInput = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  if (!areOptionsShown) return null;

  return (
    <div
      ref={listboxRef}
      className={[
        styles[menuPlacement],
        areOptionsShown ? '' : 'visually-hidden',
      ].join(' ')}
      id={selectorId + '-listbox'}
      role='listbox'
      aria-expanded={areOptionsShown}
      aria-labelledby={selectorId + '-label'}
    >
      <RenderOptions
        options={filteredOptionsbySearchInput}
        value={value}
        activeIndex={activeIndex}
        selectorId={selectorId}
        searchValue={searchValue}
        onHandleOption={onHandleOption}
        onDeleteOption={onDeleteOption}
      />
      {Array.isArray(value) ? (
        <InputWithButton
          inputValue={inputValue}
          setInputValue={setInputValue}
          onHandlePastToClipboard={onHandlePastToClipboard}
          onCreateOption={onCreateOption}
          isEmptyOptions={!filteredOptionsbySearchInput.length}
          isSearchable={isSearchable}
        />
      ) : null}
    </div>
  );
};
