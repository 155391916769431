import styles from './UserSettingPage.module.scss';
import { Tabs } from '../../../Components/UI-kit/Tabs/Tabs';
import { useState } from 'react';
import {
  NavigationPath,
  userSettingsNavigationItems,
} from '../../../config/navigation.data';
import { Outlet, useLocation } from 'react-router-dom';

export const UserSettingPage = () => {
  const location = useLocation();

  const [value, changeValue] = useState<string>(() => {
    switch (location.pathname) {
      case NavigationPath.user_settings:
      case NavigationPath.user_settings_account:
        return 'Профиль';
      case NavigationPath.user_settings_payment:
        return 'Оплата';
      default:
        return 'Профиль';
    }
  });

  return (
    <div className={styles.container}>
      <Tabs
        links={userSettingsNavigationItems}
        value={value}
        changeValue={changeValue}
      />
      <Outlet />
    </div>
  );
};
