import { useCreateMailingMutation } from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { useSelector } from 'react-redux';
import { selectHhUserId } from '../../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { CreateMailingRequest } from '../../../Entities';

export const useCreateMailingTask = () => {
  const hhUserId = useSelector(selectHhUserId);
  const [create, { isLoading }] = useCreateMailingMutation();

  const createTask = async (data: CreateMailingRequest) => {
    if (hhUserId) {
      await create({ data: { ...data, hh_user: hhUserId } });
    }
  };
  return { createTask, isLoading };
};
