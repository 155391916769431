export const getHhUserURL = (path?: string | number) =>
  `/hhusers/hhuser${path ? path : '/'}`;

export const getMailingURL = (path?: string) =>
  `/mailings/mailing${path ? path : '/'}`;

export const getRegionCodesURL = () => '/mailings/areas_codes/';
export const getEmployersURL = (path?: string) =>
  `/mailings/employer${path ? path : '/'}`;

export const getAuthURL = (path?: string) => `auth/${path ? path + '/' : '/'}`;
