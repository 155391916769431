import styles from './RootLayout.module.scss';
import { ThemesSwitch } from '../../../Components/UI-kit/ThemesSwitch/ThemesSwitch';
import { Outlet } from 'react-router-dom';
import { TELEGRAMM_SUPPORT } from 'config/const';

export const RootLayout = () => {
  return (
    <div className={styles.container}>
      <ThemesSwitch className={styles.themesSwitch} />
      <div className={styles.centredContent}>
        <Outlet />
      </div>
      <a href={TELEGRAMM_SUPPORT} target={'_blank'} rel='noreferrer'>
        Поддержка hhmailer
      </a>
      <div className={styles.background} />
    </div>
  );
};
