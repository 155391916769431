import { Outlet, useNavigate } from 'react-router-dom';
import {
  mailingStepsNavigationItems,
  NavigationPath,
} from '../../../../config/navigation.data';
import { MailingSteps } from '../../../../Components/UI-kit/MallingSteps/MailingSteps';
import styles from './CreateMailing.module.scss';
import { useFormContext } from 'react-hook-form';
import { CreateMailingRequest } from '../../../../Entities';
import { useGetIdFromSearchParams } from '../hooks/useGetIdFromSearchParams';
import { useCreateMailingMutation } from '../../../../services/RTKService/mailing/endpoints/mailingApi';

export const CreateMailing = () => {
  const { handleSubmit } = useFormContext<CreateMailingRequest>();
  const navigate = useNavigate();

  const editableMailingId = useGetIdFromSearchParams();

  const [create] = useCreateMailingMutation();

  const onHandleStep2 = handleSubmit(async (data) => {
    if (editableMailingId) {
      navigate({
        pathname: NavigationPath.mailingCreateStep2,
        search: `id=${editableMailingId}`,
      });
    } else {
      const response = await create({ data });
      if ('data' in response) {
        const id = response.data.id;
        navigate({
          pathname: NavigationPath.mailingCreateStep2,
          search: `id=${id}`,
        });
      }
    }
  });

  const onHandleStep = async (path: NavigationPath) => {
    switch (path) {
      case NavigationPath.mailingCreateStep1:
        navigate({
          pathname: NavigationPath.mailingCreateStep1,
          search: `id=${editableMailingId}`,
        });
        break;
      case NavigationPath.mailingCreateStep2:
        await onHandleStep2();
        break;
      case NavigationPath.mailingCreateStep3:
        navigate({
          pathname: NavigationPath.mailingCreateStep3,
          search: `id=${editableMailingId}`,
        });
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mailingStepsContainer}>
        <MailingSteps
          className={styles.mailingSteps}
          onHandleStep={onHandleStep}
          navigationItems={mailingStepsNavigationItems}
        />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
