import {
  useCreateEmployerMutation,
  useRefreshEmployersListMutation,
} from '../services/RTKService/mailing/endpoints/mailingApi';
import { EmployerRequest } from '../Entities';
import {
  addItemsFromArrayLocalStorage,
  filterItemFromArrayLocalStorage,
} from '../services/localStorageService/localStorageService';
import { useEffect } from 'react';

enum LocalStorageKeys {
  employerIds = 'employerIds',
}

export const useEmployerOptions = () => {
  const [create, { isLoading: isLoadingCreate }] = useCreateEmployerMutation();

  const [
    refresh,
    { data: { employerOptions = [] } = {}, isLoading: isLoadingRefresh },
  ] = useRefreshEmployersListMutation();

  useEffect(() => {
    const ignore = getOptions([]);
  }, []);

  const getOptions = async (idsInEditableMailing: number[] = []) => {
    const ids = addItemsFromArrayLocalStorage(
      LocalStorageKeys.employerIds,
      idsInEditableMailing,
    );
    if (!ids) return [];
    const response = await refresh({ data: { ids } });
    if ('data' in response) {
      return response.data.employerOptions;
    }
    return [];
  };

  const createOption = async (data: EmployerRequest) => {
    const response = await create({ data });
    if ('data' in response) {
      const ids = addItemsFromArrayLocalStorage(
        LocalStorageKeys.employerIds,
        response.data.employer.id,
      );

      if (!data) return;
      refresh({ data: { ids } });
      return response.data.employerOption;
    }
  };

  const deleteOption = async (deletedOptionValue: number) => {
    const ids = filterItemFromArrayLocalStorage<number>(
      LocalStorageKeys.employerIds,
      (id) => id !== deletedOptionValue,
    );
    if (!ids) return;
    await refresh({ data: { ids } });
  };

  return {
    getOptions,
    employerOptions,
    createOption,
    deleteOption,
    isLoading: isLoadingCreate || isLoadingRefresh,
  };
};
